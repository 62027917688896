import React, { useEffect, useState } from "react";
import { JoinButton } from "../../components/JoinButton";
import dollarBag from "../../assets/image/mining/dollar-bag.png";
import coinTarget from "../../assets/image/mining/coin-target.png";
import mineRig from "../../assets/image/mining/mine-rig.png";

import styles from "./Mining.module.scss";

import {
  Box,
  Flex,
  InputGroup,
  InputRightElement,
  useToast
} from "@chakra-ui/react";
import { CommonInput } from "../../components/UI/CommonInput";
import { useWallet } from "../../store/wallet-context";
import { useQuery } from "@tanstack/react-query";
import { differenceInSeconds } from "date-fns";
import { useContracts } from "../../hooks/useContracts";
import { useTokens } from "../../hooks/useTokens";
import { callWithToastError, truncateNumber } from "../../utils/utils";
import { BigNumber, ethers } from "ethers";
import { useIsRegistered } from "../../hooks/useIsRegistered";
import { useNavigate } from "react-router-dom";
import { usePerson } from "../../hooks/usePerson";
import { useGlobalConfig } from "../../hooks/useGlobalConfig";
import { formatEther } from "ethers/lib/utils.js";
import { useBalance, useBlockNumber } from "wagmi";
import { useNameservice } from "../../store/nameservice-context";
import { useTranslation } from "react-i18next";
import { useTimeToWait } from "../../hooks/useTimeToWait";

const intrestRate = [
  [1000, 100],
  [5000, 110],
  [10000, 120],
  [50000, 140],
  [100000, 160],
  [500000, 180],
  [500000, 220]
];
export const Mining = () => {
  //@ts-ignore
  const { t } = useTranslation();
  const {
    timeIsDue: miningTimeIsDue,
    hoursToWait: miningHours,
    minutesToWait: miningMinutes,
    secondsToWait: miningSeconds
  } = useTimeToWait();
  const toast = useToast();
  const ns = useNameservice();
  const [usdsAmount, setUsdsAmount] = useState("");
  // user input claim stf amount
  const [inputClaimStfAmount, setInputClaimStfAmount] = useState("");
  const [inputClaimStfAmountError, setInputClaimStfAmountError] =
    useState(false);
  const contracts = useContracts();
  const { accountAddress, isConnected } = useWallet();
  const bnbBalanceQuery = useBalance({ address: accountAddress });
  const manager = contracts.manager!;
  const usds = contracts.usds!;
  const tokensQuery = useTokens();
  const [miningBtnLoading, setMiningBtnLoading] = useState(false);

  const navigate = useNavigate();
  const isRegisteredQuery = useIsRegistered();
  if (isRegisteredQuery.data === false) {
    navigate("/register");
  }

  // Queries
  const perosonQuery = usePerson();
  const configQuery = useGlobalConfig();

  const claimableStfQuery = useQuery({
    queryKey: ["claimableStfQuery"],
    queryFn: async () => {
      try {
        const reward = await manager.callStatic.getReward({
          from: accountAddress!
        });
        console.log("reward", reward);
        return reward;
      } catch (error) {
        console.log(error);
        return Promise.resolve({ reward: 0, bonus: 0 });
      }
    }
  });

  const globalStakedUsdsQuery = useQuery({
    queryKey: ["globalStakedUsdsQuery"],
    queryFn: async () => {
      try {
        const totalUsdsStaked = await usds.balanceOf(ns!.UsdsStakeUsdsMix);
        console.log("totalUsdsStaked", totalUsdsStaked);
        return totalUsdsStaked;
      } catch (error) {
        return Promise.resolve(ethers.BigNumber.from(0));
      }
    },
    enabled: !!ns && !!usds
  });

  // watch block number to update balances
  const blockNumber = useBlockNumber({ watch: true });
  const blockNumberRef = React.useRef(blockNumber);

  useEffect(() => {
    if (
      blockNumber.isSuccess &&
      blockNumberRef.current.data !== blockNumber.data
    ) {
      blockNumberRef.current = blockNumber;
      claimableStfQuery.refetch();
      perosonQuery.refetch();
      globalStakedUsdsQuery.refetch();
    }
  }, [blockNumber]);

  const perosonQueryComplete =
    perosonQuery.status === "success" && perosonQuery.data;
  const configQueryComplete =
    configQuery.status === "success" && configQuery.data;

  const usdsBalance = tokensQuery.isSuccess
    ? tokensQuery.data.usds.balance
    : "0";
  const usdsStaked = perosonQueryComplete
    ? perosonQuery.data.stakedUsds.toString()
    : "0";
  const stpBalance = perosonQueryComplete
    ? perosonQuery.data.stakedSourceAmount.toString()
    : "0";
  const accumulativeReward = perosonQueryComplete
    ? perosonQuery.data.accumulativeReward.toString()
    : "0";
  const claimableStf = claimableStfQuery.isSuccess
    ? claimableStfQuery.data.reward.toString()
    : "0";
  const claimableBonus = claimableStfQuery.isSuccess
    ? claimableStfQuery.data.bonus.toString()
    : "0";
  const startTime = configQueryComplete
    ? configQuery.data.startTime.toString()
    : "0";
  const globalStp = configQueryComplete
    ? configQuery.data.totalSupplySource.toString()
    : "0";
  const globalUsdsStaked = globalStakedUsdsQuery.isSuccess
    ? globalStakedUsdsQuery.data.toString()
    : "0";

  const secondsPerDay = 60 * 60 * 24; // 86400
  const secondsPerHour = 60 * 60; // 3600
  const secondsPerMinute = 60; // 60

  const [hoursToWait, setHoursToWait] = useState(0); // [0, 23]
  const [minutesToWait, setMinutesToWait] = useState(0);
  const [secondsToWait, setSecondsToWait] = useState(0);
  const [daysSinceStarted, setDaysSinceStarted] = useState(0);
  useEffect(() => {
    const intervalId = setInterval(() => {
      const currentTime = new Date();
      const oneDay = configQuery?.data?.oneDay?.toNumber() || 1;
      const daysPassed = Math.floor(
        (currentTime.getTime() / 1000 - Number(startTime)) / oneDay
      );
      const timeDifferenceInSeconds =
        oneDay -
        (differenceInSeconds(currentTime, new Date(Number(startTime) * 1000)) %
          oneDay);
      const hoursToWait = Math.floor(timeDifferenceInSeconds / secondsPerHour);
      const minutesToWait = Math.floor(
        (timeDifferenceInSeconds % secondsPerHour) / secondsPerMinute
      );
      const secondsToWait = Math.floor(timeDifferenceInSeconds % 60);
      setHoursToWait(hoursToWait);
      setMinutesToWait(minutesToWait);
      setSecondsToWait(secondsToWait);
      setDaysSinceStarted(daysPassed);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [startTime]);

  const handleWithdraw = async () => {
    if (!bnbBalanceQuery.isSuccess) {
      return;
    }
    if (bnbBalanceQuery.data!.value.lt(ethers.utils.parseUnits("0.001", 18))) {
      toast({
        title: t(
          "Insufficient BNB balance for gas",
          "Insufficient BNB balance for gas."
        ),
        status: "error",
        duration: 1500,
        isClosable: true
      });
      return;
    }
    const amount = inputClaimStfAmount;
    console.log("withdraw amount", amount);
    if (!amount || inputClaimStfAmountError) {
      console.error("withdraw not allowed");
      return;
    }
    callWithToastError(
      () => manager.withdraw(ethers.utils.parseEther(amount)),
      toast,
      t("error occured", "An error occured. Please try again later.")
    );
  };

  const { usds: usdsToken } = tokensQuery.data || {};

  // need approve condition: allowance < input < balance
  const inputUsdsAmount = ethers.utils.parseEther(usdsAmount || "0");
  const usdsInputMoreThanApproved =
    tokensQuery.isSuccess &&
    ethers.utils.parseEther(usdsToken!.balance).gt(inputUsdsAmount) &&
    inputUsdsAmount.gt(usdsToken!.allowance);

  const usdsNotApproved =
    usdsToken?.approved === false || usdsInputMoreThanApproved;

  const handleMining = async () => {
    if (!tokensQuery.isSuccess) return;
    if (!bnbBalanceQuery.isSuccess) {
      return;
    }
    if (bnbBalanceQuery.data!.value.lt(ethers.utils.parseUnits("0.001", 18))) {
      toast({
        title: t(
          "Insufficient BNB balance for gas",
          "Insufficient BNB balance for gas."
        ),
        status: "error",
        duration: 1500,
        isClosable: true
      });
      return;
    }

    if (usdsNotApproved) {
      setMiningBtnLoading(true);
      await callWithToastError(
        async () => await usdsToken!.approve(),
        toast,
        t("error occured", "An error occured. Please try again later.")
      );
      setMiningBtnLoading(false);
      return;
    }
    if (!usdsAmount) {
      toast({
        title: "Please input correct token amount",
        status: "error",
        duration: 1500,
        isClosable: true
      });
      return;
    }
    const mineFn = async () => {
      const stakeAmount = ethers.utils.parseEther(usdsAmount);
      const tx = await manager.stake(stakeAmount);
      await tx.wait();
      setUsdsAmount("");
    };
    setMiningBtnLoading(true);
    await callWithToastError(
      mineFn,
      toast,
      t("error occured", "An error occured. Please try again later.")
    );
    setMiningBtnLoading(false);
  };

  const handleSetInputClaimStfAmount = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setInputClaimStfAmount(e.target.value);
    const valueInWei = ethers.utils.parseEther(e.target.value || "0");
    const maxClaimableStf = BigNumber.from(usdsStaked);
    if (valueInWei.gt(maxClaimableStf)) {
      setInputClaimStfAmountError(true);
    } else {
      setInputClaimStfAmountError(false);
    }
  };

  return (
    <div className={styles.mining}>
      <Box className={styles.container}>
        <Box className={styles.banner}>
          <Box className={styles.bannerTitle}>
            {t("STF Mining", "STF Mining")}
          </Box>
          <Box className={styles.bannerText}>
            {t(
              "Stake your USDS to earn STF tokens",
              "Stake your USDS to earn STF tokens."
            )}
          </Box>
          <Box>
            <img className={styles.img1} src={dollarBag} alt="img1" />
          </Box>
        </Box>

        <Flex className={styles.miningMain} justifyContent="center" gap={20}>
          <Box className={styles.pool}>
            <Box>
              <img className={styles.img2} src={coinTarget} alt="img2" />
            </Box>
            <Box className={styles.poolTitle}>
              {t("Todays Rewards", "Today's Rewards")}
            </Box>
            <Box className={styles.poolAmount}>
              {Math.max(Math.floor(50000 * 0.999 ** daysSinceStarted), 10000)}
            </Box>
            <Box className={styles.poolTokenName}>STF</Box>
            <Box className={styles.poolDesc}>
              {t(
                "countdown",
                "STF amount will decrease by 0.1% after {{hoursToWait}}h {{minutesToWait}}m {{secondsToWait}}s",
                {
                  hoursToWait,
                  minutesToWait,
                  secondsToWait
                }
              )}
            </Box>
          </Box>
          <Flex className={styles.miningSection}>
            <Box className={styles.miningTitle}>
              {t("Stake USDS to mine STF", "Stake USDS to mine STF")}
            </Box>
            <Box className={styles.miningToken}>
              <InputGroup>
                <InputRightElement
                  pointerEvents="none"
                  marginTop={1}
                  paddingRight={1}
                  w={20}
                >
                  <Box
                    bg="white"
                    color="black"
                    width="100%"
                    h="100%"
                    borderRadius="20px"
                    lineHeight="40px"
                    fontWeight={700}
                  >
                    USDS
                  </Box>
                </InputRightElement>
                <CommonInput
                  value={usdsAmount}
                  onChange={(e) => setUsdsAmount(e.target.value)}
                  placeholder={t("Input USDS Amount", "Input USDS Amount")}
                  type="number"
                />
              </InputGroup>

              <Box className={styles.tokenDesc} my={4}>
                {t("Balance", "Balance")}: {truncateNumber(usdsBalance)} USDS
              </Box>
            </Box>

            {miningTimeIsDue ? (
              <JoinButton
                handleClick={handleMining}
                name={
                  usdsNotApproved
                    ? t("Approve", "Approve")
                    : t("Mining", "Mining")
                }
                disabled={
                  !isConnected || !usdsAmount || Number(usdsAmount) === 0
                }
                loading={miningBtnLoading}
              />
            ) : (
              <JoinButton
                handleClick={undefined}
                disabled={true}
                name={`${miningHours}h ${miningMinutes}m ${miningSeconds}s`}
              />
            )}
          </Flex>
        </Flex>

        <Flex className={styles.miningMain} justifyContent="center" gap={20}>
          <Flex flexDir="column" className={styles.interestTableContainer}>
            <Box className={styles.miningTitle}>
              {t("More STP more STF", "More STP more STF")}
            </Box>
            <table className={styles.interestTable}>
              <tbody>
                <tr>
                  <td>USDS</td>
                  <td>STP</td>
                </tr>
                {intrestRate.map((item, index) => (
                  <tr key={index}>
                    <td>{"<" + item[0]}</td>
                    <td>{item[1] + "%"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Flex>
          <Box className={styles.pool}>
            <Box>
              <img className={styles.img2} src={mineRig} alt="mineRig" />
            </Box>
            <Box className={styles.mineRigCard}>
              <Box>{t("Global USDS staked", "Global USDS staked")}</Box>
              <Box>
                <strong>{truncateNumber(formatEther(globalUsdsStaked))}</strong>{" "}
                USDS
              </Box>
            </Box>
            <Box className={styles.mineRigCard} mt={10}>
              <Box>{t("Global STP", "Global STP")}</Box>
              <Box>
                <strong>{truncateNumber(formatEther(globalStp))}</strong> STP
              </Box>
            </Box>
          </Box>
        </Flex>

        <Flex className={styles.miningMain} justifyContent="center" gap={20}>
          <Flex
            className={styles.stakeCard}
            justifyContent="center"
            flexDir="column"
            alignItems="center"
            gap={6}
          >
            <Flex className={styles.stakeInfo}>
              <Box className={styles.stakeLabel}>
                {t("USDS Staked", "USDS Staked")}:
              </Box>
              <Box className={styles.stakeAmount}>
                {truncateNumber(formatEther(usdsStaked))} USDS
              </Box>
            </Flex>
            <Flex className={styles.stakeInfo}>
              <Box className={styles.stakeLabel}>
                {t("Your STP", "Your STP")}:
              </Box>
              <Box className={styles.stakeAmount}>
                {truncateNumber(formatEther(stpBalance))} STP
              </Box>
            </Flex>
            <CommonInput
              value={inputClaimStfAmount}
              onChange={handleSetInputClaimStfAmount}
              placeholder={t("Enter Amount", "Enter Amount to Unstake")}
              type="number"
              isError={inputClaimStfAmountError}
            />

            {miningTimeIsDue ? (
              <JoinButton
                handleClick={handleWithdraw}
                name={t("Unstake", "Unstake")}
                outline={true}
                className={styles.stakeButton}
                disabled={
                  Number(usdsStaked) === 0 ||
                  Number(inputClaimStfAmount) === 0 ||
                  inputClaimStfAmountError
                }
              />
            ) : (
              <JoinButton
                handleClick={undefined}
                disabled={true}
                name={`${miningHours}h ${miningMinutes}m ${miningSeconds}s`}
              />
            )}
          </Flex>
          <Flex
            className={styles.stakeCard}
            justifyContent="center"
            flexDir="column"
            alignItems="center"
            gap={6}
          >
            <Flex className={styles.stakeInfo}>
              <Box className={styles.stakeLabel}>
                {t("STF claimed", "STF claimed")}:{" "}
              </Box>
              <Box className={styles.stakeAmount}>
                {truncateNumber(formatEther(accumulativeReward), 4)} STF
              </Box>
            </Flex>
            <Flex className={styles.stakeInfo}>
              <Box className={styles.stakeLabel}>
                {t("STF available", "STF available")}:{" "}
              </Box>
              <Box className={styles.stakeAmount}>
                {truncateNumber(formatEther(claimableStf), 4)} STF
              </Box>
            </Flex>
            <Flex className={styles.stakeInfo}>
              <Box className={styles.stakeLabel}>
                {t("Holding Bonus", "Holding Bonus")}:{" "}
              </Box>
              <Box className={styles.stakeAmount}>
                {truncateNumber(formatEther(claimableBonus), 4)} STF
              </Box>
            </Flex>
            <p className={styles.holdingBonus}>
              {t(
                "Holding Bonus Desc",
                "* Non-withdrawn mining profit increases 1% every 24 hours. Accumulates but resets after each withdrawal."
              )}
            </p>
            {/*<JoinButton*/}
            {/*  handleClick={undefined}*/}
            {/*  disabled={true}*/}
            {/*  name={t("comming soon", "comming soon")}*/}
            {/*/>*/}
            {miningTimeIsDue ? (
              <JoinButton
                handleClick={() => {
                  if (!bnbBalanceQuery.isSuccess) {
                    return;
                  }
                  if (
                    bnbBalanceQuery.data!.value.lt(
                      ethers.utils.parseUnits("0.001", 18)
                    )
                  ) {
                    toast({
                      title: t(
                        "Insufficient BNB balance for gas",
                        "Insufficient BNB balance for gas."
                      ),
                      status: "error",
                      duration: 1500,
                      isClosable: true
                    });
                    return;
                  }
                  callWithToastError(
                    () => manager.getReward(),
                    toast,
                    t(
                      "error occured",
                      "An error occured. Please try again later."
                    )
                  );
                }}
                name={t("claim", "Claim")}
                outline={true}
                className={styles.stakeButton}
                disabled={
                  Number(accumulativeReward) === 0 &&
                  Number(claimableStf) === 0 &&
                  Number(claimableBonus) === 0
                }
              />
            ) : (
              <JoinButton
                handleClick={undefined}
                disabled={true}
                name={`${miningHours}h ${miningMinutes}m ${miningSeconds}s`}
              />
            )}
          </Flex>
        </Flex>
      </Box>
    </div>
  );
};