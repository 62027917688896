import { useQuery } from "@tanstack/react-query";
import { BigNumber } from "ethers";
import { useContracts } from "./useContracts";
import { useWallet } from "../store/wallet-context";
import { parseEther } from "ethers/lib/utils.js";
import { requestStfPrice } from "../utils/zbcOracleUtil";
import { useTokens } from "./useTokens";
import { LpInfo, LpInfoItem } from "./useLpInfos";

export const useUsdsLpInfos = () => {
  const contracts = useContracts();
  const { accountAddress } = useWallet();
  const manager = contracts?.manager;
  const priceQuery = useQuery({
    queryKey: ["stfOracle"],
    queryFn: async () => {
      const price = await requestStfPrice();
      return price;
    },
  });
  const tokensQuery = useTokens();

  const infoQuery = useQuery({
    queryKey: ["usdsLpInfos"],
    queryFn: async () => {
      const person = await manager!.getPerson(accountAddress!);
      const stakedUsds = person.stakedUsdsStfLp;
      const earnedStf = person.UsdsStfLpCumulativeStfRewardClaimed;
      const cumulativeLpWithdrawnStfInviter1 = person.UsdsStfLpCumulativeLpWithdrawnLpStfInviter1;
      const cumulativeLpWithdrawnStfInviter2 = person.UsdsStfLpCumulativeLpWithdrawnLpStfInviter2;
      const cumulativeLpWithdrawnStfInviter = cumulativeLpWithdrawnStfInviter1.add(cumulativeLpWithdrawnStfInviter2);

      console.log("person", person);
      // console.log("stakedLp", formatEther(stakedUsds));
      // console.log("cumulativeStfRewardClaimed", formatEther(cumulativeLpWithdrawnStfInviter));
      const activeBillIds = await manager!.getUsdsStfLpPersonActiveBill(accountAddress!);
      console.log("activeBillIds", activeBillIds);
      const finishedBillIds = await manager!.getUsdsStfLpPersonFinishedBill(accountAddress!);

      const bills: LpInfoItem[] = await Promise.all(
        [...finishedBillIds, ...activeBillIds].map(async (billNumber, index) => {
          const bill = await manager!.getUsdsStfLpBill(billNumber);
          const claimable = await manager!.callStatic.usdsStfLpGetReward(
            priceQuery.data!.price,
            priceQuery.data!.signature,
            [billNumber],
            { from: accountAddress! },
          );
          return {
            id: billNumber,
            apr: bill.apy,
            staked: bill.lpEquivalentUsd,
            claimable: claimable,
            claimed: bill.cumulativeStfRewardClaimed,
            endTimestamp: bill.finishTimestamp,
          };
        }),
      );
      return {
        lpBalance: parseEther(tokensQuery.data!.stfusdtlp.balance),
        stakedUsdt: BigNumber.from(0),
        stakedStf: BigNumber.from(0),
        stakedLp: stakedUsds,
        earnedStf: earnedStf,
        cumulativeLpWithdrawnStfInviter: cumulativeLpWithdrawnStfInviter,
        totalClaimabledStf: bills.reduce((acc, cur) => acc.add(cur.claimable), BigNumber.from(0)),
        totalClaimedStf: person.UsdsStfLpCumulativeStfRewardClaimed,
        lpInfoItems: bills,
      } as LpInfo;
    },
    enabled: !!manager && !!accountAddress && priceQuery.isSuccess && tokensQuery.isSuccess,
  });

  return infoQuery;
};
