import styles from "./index.module.scss";
import { Box } from "@chakra-ui/react";
import img1 from "../../assets/image/stake/plant.png";
import { useTranslation } from "react-i18next";

export const Banner = () => {
  //@ts-ignore
  const { t } = useTranslation();
  return (
    <Box className={styles.banner}>
      <Box>
        <img className={styles.img1} src={img1} alt="img1" />
      </Box>
      <Box className={styles.bannerTitle}>{t("Farm", "Farm")}</Box>
      <Box className={styles.bannerText}>{t("Stake LP token to Earn", "Stake LP token to Earn")}</Box>
    </Box>
  );
};
