import { Box, Flex, useToast } from "@chakra-ui/react";
import { TokenInput } from "../UI/CommonInput/TokenInput";
import { useTranslation } from "react-i18next";
import stfIcon from "../../assets/image/STF.png";
import { JoinButton } from "../JoinButton";
import { useState } from "react";
import { UsdsLpOracleResult, requestStfPrice } from "../../utils/zbcOracleUtil";
import { callWithToastError, truncAddress, truncateNumber } from "../../utils/utils";
import { formatEther, parseEther } from "ethers/lib/utils.js";
import { useContracts } from "../../hooks/useContracts";
import { BigNumber, ethers } from "ethers";
import { LpInfoItem } from "../../hooks/useLpInfos";
import { useWallet } from "../../store/wallet-context";
import { useQuery } from "@tanstack/react-query";
import styles from "./index.module.scss";
import { use } from "i18next";

export const RemoveUsdsLiquidity = ({
  lpOracleResult,
  bill,
  onClose,
}: {
  lpOracleResult: UsdsLpOracleResult | undefined
  bill: LpInfoItem | null
  onClose: () => void
}) => {
  //@ts-ignore
  const { t } = useTranslation();
  const toast = useToast();
  const contracts = useContracts();
  const { accountAddress } = useWallet();
  const manager = contracts?.manager;

  let stfToGet = BigNumber.from(0);
  const stfOracle = useQuery({
    queryKey: ["stfOracle"],
    queryFn: async () => {
      const stfOracle = await requestStfPrice();
      return stfOracle;
    },
  });
  const isReady = !(!manager || !accountAddress || !bill || !lpOracleResult || !stfOracle.isSuccess);
  if (isReady) {
    const price = stfOracle.data.price.stfPriceFixedPoint18;
    stfToGet = bill!.staked.mul(parseEther("1")).div(price).mul(19).div(20);
  }

  const expectedRewardQuery = useQuery({
    queryKey: ["expectedReward", bill?.id.toHexString()],
    queryFn: async () => {
      if (!manager || !bill) return;
      const reward = await manager.callStatic.usdsStfLpWithdraw([bill.id]);
      return reward;
    },
    enabled: isReady,
  });

  const estimateStfToGet = expectedRewardQuery.isSuccess ? expectedRewardQuery.data!.allStfToSelf : stfToGet;

  // remove
  const [isRemoveLoading, setIsRemoveLoading] = useState(false);
  const handleRemove = async () => {
    if (!manager) return;
    setIsRemoveLoading(true);
    await callWithToastError(
      async () => {
        if (!bill || !manager) return;
        const tx = await manager.usdsStfLpWithdraw([bill.id]);
        const receipt = await tx.wait();
        setIsRemoveLoading(false);
        onClose();
      },
      toast,
      t("Please wait for the bill to be finished or claim the reward first", "Please wait for the bill to be finished or claim the reward first."),
    );
    setIsRemoveLoading(false);
  };

  return (
    <Flex className={styles.container}>
      <Box className={styles.modalTitle}>{t("Withdraw liquidity", "Withdraw liquidity")}</Box>
      <Box className={styles.modalSubTitle}>
        {t("You will get", "You will get")}
      </Box>
      <Flex className={styles.receiveWrapper}>
        <Flex className={styles.inputGroup}>
          <Box className={styles.tokenInputWrapper} width='100%'>
            <TokenInput
              isLoading={false}
              tokenIconSrc={stfIcon}
              value={truncateNumber(formatEther(estimateStfToGet))}
              onChange={() => undefined}
              isDisabled
            />
          </Box>
        </Flex>
      </Flex>

      <Box className={styles.desc}>
        {t(
          "Output is estimated. If the price changes by more than 10% your transaction will revert",
          "Output is estimated. If the price changes by more than 10% your transaction will revert.",
        )}
      </Box>

      <Flex className={styles.metadatas}>
        {expectedRewardQuery.isSuccess && <Flex className={styles.metadata}>
          <Box className={styles.metaLabel}>{t("STF Given", "STF Given")}:</Box>
          <Flex className={styles.priceValues}>
            <Box className={styles.metaValue}>
              {truncAddress(expectedRewardQuery.data!.inviterUp1)} 5%
            </Box>
            {expectedRewardQuery.data!.inviterUp2 !== ethers.constants.AddressZero && <Box className={styles.metaValue}>
              {truncAddress(expectedRewardQuery.data!.inviterUp2)} 5%
            </Box>}
            
          </Flex>
        </Flex>}
      </Flex>

      <Flex className={styles.buttons}>
        <JoinButton handleClick={handleRemove} name={t("Withdraw", "Withdraw")} type="danger" loading={isRemoveLoading} />
      </Flex>
    </Flex>
  );
};
