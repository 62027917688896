import { useQuery } from "@tanstack/react-query";
import { BigNumber } from "ethers";
import { useContracts } from "./useContracts";
import { useWallet } from "../store/wallet-context";
import { parseEther } from "ethers/lib/utils.js";
import { requestStfPrice } from "../utils/zbcOracleUtil";
import { useTokens } from "./useTokens";

export type LpInfoItem = {
  id: BigNumber
  apr: BigNumber
  staked: BigNumber
  claimable: BigNumber
  claimed: BigNumber
  endTimestamp: BigNumber
}

export type LpInfo = {
  lpBalance: BigNumber
  // banner area
  stakedUsdt: BigNumber
  stakedStf: BigNumber
  stakedLp: BigNumber
  earnedStf: BigNumber
  cumulativeLpWithdrawnStfInviter: BigNumber
  // table banner
  totalClaimabledStf: BigNumber
  totalClaimedStf: BigNumber
  // table area
  lpInfoItems: LpInfoItem[]
}

export const useLpInfos = () => {
  const contracts = useContracts();
  const { accountAddress } = useWallet();
  const manager = contracts?.manager;
  const priceQuery = useQuery({
    queryKey: ["stfOracle"],
    queryFn: async () => {
      const price = await requestStfPrice();
      return price;
    },
  });
  const tokensQuery = useTokens();

  const infoQuery = useQuery({
    queryKey: ["lpInfos"],
    queryFn: async () => {
      const person = await manager!.getPerson(accountAddress!);
      const stakedUsdt = person.stakedLp;
      const earnedStf = person.cumulativeStfRewardClaimed;
      const cumulativeLpWithdrawnStfInviter1 = person.cumulativeLpWithdrawnStfInviter1;
      const cumulativeLpWithdrawnStfInviter2 = person.cumulativeLpWithdrawnStfInviter2;
      const cumulativeLpWithdrawnStfInviter = cumulativeLpWithdrawnStfInviter1.add(cumulativeLpWithdrawnStfInviter2);

      console.log("person", person);
      // console.log("stakedLp", formatEther(stakedUsdt));
      // console.log("cumulativeStfRewardClaimed", formatEther(cumulativeLpWithdrawnStfInviter));
      const activeBillIds = await manager!.getLpPersonActiveBill(accountAddress!);
      const finishedBillIds = await manager!.getLpPersonFinishedBill(accountAddress!);
      const bills: LpInfoItem[] = await Promise.all(
        [...finishedBillIds, ...activeBillIds].map(async (billNumber, index) => {
          const bill = await manager!.getLpBill(billNumber);
          const claimable = await manager!.callStatic.lpGetReward(
            priceQuery.data!.price,
            priceQuery.data!.signature,
            [billNumber],
            { from: accountAddress! },
          );
          return {
            id: billNumber,
            apr: bill.apy,
            staked: (bill.lpEquivalentUsd),

            claimable: (claimable),
            claimed: (bill.cumulativeStfRewardClaimed),
            endTimestamp: bill.finishTimestamp,
          };
        }),
      );
      return {
        lpBalance: parseEther(tokensQuery.data!.stfusdtlp.balance),
        stakedUsdt: BigNumber.from(0),
        stakedStf: BigNumber.from(0),
        stakedLp: stakedUsdt,
        earnedStf: earnedStf,
        cumulativeLpWithdrawnStfInviter: cumulativeLpWithdrawnStfInviter,
        totalClaimabledStf: bills.reduce((acc, cur) => acc.add(cur.claimable), BigNumber.from(0)),
        totalClaimedStf: person.cumulativeLpWithdrawnStfSelf,
        lpInfoItems: bills,
      } as LpInfo;
    },
    enabled: !!manager && !!accountAddress && priceQuery.isSuccess && tokensQuery.isSuccess,
  });

  return infoQuery;
};
