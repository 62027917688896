/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Signer, utils, Contract, ContractFactory, Overrides } from "ethers";
import type { Provider, TransactionRequest } from "@ethersproject/providers";
import type { PromiseOrValue } from "../../../../common";
import type {
  VRFReceiverLogic,
  VRFReceiverLogicInterface,
} from "../../../../contracts/dependant/vrfReceiver/VRFReceiverLogic";

const _abi = [
  {
    inputs: [],
    name: "accessControl",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "requestId",
        type: "bytes32",
      },
      {
        internalType: "bytes32",
        name: "randomness",
        type: "bytes32",
      },
    ],
    name: "rawFulfillRandomness",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
] as const;

const _bytecode =
  "0x608060405234801561001057600080fd5b50610258806100206000396000f3fe608060405234801561001057600080fd5b50600436106100365760003560e01c806313007d551461003b578063733da2101461005a575b600080fd5b600054604080516001600160a01b039092168252519081900360200190f35b61006d6100683660046101d0565b61006f565b005b6100776100f0565b6001600160a01b0316336001600160a01b0316146100ec5760405162461bcd60e51b815260206004820152602760248201527f76726620636c69656e743a2063616c6c206261636b206973206e6f74207672666044820152661031b2b73a32b960c91b60648201526084015b60405180910390fd5b5050565b60006100fa610175565b604051630930e56560e31b8152682b292321b2b73a32b960b91b60048201526001600160a01b0391909116906349872b2890602401602060405180830381865afa15801561014c573d6000803e3d6000fd5b505050506040513d601f19601f8201168201806040525081019061017091906101f2565b905090565b600080546001600160a01b03166101c05760405162461bcd60e51b815260206004820152600f60248201526e06e7328293a2061646472657373203608c1b60448201526064016100e3565b506000546001600160a01b031690565b600080604083850312156101e357600080fd5b50508035926020909101359150565b60006020828403121561020457600080fd5b81516001600160a01b038116811461021b57600080fd5b939250505056fea2646970667358221220a7e05c12e874980a2556ba8df6b3e84bf290377c62f67f47878e606a671acfa564736f6c63430008110033";

type VRFReceiverLogicConstructorParams =
  | [signer?: Signer]
  | ConstructorParameters<typeof ContractFactory>;

const isSuperArgs = (
  xs: VRFReceiverLogicConstructorParams
): xs is ConstructorParameters<typeof ContractFactory> => xs.length > 1;

export class VRFReceiverLogic__factory extends ContractFactory {
  constructor(...args: VRFReceiverLogicConstructorParams) {
    if (isSuperArgs(args)) {
      super(...args);
    } else {
      super(_abi, _bytecode, args[0]);
    }
  }

  override deploy(
    overrides?: Overrides & { from?: PromiseOrValue<string> }
  ): Promise<VRFReceiverLogic> {
    return super.deploy(overrides || {}) as Promise<VRFReceiverLogic>;
  }
  override getDeployTransaction(
    overrides?: Overrides & { from?: PromiseOrValue<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(overrides || {});
  }
  override attach(address: string): VRFReceiverLogic {
    return super.attach(address) as VRFReceiverLogic;
  }
  override connect(signer: Signer): VRFReceiverLogic__factory {
    return super.connect(signer) as VRFReceiverLogic__factory;
  }

  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): VRFReceiverLogicInterface {
    return new utils.Interface(_abi) as VRFReceiverLogicInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): VRFReceiverLogic {
    return new Contract(address, _abi, signerOrProvider) as VRFReceiverLogic;
  }
}
