import React from "react";
import { JoinButton } from "../JoinButton";
import { useWallet } from "../../store/wallet-context";
import { truncAddress } from "../../utils/utils";
import { useTranslation } from "react-i18next";

type WalletButtonProps = {
  onClick?: () => void;
};

const WalletButton = ({ onClick = () => {} }: WalletButtonProps) => {
  // @ts-ignore
  const { t } = useTranslation();
  const { connect, disconnect, accountAddress, isConnected } = useWallet();
  if (accountAddress && isConnected) {
    return (
      <JoinButton
        name={truncAddress(accountAddress)}
        handleClick={disconnect}
      />
    );
  }
  return (
    <JoinButton
      name={t("Connect Wallet", "Connect Wallet")}
      handleClick={() => {
        onClick();
        connect();
      }}
    />
  );
};

export default WalletButton;
