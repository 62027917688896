import React from "react";
import { WagmiConfig, createClient, configureChains } from "wagmi";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
import { bsc } from "wagmi/chains";

const nodeUrl = process.env.REACT_APP_NODE_URL;
if (!nodeUrl) throw new Error("REACT_APP_NODE_URL not set");
console.log("nodeUrl is:", nodeUrl);

const { provider } = configureChains(
  [bsc],
  [
    jsonRpcProvider({
      // rpc: () => ({ http: "https://bsc-dataseed.binance.org/" }),
      rpc: () => ({ http: nodeUrl }),
    }),
  ]
);

const client = createClient({
  autoConnect: true,
  provider,
});

const WagmiProvider = ({ children }: { children: React.ReactNode }) => (
  <WagmiConfig client={client}>{children}</WagmiConfig>
);

export default WagmiProvider;
