import { ethers } from "ethers";
import { useNameservice } from "../store/nameservice-context";
import {
  type ERC20,
  ERC20__factory,
  type InvestmentCredentialInterface,
  InvestmentCredentialInterface__factory,
  type InvitationCenterInterface,
  InvitationCenterInterface__factory,
  type ManagerInterface,
  ManagerInterface__factory,
  IPancakeRouter02__factory,
  type IPancakeRouter02,
  IPancakePair,
  IPancakePair__factory,
} from "../typechain";

type ContractStatus = "loading" | "success" | "error";

interface Contracts {
  status: ContractStatus;
  usds?: ERC20;
  usdt?: ERC20;
  zBec?: ERC20;
  stf?: ERC20;
  stfusdtlp?: IPancakePair;
  stfusdslp?: IPancakePair;
  pancakeRouter?: IPancakeRouter02;
  manager?: ManagerInterface;
  investmentCredential?: InvestmentCredentialInterface;
  invitationCenter?: InvitationCenterInterface;
}

export const useContracts = (): Contracts => {
  const ns = useNameservice();
  if (ns === null || window.ethereum == null) {
    return {
      status: "loading",
    };
  }
  const provider = new ethers.providers.Web3Provider(window.ethereum as any);
  const usdsAddress = ns.USDS;
  const usdtAddress = ns.USDT;
  const zBecAddress = ns.ZBCBridged;
  const stfAddress = ns.STF;
  const stfusdtlpAddress = ns.PancakePair;
  const stfusdslpAddress = ns.PancakePairUsdsStf;
  const pancakePairAddress = ns.PancakePair;

  const investmentCredentialAddress = ns.InvestmentCredential;
  const managerAddress = ns.Manager;
  const invitationCenterAddress = ns.InvitationCenter;
  const usds = ERC20__factory.connect(usdsAddress, provider.getSigner());
  const usdt = ERC20__factory.connect(usdtAddress, provider.getSigner());
  const zBec = ERC20__factory.connect(zBecAddress, provider.getSigner());
  const stf = ERC20__factory.connect(stfAddress, provider.getSigner());
  const stfusdtlp = IPancakePair__factory.connect(stfusdtlpAddress, provider.getSigner());
  const stfusdslp = IPancakePair__factory.connect(stfusdslpAddress, provider.getSigner());
  const pancakeRouter = IPancakeRouter02__factory.connect(pancakePairAddress, provider.getSigner());
  const investmentCredential = InvestmentCredentialInterface__factory.connect(
    investmentCredentialAddress,
    provider.getSigner()
  );
  const manager = ManagerInterface__factory.connect(
    managerAddress,
    provider.getSigner()
  );
  const invitationCenter = InvitationCenterInterface__factory.connect(
    invitationCenterAddress,
    provider.getSigner()
  );

  return {
    status: "success",
    usds,
    usdt,
    zBec,
    stf,
    stfusdtlp,
    stfusdslp,
    manager,
    pancakeRouter,
    investmentCredential,
    invitationCenter,
  };
};
