import { ethers } from "ethers";

export const truncAddress = (addr: string) => {
  if (addr && addr.length === 42 && addr !== ethers.constants.AddressZero) {
    return `${addr.substring(0, 6)}...${addr.substring(addr.length - 4)}`;
  }
  return "-";
};

export function truncateNumber(input: string | number, maxDecimalDigits = 4) {
  const str = String(input);
  if (str.includes(".")) {
    const parts = str.split(".");
    let fracturePart = parts[1].slice(0, maxDecimalDigits);
    while (fracturePart.endsWith("0") && fracturePart.length > 1) {
      fracturePart = fracturePart.slice(0, -1);
    }
    return parts[0] + "." + fracturePart;
  }

  return str;
}

export function stringToBytes32(input: string): string {
  return ethers.utils.formatBytes32String(input);
}

export function bytes32ToString(input: string): string {
  return ethers.utils.parseBytes32String(input);
}

export function hexify(input: string): string {
  // return ethers.utils.hexlify(ethers.utils.toUtf8Bytes(input));
  const result = ethers.utils.formatBytes32String(input);
  // console.log('hexify', input, result);
  return result;
}

export async function callWithToastError<T>(
  func: () => Promise<T>,
  toast: any,
  message: string
): Promise<T | null> {
  try {
    return await func();
  } catch (e: unknown) {
    console.error(e);
    if (e instanceof Error) {
      toast({
        // description: e.message,
        description: message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    return null;
  }
}
