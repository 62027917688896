import { useState } from "react";
import styles from "./index.module.scss";
import MainContainer from "../../components/UI/MainContainer/MainContainer";
import { Banner } from "./Banner";
import { LpUsdtStf } from "./LpUsdtStf";
import { Switcher } from "./Switcher";
import { LpUsdsStf } from "./LpUsdsStf";

export const Farm = () => {
  const [selectedTab, setSelectedTab] = useState("usdt");
  const isSelectedUsdt = selectedTab === "usdt";

  return (
    <div className={styles.invest}>
      <MainContainer className={styles.container}>
        <Banner />
        <Switcher value={selectedTab} onChange={setSelectedTab}/>
        {isSelectedUsdt ? <LpUsdtStf /> :  <LpUsdsStf />}
      </MainContainer>
    </div>
  );
};
