import {
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spinner
} from "@chakra-ui/react";
import classNames from "classnames";
import { CommonInput } from ".";
import { useTranslation } from "react-i18next";
import styles from "./index.module.scss";

type Prop = {
  isError?: boolean;
  isDisabled?: boolean;
  placeholder?: string;
  isLoading: boolean;
  tokenIconSrc: string;
  value: string;
  onChange: (e: any) => void;
};

export const TokenInput = ({ isLoading, tokenIconSrc, isError, value, onChange, isDisabled, placeholder }: Prop) => {
  //@ts-ignore
  const { t } = useTranslation();
  return (
    <InputGroup>
      <InputLeftElement
        pointerEvents="none"
        marginTop={1}
        marginLeft={1}
        paddingRight={2}
      >
        <img src={tokenIconSrc} alt="" className={styles.tokenIcon} />
      </InputLeftElement>
      <CommonInput
        value={value}
        onChange={isDisabled ? () => undefined : onChange}
        type="number"
        className={classNames(styles.tokenInput, isError && styles.errorInput)}
        placeholder={placeholder || t("Enter Token Amount", "Enter Amount")}
        disabled={isLoading}
      />
      {isLoading && (
        <InputRightElement
          pointerEvents="none"
          marginTop={1}
          marginLeft={1}
          paddingRight={2}
        >
          <Spinner />
        </InputRightElement>
      )}
    </InputGroup>
  );
};
