import React from "react";
import { Banner } from "../components/Banner";
import { Whyus } from "../components/Whyus";
import { Clients } from "../components/Clients";
import { Ecosystem } from "../components/Ecosystem";
import { Partnership } from "../components/Partnership";

export default function Home() {
  return (
    <div className="Home" style={{ overflow: "clip" }}>
      <div id="home">
        <Banner />
      </div>
      <div id="whyus">
        <Whyus />
      </div>
      <div id="clients">
        <Clients />
      </div>
      <div id="ecosystem">
        <Ecosystem />
      </div>
      <div id="partner">
        <Partnership />
      </div>
      {/* <div id="about"> */}
      {/*  <AboutUs /> */}
      {/* </div> */}
    </div>
  );
}
