/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Signer, utils, Contract, ContractFactory, Overrides } from "ethers";
import type { Provider, TransactionRequest } from "@ethersproject/providers";
import type { PromiseOrValue } from "../../../../common";
import type {
  GenericSafetyClusterRefLogic,
  GenericSafetyClusterRefLogicInterface,
} from "../../../../contracts/dependant/safetyClusterRef/GenericSafetyClusterRefLogic";

const _abi = [
  {
    inputs: [],
    name: "accessControl",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
] as const;

const _bytecode =
  "0x6080604052348015600f57600080fd5b50608280601d6000396000f3fe6080604052348015600f57600080fd5b506004361060285760003560e01c806313007d5514602d575b600080fd5b600054604080516001600160a01b039092168252519081900360200190f3fea2646970667358221220d7a3a2c91189361b6cce90c1c08be559037e7ff5a162faad1ea371badc889ad464736f6c63430008110033";

type GenericSafetyClusterRefLogicConstructorParams =
  | [signer?: Signer]
  | ConstructorParameters<typeof ContractFactory>;

const isSuperArgs = (
  xs: GenericSafetyClusterRefLogicConstructorParams
): xs is ConstructorParameters<typeof ContractFactory> => xs.length > 1;

export class GenericSafetyClusterRefLogic__factory extends ContractFactory {
  constructor(...args: GenericSafetyClusterRefLogicConstructorParams) {
    if (isSuperArgs(args)) {
      super(...args);
    } else {
      super(_abi, _bytecode, args[0]);
    }
  }

  override deploy(
    overrides?: Overrides & { from?: PromiseOrValue<string> }
  ): Promise<GenericSafetyClusterRefLogic> {
    return super.deploy(
      overrides || {}
    ) as Promise<GenericSafetyClusterRefLogic>;
  }
  override getDeployTransaction(
    overrides?: Overrides & { from?: PromiseOrValue<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(overrides || {});
  }
  override attach(address: string): GenericSafetyClusterRefLogic {
    return super.attach(address) as GenericSafetyClusterRefLogic;
  }
  override connect(signer: Signer): GenericSafetyClusterRefLogic__factory {
    return super.connect(signer) as GenericSafetyClusterRefLogic__factory;
  }

  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): GenericSafetyClusterRefLogicInterface {
    return new utils.Interface(_abi) as GenericSafetyClusterRefLogicInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): GenericSafetyClusterRefLogic {
    return new Contract(
      address,
      _abi,
      signerOrProvider
    ) as GenericSafetyClusterRefLogic;
  }
}
