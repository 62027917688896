import React, { Fragment, useState } from "react";
import { JoinButton } from "../../components/JoinButton";
import lpLogo from "../../assets/image/stake/lp.png";
import stfIcon from "../../assets/image/STF.png";
import usdsIcon from "../../assets/image/invest/usds.png";
import useSWR from "swr";
import styles from "./index.module.scss";
import { Box, Flex, Table, TableContainer, Tbody, Td, Th, Thead, Tr, useMediaQuery, useToast } from "@chakra-ui/react";
import { Modal } from "../../components/Modal";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { LpInfoItem } from "../../hooks/useLpInfos";
import { formatLpInfos } from "./utils";
import { requestLpPrice, requestStfPrice, requestUsdsLpPrice } from "../../utils/zbcOracleUtil";
import { useWallet } from "../../store/wallet-context";
import { BigNumber, BigNumberish } from "ethers";
import { useContracts } from "../../hooks/useContracts";
import { callWithToastError, truncateNumber } from "../../utils/utils";
import { useNavigate } from "react-router-dom";
import { useUsdsLpInfos } from "../../hooks/useUsdsLpInfos";
import { AddUsdsLiquidity } from "../../components/AddUsdsLiquidity";
import { RemoveUsdsLiquidity } from "../../components/RemoveLiquidity/RemoveUsdsLiquidity";
import { useTokens } from "../../hooks/useTokens";
import { set } from "lodash";
// import { LpIcon } from "./LpIcon";

export const LpUsdsStf = () => {
  //@ts-ignore
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { accountAddress } = useWallet();
  const { manager } = useContracts();
  const tokens = useTokens();
  const toast = useToast();
  const [showAddLpModal, setShowAddLpModal] = useState(false);
  const [showRemoveLpModal, setShowRemoveLpModal] = useState(false);
  // const [lpOracleResult, setLpOracleResult] = useState<LpOracleResult | null>(null);
  // const [stfOracleResult, setStfOracleResult] = useState<StfOracleResult | null>(null);
  const [expandIndex, setExpandIndex] = useState(-1);
  const lpInfos = useUsdsLpInfos();
  const displayLpInfos = formatLpInfos(lpInfos.data);

  const { data: usdsLpOracleResult } = useSWR("usdsLpOracle", requestUsdsLpPrice, { refreshInterval: 30 * 1000 });
  const { data: lpOracleResult } = useSWR("lpOracle", requestLpPrice, { refreshInterval: 30 * 1000 });
  const { data: stfOracleResult } = useSWR("stfOracle", requestStfPrice, { refreshInterval: 30 * 1000 });

  const handleCloseAddLpModal = () => {
    setShowAddLpModal(false);
  };
  const handleOpenAddLpModal = () => {
    if (!lpOracleResult) return;
    setShowAddLpModal(true);
  };
  const handleCloseRemoveLpModal = () => {
    setShowRemoveLpModal(false);
  };
  const [selectedBill, setSelectedBill] = useState<LpInfoItem | null>(null);
  const handleOpenRemoveLpModal = (billNumber: BigNumberish) => {
    if (!lpOracleResult) return;
    // be sure to find the original bill
    const targetBill = lpInfos.data?.lpInfoItems.find(bill => BigNumber.from(bill.id).eq(billNumber))!;
    setSelectedBill(targetBill);
    setShowRemoveLpModal(true);
  };
  // remove
  const [isWithdrawBillLoading, setIsWithdrawBillLoading] = useState(false);
  const handleWithdrawBill = async (id: BigNumberish) => {
    if (!manager) return;
    setIsWithdrawBillLoading(true);
    await callWithToastError(
      async () => {
        await manager!.lpWithdraw([id]);
        setIsWithdrawBillLoading(false);
      },
      toast,
      t(
        "Please wait for the bill to be finished or claim the reward first",
        "Please wait for the bill to be finished or claim the reward first.",
      ),
    );
  };

  const [isClaimBillLoading, setIsClaimBillLoading] = useState(false);
  const [claimBillId, setClaimBillId] = useState<BigNumberish | null>(null);
  const handleClaimReward = (billNumber: BigNumberish) => {
    if (!manager || !stfOracleResult) return;
    setClaimBillId(billNumber);
    setIsClaimBillLoading(true);
    callWithToastError(
      async () => {
        try {
          const tx = await manager.usdsStfLpGetReward(stfOracleResult.price, stfOracleResult.signature, [billNumber]);
          const receipt = await tx.wait();
          setIsClaimBillLoading(false);
        } catch (error) {
          setIsClaimBillLoading(false);
        }
      },
      toast,
      t("Claim reward failed", "Claim reward failed"),
    );
  };
  const handleClaimAll = () => {
    if (!manager || !stfOracleResult || !lpInfos.isSuccess) return;
    if (lpInfos.data.lpInfoItems.length === 0) {
      toast({
        title: t("No reward to claim", "No reward to claim"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    callWithToastError(
      async () => {
        await manager.usdsStfLpGetReward(
          stfOracleResult.price,
          stfOracleResult.signature,
          lpInfos.data.lpInfoItems.map(bill => bill.id) ?? [],
        );
      },
      toast,
      t("Claim reward failed", "Claim reward failed"),
    );
  };

  const historyInfos = [
    [t("LP token Staked", "LP token Staked"), truncateNumber(displayLpInfos.stakedLp)],
    [t("Earned STF", "Earned STF"), truncateNumber(displayLpInfos.earnedStf)],
    [
      t("Referral rewards(STF)", "Referral rewards(STF)"),
      truncateNumber(displayLpInfos.cumulativeLpWithdrawnStfInviter),
    ],
  ];

  const handleExpandIndex = (index: number) => {
    if (index === expandIndex) {
      setExpandIndex(-1);
    } else {
      setExpandIndex(index);
    }
  };

  const isMobile = useMediaQuery("(max-width: 768px)")[0];

  return (
    <>
      <Box className={styles.card}>
        <Flex wrap="wrap" justifyContent="space-between" w="100%" alignItems="center">
          <Flex flexDirection="column">
            <Flex className={styles.lpBalance} mb={4}>
              <img src={usdsIcon} className={styles.tokenIcon} />
              {t("USDS balance", "USDS balance")}: {truncateNumber(tokens.data?.usds.balance ?? 0)}
            </Flex>
            <Flex className={styles.lpBalance}>
              <img src={stfIcon} className={styles.tokenIcon} />
              {t("STF balance", "STF balance")}: {truncateNumber(tokens.data?.stf.balance ?? 0)}
            </Flex>
          </Flex>
          <Box className={styles.lpBtnGroup}>
            <JoinButton
              handleClick={handleOpenAddLpModal}
              name={t("Add & Stake Liquidity", "Add & Stake Liquidity")}
              className={styles.lpAddStakeBtn}
            />
          </Box>
        </Flex>
      </Box>

      <Flex className={styles.historyInfoFlex}>
        {historyInfos.map(info => (
          <Flex key={info[0]} flexDir="column" className={styles.historyInfo}>
            <Box className={styles.historyInfoTitle}>{info[0]}</Box>
            <Box className={styles.historyInfoValue}>
              <strong>{info[1]}</strong>
            </Box>
          </Flex>
        ))}
      </Flex>

      <Box className={styles.historyTitle}>Stake LP History</Box>

      <Flex className={styles.historyInfoFlex}>
        <Flex flexDir="column" className={classNames(styles.historyInfo, styles.wideHistoryInfo)}>
          <Box className={styles.historyInfoTitle}>{t("Total Claimable", "Total Claimable")}</Box>
          <Box className={styles.historyInfoValue}>
            <strong>{truncateNumber(displayLpInfos.totalClaimabledStf)} STF</strong>
          </Box>
          <JoinButton className={styles.claimAllBtn} name={t("Claim All", "Claim All")} handleClick={handleClaimAll} />
        </Flex>
        <Flex flexDir="column" className={classNames(styles.historyInfo, styles.wideHistoryInfo)}>
          <Box className={styles.historyInfoTitle}>{t("Total Claimed", "Total Claimed")}</Box>
          <Box className={styles.historyInfoValue}>
            <strong>{truncateNumber(displayLpInfos.totalClaimedStf)} STF</strong>
          </Box>
        </Flex>
      </Flex>

      {/* display table on desktop */}
      {!isMobile && (
        <TableContainer className={styles.historyTable}>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>{t("LP Token", "LP Token")}</Th>
                <Th>{t("APR", "APR")}</Th>
                <Th>{t("Staked", "Staked")}</Th>
                <Th>{t("Claimable", "Claimable")}</Th>
                <Th>{t("Claimed", "Claimed")}</Th>
                <Th>{t("Staking Ends", "Staking Ends")}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {displayLpInfos.lpInfoItems.map((history, index) => (
                <Fragment key={history.id.toString()}>
                  <Tr onClick={() => handleExpandIndex(index)} style={{ cursor: "pointer" }}>
                    <Td>
                      <Box className={styles.firstTd}>
                        <Box className={styles.usdsLpIcon}>
                          <img src={usdsIcon} />
                          <img src={stfIcon} />
                        </Box>
                        STF-USDS LP
                      </Box>
                    </Td>
                    <Td>{history.apr}%</Td>
                    <Td>$ {truncateNumber(history.staked)}</Td>
                    <Td>{truncateNumber(history.claimable)}</Td>
                    <Td>{truncateNumber(history.claimed)}</Td>
                    <Td>{history.endTimestamp}</Td>
                    <Td>{expandIndex === index ? <ChevronUpIcon /> : <ChevronDownIcon />}</Td>
                  </Tr>
                  {expandIndex === index && (
                    <>
                      <Tr key={index + 100}>
                        <Td colSpan={6}>
                          <JoinButton
                            handleClick={() => handleClaimReward(history.id)}
                            loading={isClaimBillLoading}
                            className={styles.claimBtn}
                            name={t("Claim", "Claim")}
                          />
                          <JoinButton
                            type="danger"
                            loading={
                              isWithdrawBillLoading && !!selectedBill && BigNumber.from(history.id).eq(selectedBill.id)
                            }
                            handleClick={() => handleOpenRemoveLpModal(history.id)}
                            className={styles.withdrawBtn}
                            name={t("Withdraw", "Withdraw")}
                          />
                        </Td>
                      </Tr>
                    </>
                  )}
                </Fragment>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      )}

      {/* display cards on mobile */}
      {isMobile && (
        <>
          {displayLpInfos.lpInfoItems.map((history, index) => {
            return (
              <Box key={`lp-${index}`} className={styles.historyCard}>
                <Box className={styles.title}>
                  <Box className={styles.usdsLpIcon}>
                    <img src={usdsIcon} />
                    <img src={stfIcon} />
                  </Box>
                  STF-USDS LP
                </Box>
                <Flex className={styles.hisotryAttribute}>
                  <Box>{t("APR", "APR")}</Box>
                  <Box>{history.apr}%</Box>
                </Flex>
                <Flex className={styles.hisotryAttribute}>
                  <Box>{t("Staked Liquidity", "Staked Liquidity")}</Box>
                  <Box>$ {truncateNumber(history.staked)}</Box>
                </Flex>
                <Flex className={styles.hisotryAttribute}>
                  <Box>{t("Claimable", "Claimable")}</Box>
                  <Box>{truncateNumber(history.claimable)}</Box>
                </Flex>
                <Flex className={styles.hisotryAttribute}>
                  <Box>{t("Claimed", "Claimed")}</Box>
                  <Box>{truncateNumber(history.claimed)}</Box>
                </Flex>
                <Flex className={styles.hisotryAttribute}>
                  <Box>{t("Staking Ends", "Staking Ends")}</Box>
                  <Box>{history.endTimestamp}</Box>
                </Flex>
                <Flex className={styles.hisotryAttribute}>
                  <JoinButton
                    handleClick={() => handleClaimReward(history.id)}
                    loading={isClaimBillLoading && !!claimBillId && BigNumber.from(claimBillId).eq(history.id)}
                    className={styles.claimBtn}
                    name={t("Claim", "Claim")}
                  />
                  <JoinButton
                    handleClick={() => handleOpenRemoveLpModal(history.id)}
                    loading={isWithdrawBillLoading && !!selectedBill && BigNumber.from(history.id).eq(selectedBill.id)}
                    type="danger"
                    className={styles.withdrawBtn}
                    name={t("Withdraw", "Withdraw")}
                  />
                </Flex>
              </Box>
            );
          })}
        </>
      )}
      {showAddLpModal && (
        <Modal setIsOpen={handleCloseAddLpModal} className={styles.nftModal} closable={true}>
          <AddUsdsLiquidity lpOracleResult={usdsLpOracleResult} onClose={handleCloseAddLpModal} />
        </Modal>
      )}
      {showRemoveLpModal && (
        <Modal setIsOpen={handleCloseRemoveLpModal} className={styles.nftModal} closable={true}>
          <RemoveUsdsLiquidity
            lpOracleResult={usdsLpOracleResult}
            bill={selectedBill}
            onClose={handleCloseRemoveLpModal}
          />
        </Modal>
      )}
    </>
  );
};
