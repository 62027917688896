import React from "react";
import { Box, Flex, Spinner } from "@chakra-ui/react";
import "./index.css";
import classNames from "classnames";

type JoinButtonProps = {
  handleClick: any;
  name?: string;
  outline?: boolean;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  type?: string; 
};
export function JoinButton({
  handleClick,
  name,
  outline,
  className,
  disabled,
  loading,
  type
}: JoinButtonProps) {
  return (
    <Box
      className={classNames(
        "JoinButtonBox",
        type === "danger" && "dangerButton",
        outline && "outlinedButton",
        disabled && "disabledButton",
        loading && "disabledButton",
        className
      )}
      onClick={disabled || loading ? () => {} : handleClick}
    >
      <Flex lineHeight="46px" justifyContent="center" gap={2}>
        {name || "Subscribe"}
        {loading && (
          <Box mt={1}>
            <Spinner />
          </Box>
        )}
      </Flex>
    </Box>
  );
}
