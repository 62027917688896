import React from "react";
import ReactDOM from "react-dom/client";
import { ChakraProvider } from "@chakra-ui/react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import WagmiProvider from "./store/wagmi-context";
import NameserviceProvider from "./store/nameservice-context";
import "./i18n";

import App from "./App";
import Home from "./pages/Home";
import EarlyBird from "./pages/EarlyBird/EarlyBird";

import "./index.css";
import WalletProvider from "./store/wallet-context";
import { Register } from "./pages/Register/Register";
import { Invest } from "./pages/Invest/Invest";
import { Profile } from "./pages/Profile/Profile";
import { Mining } from "./pages/Mining/Mining";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Farm } from "./pages/Farm";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "earlybird",
        element: <EarlyBird />,
      },
      {
        path: "register",
        element: <Register />,
      },
      {
        path: "invest",
        element: <Invest />,
      },
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "mining",
        element: <Mining />,
      },
      {
        path: "farm",
        element: <Farm />,
      },
    ],
  },
]);
root.render(
  <React.StrictMode>
    <ChakraProvider>
      <WagmiProvider>
        <NameserviceProvider>
          <WalletProvider>
            <QueryClientProvider client={queryClient}>
              <RouterProvider router={router} />
            </QueryClientProvider>
          </WalletProvider>
        </NameserviceProvider>
      </WagmiProvider>
    </ChakraProvider>
  </React.StrictMode>
);
