import React from "react";
import { Box, Grid, GridItem } from "@chakra-ui/react";
import "./index.scss";
import { useTranslation } from "react-i18next";

const ecosystemItems = [
  { name: "zebec" },
  { name: "nautilus" },
  { name: "bnbChain" },
  { name: "poseiswap" },
  { name: "circle" },
  { name: "polygon" },
  { name: "arf" },
  { name: "superfluid" },
  { name: "jia" },
  { name: "request" },
  { name: "bulla" },
  { name: "spectral" },
  { name: "zksync" },
  { name: "safe" },
  { name: "zkjoey" },
  { name: "krypto" },
  { name: "tribe" },
];

export function Ecosystem() {
  // @ts-ignore
  const { t } = useTranslation();
  return (
    <Box className="ecosystemContainer">
      <Box className="ecosystemContent">
        <Box className="ecosystemTextContainer">
          <Box className="ecosystemSlogan">{t("Ecosystem", "Ecosystem")}</Box>
          {/* <Box className="ecosystemText">
            {t(
              "ecosystem desc",
              "We've done it carefully and simply. Combined with the ingredients makes for beautiful landings. It is definitely the tool you need to speed up your design process."
            )}
          </Box> */}
        </Box>
        <Grid
          className="ecosystemItemContainer"
          templateColumns="repeat(4, 1fr)"
          gap={{ base: 2, md: 8 }}
        >
          {ecosystemItems.map((item) => (
            <GridItem w="100%" h="50px" key={item.name} className="gridItem">
              <img src={`/image/ecosystem/${item.name}.png`} alt={item.name} />
            </GridItem>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}
