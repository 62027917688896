import axios from "axios";

type Signature = {
  r: string;
  s: string;
  v: number;
};
export interface OracleResult {
  price: {
    zbcPriceFixedPoint18: string;
    timestamp: string;
  };
  signature: Signature;
}
const baseUrl = process.env.REACT_APP_API_URL!;

const oracleUrl = process.env.REACT_APP_BACKEND_URL! + "/api1/";
export const requestZbcPrice = async () => {
  const result = await axios.get<OracleResult>(oracleUrl);
  return result.data;
};

export interface UsdsLpOracleResult {
  price: {
    usdsStfLpPriceFixedPoint18: string;
    timestamp: string;
  };
  signature: Signature;
}
export interface LpOracleResult {
  price: {
    lpPriceFixedPoint18: string;
    timestamp: string;
  };
  signature: Signature;
}
const lpOracleUrl = process.env.REACT_APP_BACKEND_URL! + "/api1/lpPrice";
export const requestLpPrice = async () => {
  const result = await axios.get<LpOracleResult>(lpOracleUrl);
  return result.data;
};
const usdsLpOracleUrl = process.env.REACT_APP_BACKEND_URL! + "/api1/usdsLpPrice";
export const requestUsdsLpPrice = async () => {
  const result = await axios.get<UsdsLpOracleResult>(usdsLpOracleUrl);
  return result.data;
};

export interface StfOracleResult {
  price: {
    stfPriceFixedPoint18: string;
    timestamp: string;
  };
  signature: Signature;
}
const stfOracleUrl = process.env.REACT_APP_BACKEND_URL! + "/api1/stfPrice";
export const requestStfPrice = async () => {
  const result = await axios.get<StfOracleResult>(stfOracleUrl);
  return result.data;
};

interface AccountInfo {
  registryIndex: number;
  address: string;
  inviter: string;
  tier: string;
  tierForce: string;
  investUsdtAmount: string;
  inviteeNumber: number;
  totalInviteeInvestUsdtAmount: string;
  largestInviteeInvestUsdtAmount: string;
  largestInviteeInvestAddress: string;
  effectiveInviteeInvestUsdtAmount: string;
  lastOperatedBusinessIndex: number;
  lastOperatedBusinessDomain: string;
  allInvitees?: AccountInfo[];
}

// const infoUrl = baseUrl + '/api2/info/';
// export const requestAccountInfo = async (address: string) => {
//   const result = await axios.post<AccountInfo>(infoUrl, { address });
//   return result.data;
// };

// // 可以领取的奖励
// const claimableReward = process.env.REACT_APP_API_URL! + '/claimablereward/';
// export const requestClaimableReward = async (address: string) => {
//   const result = await axios.post<any>(claimableReward, { address });
//   return result.data;
// };

// // 已经领取的奖励
// const claimedreward = process.env.REACT_APP_API_URL! + '/claimedreward/';
// export const requestClaimedReward = async (address: string) => {
//   const result = await axios.post<any>(claimedreward, { address });
//   return result.data;
// };
