/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type {
  ManagerInterface4,
  ManagerInterface4Interface,
} from "../../../contracts/manager/ManagerInterface4";

const _abi = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "who",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "usdtCost",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256[]",
        name: "earlyAccessIds",
        type: "uint256[]",
      },
    ],
    name: "BuyEarlyAccess",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "who",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "usdsAmount",
        type: "uint256",
      },
    ],
    name: "GetInvestmentReward",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "who",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "reward",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "bonus",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "stfToSelf",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "stfToDead",
        type: "uint256",
      },
    ],
    name: "GetReward",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "who",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "usdtAll",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "zbcUsdtPrice",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "usdtCost",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "zbcCost",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "investmentCredentialId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "usdsAmount",
        type: "uint256",
      },
    ],
    name: "Invest",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "who",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "usdsAmount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "usdtAmount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "usdtToColdWallet",
        type: "uint256",
      },
    ],
    name: "RedeemUsds",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "who",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "inviter",
        type: "address",
      },
    ],
    name: "Register",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "who",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "usds",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "power",
        type: "uint256",
      },
    ],
    name: "Stake",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "who",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "usdtAmount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "usdsAmount",
        type: "uint256",
      },
    ],
    name: "UsdtPurchaseUsds",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "who",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "usds",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "usdsToBurn",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "power",
        type: "uint256",
      },
    ],
    name: "Withdraw",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "who",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "usdsAmount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "ticketNumber",
        type: "uint256",
      },
    ],
    name: "WithdrawPromotionUsds",
    type: "event",
  },
  {
    inputs: [],
    name: "accessControl",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "lpPriceFixedPoint18",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "timestamp",
            type: "uint256",
          },
        ],
        internalType: "struct ManagerType.LpPriceParam",
        name: "param",
        type: "tuple",
      },
    ],
    name: "calcLpPriceParamDigest",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getLpPriceParamTypeHash",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "stfPriceFixedPoint18",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "timestamp",
            type: "uint256",
          },
        ],
        internalType: "struct ManagerType.StfPriceParam",
        name: "param",
        type: "tuple",
      },
      {
        components: [
          {
            internalType: "bytes32",
            name: "r",
            type: "bytes32",
          },
          {
            internalType: "bytes32",
            name: "s",
            type: "bytes32",
          },
          {
            internalType: "uint8",
            name: "v",
            type: "uint8",
          },
        ],
        internalType: "struct ManagerType.Signature",
        name: "signature",
        type: "tuple",
      },
      {
        internalType: "uint256[]",
        name: "bills",
        type: "uint256[]",
      },
    ],
    name: "lpGetReward",
    outputs: [
      {
        internalType: "uint256",
        name: "stfReward",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "lpPriceFixedPoint18",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "timestamp",
            type: "uint256",
          },
        ],
        internalType: "struct ManagerType.LpPriceParam",
        name: "param",
        type: "tuple",
      },
      {
        components: [
          {
            internalType: "bytes32",
            name: "r",
            type: "bytes32",
          },
          {
            internalType: "bytes32",
            name: "s",
            type: "bytes32",
          },
          {
            internalType: "uint8",
            name: "v",
            type: "uint8",
          },
        ],
        internalType: "struct ManagerType.Signature",
        name: "signature",
        type: "tuple",
      },
      {
        internalType: "uint256",
        name: "lpAmount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "stakeTimespan",
        type: "uint256",
      },
    ],
    name: "lpStake",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256[]",
        name: "bills",
        type: "uint256[]",
      },
    ],
    name: "lpWithdraw",
    outputs: [
      {
        internalType: "uint256",
        name: "allUsdtAmount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "allStfToSelf",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "inviterUp1",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "allStfToInviter1",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "inviterUp2",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "allStfToInviter2",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newAssociatedOperator",
        type: "address",
      },
      {
        internalType: "bool",
        name: "flag",
        type: "bool",
      },
    ],
    name: "setAssociatedOperator",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
] as const;

export class ManagerInterface4__factory {
  static readonly abi = _abi;
  static createInterface(): ManagerInterface4Interface {
    return new utils.Interface(_abi) as ManagerInterface4Interface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): ManagerInterface4 {
    return new Contract(address, _abi, signerOrProvider) as ManagerInterface4;
  }
}
