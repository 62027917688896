/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Signer, utils, Contract, ContractFactory, Overrides } from "ethers";
import type { Provider, TransactionRequest } from "@ethersproject/providers";
import type { PromiseOrValue } from "../../../common";
import type {
  NameServiceRefLogic,
  NameServiceRefLogicInterface,
} from "../../../contracts/nameServiceRef/NameServiceRefLogic";

const _abi = [
  {
    inputs: [],
    name: "accessControl",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
] as const;

const _bytecode =
  "0x6080604052348015600f57600080fd5b50608280601d6000396000f3fe6080604052348015600f57600080fd5b506004361060285760003560e01c806313007d5514602d575b600080fd5b600054604080516001600160a01b039092168252519081900360200190f3fea2646970667358221220ace22f1e4cf162d69620de2fbe14e6577b4c6296de51362ffbbd32065c40413464736f6c63430008110033";

type NameServiceRefLogicConstructorParams =
  | [signer?: Signer]
  | ConstructorParameters<typeof ContractFactory>;

const isSuperArgs = (
  xs: NameServiceRefLogicConstructorParams
): xs is ConstructorParameters<typeof ContractFactory> => xs.length > 1;

export class NameServiceRefLogic__factory extends ContractFactory {
  constructor(...args: NameServiceRefLogicConstructorParams) {
    if (isSuperArgs(args)) {
      super(...args);
    } else {
      super(_abi, _bytecode, args[0]);
    }
  }

  override deploy(
    overrides?: Overrides & { from?: PromiseOrValue<string> }
  ): Promise<NameServiceRefLogic> {
    return super.deploy(overrides || {}) as Promise<NameServiceRefLogic>;
  }
  override getDeployTransaction(
    overrides?: Overrides & { from?: PromiseOrValue<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(overrides || {});
  }
  override attach(address: string): NameServiceRefLogic {
    return super.attach(address) as NameServiceRefLogic;
  }
  override connect(signer: Signer): NameServiceRefLogic__factory {
    return super.connect(signer) as NameServiceRefLogic__factory;
  }

  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): NameServiceRefLogicInterface {
    return new utils.Interface(_abi) as NameServiceRefLogicInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): NameServiceRefLogic {
    return new Contract(address, _abi, signerOrProvider) as NameServiceRefLogic;
  }
}
