import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import { HashLink as Link } from "react-router-hash-link";
import "./index.css";
import { JoinButton } from "../JoinButton";
import { CloseIcon } from "@chakra-ui/icons";
import classNames from "classnames";
import WalletButton from "../WalletButton/WalletButton";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export function Hamburger({
  closeMenu,
  className,
  links,
  appLink,
  isHomepage
}: {
  closeMenu: () => void;
  className?: string;
  links: { name: string; link: string }[];
  appLink: string;
  isHomepage?: boolean;
}) {
  // @ts-ignore
  const { t } = useTranslation();
  const navigate = useNavigate();

  const hanldleLaunchApp = () => {
    closeMenu();
    navigate(appLink);
  };

  return (
    <Box
      className={classNames("hamburgerContainer", className)}
      w="80%"
      right={0}
      height="600px"
      background="white"
      position="fixed"
      zIndex={900}
      padding={10}
      transition={"all 0.2s ease"}
    >
      <Flex w="100%" justify="space-around">
        <Flex width="200px">
          <Box className="subscriptionLogo" />
          <Box className="subscriptionLogoText">Settle finance</Box>
        </Flex>
        <CloseIcon
          color="#391484"
          mt={1}
          cursor="pointer"
          onClick={closeMenu}
        />
      </Flex>
      {links.map((link) => {
        return (
          <Link
            to={link.link}
            className="hamburgerLink"
            key={link.name}
            onClick={closeMenu}
          >
            <Box className="hambergerLink">{t(link.name)}</Box>
          </Link>
        );
      })}
      {isHomepage ? (
        <JoinButton handleClick={() => hanldleLaunchApp()} name="Launch App" />
      ) : (
        <Box className="hamburgerConnectButton">
          <WalletButton onClick={closeMenu} />
        </Box>
      )}
    </Box>
  );
}
