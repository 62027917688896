import styles from "./Switcher.module.scss";
import { Box } from "@chakra-ui/react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

type Prop = {
  value: string;
  onChange: (value: string) => void;
};
export const Switcher = (prop: Prop) => {
  const { value, onChange } = prop;
  const isUsdt = value === "usdt";
  const toggle = () => {
    if (isUsdt) {
      onChange("usds");
    } else {
      onChange("usdt");
    }
  };
  //@ts-ignore
  const { t } = useTranslation();
  return (
    <Box className={styles.container}>
      <Box className={styles.wrapper} onClick={toggle}>
        <Box className={classNames(styles.item, isUsdt && styles.isBlack)} onClick={toggle}>
          USDT-STF
        </Box>
        <Box className={classNames(styles.item, !isUsdt && styles.isBlack)} onClick={toggle}>
          USDS-STF
        </Box>
        <Box className={classNames(styles.switcherBg, !isUsdt && styles.isSwitched)} />
      </Box>
    </Box>
  );
};
