import { BigNumber, ethers } from "ethers";
import { formatEther, parseEther } from "ethers/lib/utils.js";

export function calcOpponentTokenAmount(
  inputAmount: string | number,
  pairReserveTokens: { stfReserve: BigNumber; usdsReserve: BigNumber },
  inputStf: boolean = false,
) {
  if (Number(inputAmount) === 0) return "";
  const { stfReserve, usdsReserve } = pairReserveTokens;
  // stf price  = 1 / ratio
  const ratio = stfReserve.mul(parseEther("1")).div(usdsReserve);

  const targetAmount = inputStf
    ? parseEther(String(inputAmount)).mul(parseEther("1")).div(ratio)
    // input usds by default
    : parseEther(String(inputAmount)).mul(ratio).div(parseEther("1"));
  return formatEther(targetAmount);
}
