import React, { useEffect, useState } from "react";
import { JoinButton } from "../../components/JoinButton";
import img1 from "../../assets/image/register/referal.png";

import styles from "./Register.module.scss";
import { Box, Flex, useToast } from "@chakra-ui/react";
import { CommonInput } from "../../components/UI/CommonInput";
import { useLocation, useNavigate } from "react-router-dom";
import { useWallet } from "../../store/wallet-context";
import { ethers } from "ethers";
import { callWithToastError } from "../../utils/utils";
import { useContracts } from "../../hooks/useContracts";
import { useIsRegistered } from "../../hooks/useIsRegistered";
import { useTranslation } from "react-i18next";

export const Register = () => {
  //@ts-ignore
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();
  const [txLoading, setTxLoading] = useState(false); // register tx loading
  const location = useLocation();
  const contracts = useContracts();
  const searchParams = new URLSearchParams(location.search);
  const refCode = searchParams.get("ref") || "";
  const [referralCode, setReferralCode] = useState(refCode);
  const { accountAddress, isConnected } = useWallet();
  const isRegisteredQuery = useIsRegistered();

  useEffect(() => {
    if (isRegisteredQuery.data === true) {
      navigate("/invest");
    }
  }, [isRegisteredQuery]);

  const handleRegister = async () => {
    const invitationCenter = contracts.invitationCenter!;
    const manager = contracts.manager!;
    const isRegistered = await invitationCenter.isRegistered(accountAddress!);
    console.log("isRegistered:", isRegistered);

    if (!isRegistered) {
      if (!referralCode) {
        toast({
          title: t(
            "Please input referral code",
            "Please input referral code."
          ),
          status: "error",
          duration: 1500,
          isClosable: true
        });
        return;
      }
      const code = ethers.utils.formatBytes32String(referralCode);
      const inviterAddress = await invitationCenter.inviterCodeToAddress(code);
      console.log("inviter code:", ethers.utils.parseBytes32String(code));
      console.log("inviter address:", inviterAddress);
      if(inviterAddress === ethers.constants.AddressZero) {
        toast({
          title: t(
            "Referral code error",
            "Referral code error, please check again."
          ),
          status: "error",
          duration: 1500,
          isClosable: true
        });
        return;
      }
      const registerUser = async () => {
        const gas = await manager.estimateGas.register(code);
        const tx = await manager.register(code, {
          gasLimit: gas.mul(150).div(100)
        });
        const receipt = await tx.wait();
        return receipt;
      };
      setTxLoading(true);
      const receipt = await callWithToastError(
        registerUser,
        toast,
        t("error occured", "An error occured. Please try again later.")
      );
      setTxLoading(false);
      if (receipt) {
        toast({
          title: t("Register successfully", "Register successfully."),
          status: "success",
          duration: 1500,
          isClosable: true
        });
        setTimeout(() => {
          navigate("/invest");
        }, 1500);
      }
    } else {
      const records = await invitationCenter.inviterRecords(accountAddress!);
      console.log("inviter:", records.inviter);
      toast({
        title: t(
          "You have already registered, navigate to invest page",
          "You have already registered, navigate to invest page."
        ),
        status: "success",
        duration: 1500,
        isClosable: true
      });
      setTimeout(() => {
        navigate("/invest");
      }, 1500);
    }
  };
  return (
    <div className={styles.register}>
      <Box className={styles.container}>
        <div className={styles.referal}>
          <img src={img1} alt="img1" />
        </div>
        <Flex
          className={styles.card}
          flexDir="column"
          justifyContent="center"
          gap={10}
        >
          <Box className={styles.cardTitle}>
            {t("Welcome to Settle Finance", "Welcome to Settle Finance")}
          </Box>
          <Box className={styles.cardText}>
            {t(
              "Please input your referral code to register",
              "Please input your referral code to register."
            )}
          </Box>
          <Box className={styles.referralPaste}>
            <CommonInput
              value={referralCode}
              onChange={(e) => setReferralCode(e.target.value)}
              placeholder={t(
                "Paste Referral Code Here",
                "Paste Referral Code Here"
              )}
            />
          </Box>
          <Box textAlign="right" w="80%">
            <JoinButton
              handleClick={handleRegister}
              name={t("Register", "Register")}
              disabled={!isConnected}
              loading={txLoading}
            />
          </Box>
        </Flex>
      </Box>
    </div>
  );
};
