import React, { ChangeEvent } from "react";
import { Input } from "@chakra-ui/react";
import classNames from "classnames";
import styles from "./index.module.scss";

type CommonInputProps = {
  placeholder?: string;
  className?: string;
  type?: "text" | "number";
  disabled?: boolean;
  value: string;
  onChange: (e: any) => void;
  isError?: boolean;
};

export function CommonInput({
  placeholder = "",
  value,
  onChange,
  className,
  type = "text",
  disabled,
  isError,
}: CommonInputProps) {
  const onValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (type === "number") {
      if (!Number.isNaN(Number(e.target.value))) {
        onChange(e);
      }
    } else {
      onChange(e);
    }
  };
  return (
    <Input
      variant="outline"
      placeholder={placeholder}
      value={value}
      onChange={onValueChange}
      size="lg"
      borderRadius={40}
      paddingLeft={5}
      className={classNames(className, isError && styles.errorInput)}
      disabled={disabled}
    />
  );
}
