import React from "react";
import { Box } from "@chakra-ui/react";
import "./index.css";
import { useTranslation } from "react-i18next";
const bg = require("../../assets/image/clients/mainImg.png");

const links = [
  {
    title: "Jia Pioneer Fund",
    description: "This is a fund base in San Fransisco",
    icon: "/image/fund.png",
  },
  {
    title: "Air Credit Line",
    description: "Travel ticket credit service provider",
    icon: "/image/credit.png",
  },
  {
    title: "Superfluid Stream Factoring",
    description: "Superfluid Stream Factoring desc", // Superfluid is a revolutionary asset streaming protocol that brings subscriptions, salaries, vesting, and rewards to DAOs and crypto-native businesses worldwide.
    icon: "/image/wallet.png",
  },
];

export function Clients() {
  // @ts-ignore
  const { t } = useTranslation();
  return (
    <Box className="clientsContainer">
      <Box className="clientsContent">
        <Box className="clientsLeft">
          <img src={bg} className="clientsLeftImg" alt="" />

          <Box className="clientsTitle">{t("Our clients", "Our clients")}</Box>
          <Box className="clientsText">
            {t(
              "our client desc",
              "Good things come in 3s. Get 3x Leveraged tokens now."
            )}
          </Box>

          <Box className="clientGoldenLabel">
            {t("Powered by Settle finance", "Powered by Settle finance")}{" "}
            <Box className="clientKeyIcon" />
          </Box>
        </Box>

        <Box className="clientsRight">
          {links.map((link) => {
            return (
              <Box key={link.title} className="clientsItemContainer">
                <Box className="clientsItemSlogan">{t(link.title)}</Box>
                <Box className="clientsItemText">{t(link.description)}</Box>
                <Box className="clientsItemGap"></Box>
                <Box className="clientsItemComing">
                  {t("coming soon", "coming soon")}
                </Box>
                <Box className="clientsItemImg">
                  <img src={link.icon} alt="" />
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
}
