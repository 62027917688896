import React from "react";
import { Box } from "@chakra-ui/react";
import "./index.css";
import "./carousel.css";

export function CarouselItem({
  name,
  url,
  urlName,
  pic,
  desc,
}: {
  name: string;
  url: string;
  urlName: string;
  pic: string;
  desc: string;
}) {
  return (
    <Box className="keen-slider__slide">
      <Box className="carouselItemContainer">
        <Box className="carouselImage">
          <img src={pic} alt="" />
        </Box>
        <Box className="carouselText">{name}</Box>
        <Box className="carouselItemDetail" onClick={() => window.open(url)}>
          {desc}
        </Box>
        {/*<Box className="goldenLabel" style={{ textAlign: "center" }}>*/}
        {/*  {urlName} <Box className="carouselItemArrow" />*/}
        {/*</Box>*/}
      </Box>
    </Box>
  );
}
