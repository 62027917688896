import { CloseIcon } from "@chakra-ui/icons";
import { Box } from "@chakra-ui/react";
import React from "react";
import "./index.css";
import classNames from "classnames";

type ModalProps = {
  setIsOpen: (open: boolean) => void;
  children?: React.ReactNode;
  className?: string;
  autoClose?: boolean;
  closable?: boolean;
};

export function Modal({
  setIsOpen,
  children,
  className,
  autoClose,
  closable = true,
}: ModalProps) {
  return (
    <>
      <div
        className="darkBG"
        onClick={autoClose ? () => setIsOpen(false) : () => {}}
      />
      <div className="centered">
        <div className={classNames("modal", className)}>
          {closable && (
            <button className="closeBtn" onClick={() => setIsOpen(false)}>
              <CloseIcon boxSize={6} />
            </button>
          )}
          <Box
            className={classNames(
              "modalContent",
              !closable && "modalContentWithoutCloseBtn"
            )}
          >
            {children}
          </Box>
        </div>
      </div>
    </>
  );
}
