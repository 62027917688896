import React, { useContext } from "react";
import { ethers } from "ethers";
import { useContractRead } from "wagmi";
import abi from "../abi/NameServiceInterface";

export enum NameserviceKeys {
  AggregateCall = "AggregateCall",
  EarlyAccess = "EarlyAccess",
  EarlyAccessSaleUsdtReceive = "EarlyAccessSaleUsdtReceive",
  ICSaleZbcAndUsdtColdWallet = "ICSaleZbcAndUsdtColdWallet",
  InvestmentCredential = "InvestmentCredential",
  InvitationCenter = "InvitationCenter",
  Manager = "Manager",
  OnlyBuyEarlyAccess = "OnlyBuyEarlyAccess",
  PromotionUsdtReceive = "PromotionUsdtReceive",
  PancakePair = "PancakePair",
  STF = "STF",
  STFUSDTLP = "STFUSDTLP",
  TestMark = "TestMark",
  USDS = "USDS",
  USDT = "USDT",
  UsdsRedemptionUsdtMix = "UsdsRedemptionUsdtMix",
  UsdsStakeStfDispatch = "UsdsStakeStfDispatch",
  UsdsStakeUsdsMix = "UsdsStakeUsdsMix",
  UsdsStakeWithdrawFeeUsdsReceive = "UsdsStakeWithdrawFeeUsdsReceive",
  ZBCBridged = "ZBCBridged",
}

type NameserviceContextType = Record<string, string> | null;

const NameserviceContext = React.createContext<NameserviceContextType>(null);

export const useNameservice = () => useContext(NameserviceContext);

const decodeEntries = (records: Array<Record<string, string>> | undefined) =>
  records != null
    ? records.reduce(
      (accum, item) => ({
        ...accum,
        [ethers.utils.parseBytes32String(item.name)]: item.addr,
      }),
      {}
    )
    : null;

const useProvideNameservice = () => {
  const { data: singleEntries } = useContractRead({
    address: ethers.utils.getAddress(process.env.REACT_APP_NS!),
    abi,
    functionName: "listSingleEntries",
  });
  const decodedSingleEntries = decodeEntries(singleEntries as any);
  return decodedSingleEntries;
};

const NameserviceProvider = ({ children }: { children: React.ReactNode }) => {
  const addresses = useProvideNameservice();
  return (
    <NameserviceContext.Provider value={addresses}>
      {children}
    </NameserviceContext.Provider>
  );
};

export default NameserviceProvider;
