/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type {
  ManagerInterface3,
  ManagerInterface3Interface,
} from "../../../contracts/manager/ManagerInterface3";

const _abi = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "who",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "usdtCost",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256[]",
        name: "earlyAccessIds",
        type: "uint256[]",
      },
    ],
    name: "BuyEarlyAccess",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "who",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "usdsAmount",
        type: "uint256",
      },
    ],
    name: "GetInvestmentReward",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "who",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "reward",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "bonus",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "stfToSelf",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "stfToDead",
        type: "uint256",
      },
    ],
    name: "GetReward",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "who",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "usdtAll",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "zbcUsdtPrice",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "usdtCost",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "zbcCost",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "investmentCredentialId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "usdsAmount",
        type: "uint256",
      },
    ],
    name: "Invest",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "who",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "usdsAmount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "usdtAmount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "usdtToColdWallet",
        type: "uint256",
      },
    ],
    name: "RedeemUsds",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "who",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "inviter",
        type: "address",
      },
    ],
    name: "Register",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "who",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "usds",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "power",
        type: "uint256",
      },
    ],
    name: "Stake",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "who",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "usdtAmount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "usdsAmount",
        type: "uint256",
      },
    ],
    name: "UsdtPurchaseUsds",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "who",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "usds",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "usdsToBurn",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "power",
        type: "uint256",
      },
    ],
    name: "Withdraw",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "who",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "usdsAmount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "ticketNumber",
        type: "uint256",
      },
    ],
    name: "WithdrawPromotionUsds",
    type: "event",
  },
  {
    inputs: [],
    name: "accessControl",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "stfPriceFixedPoint18",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "timestamp",
            type: "uint256",
          },
        ],
        internalType: "struct ManagerType.StfPriceParam",
        name: "param",
        type: "tuple",
      },
    ],
    name: "calcStfPriceParamDigest",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "domainSeparator",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "start",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "maxNumber",
        type: "uint256",
      },
    ],
    name: "getAction",
    outputs: [
      {
        internalType: "bool",
        name: "hasMore",
        type: "bool",
      },
      {
        internalType: "uint256[]",
        name: "actionIndexes",
        type: "uint256[]",
      },
      {
        internalType: "uint256[]",
        name: "actionTypes",
        type: "uint256[]",
      },
      {
        internalType: "bytes[]",
        name: "actionData",
        type: "bytes[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getActionIndex",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getGlobalConfig",
    outputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "oneDay",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "investUsdtOnlyMinimal",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "investmentCredentialUsdtToZbcNumerator",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "investmentCredentialUsdtToZbcDenominator",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "investmentDuration",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "gap0",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "gap1",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "gap2",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "gap3",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "gap4",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "earlyAccessUsdtStock",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "earlyAccessUsdtPrice",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "earlyAccessRedeemTimestamp",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "gap5",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "gap6",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "gap7",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "gap8",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "gap9",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "startTime",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "endTime",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "lastUpdateTime",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "rewardRate",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "rewardRateMin",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "totalSupplySource",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "rewardPerTotalSourceStored",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "gap10",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "gap11",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "gap12",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "gap13",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "gap14",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "investUsdtToBuyStf",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "investStfBurned",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "usdsRedeemUsdtToBuyStf",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "usdsRedeemStfBurned",
            type: "uint256",
          },
        ],
        internalType: "struct ManagerType.GlobalConfig",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "billNumber",
        type: "uint256",
      },
    ],
    name: "getLpBill",
    outputs: [
      {
        components: [
          {
            internalType: "address",
            name: "who",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "lpAmount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "lpEquivalentUsd",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "apy",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "startTimestamp",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "lastUpdateTimestamp",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "finishTimestamp",
            type: "uint256",
          },
          {
            internalType: "bool",
            name: "finished",
            type: "bool",
          },
          {
            internalType: "uint256",
            name: "cumulativeStfRewardClaimed",
            type: "uint256",
          },
          {
            internalType: "bool",
            name: "withdrawn",
            type: "bool",
          },
          {
            internalType: "uint256",
            name: "stfToSelf",
            type: "uint256",
          },
          {
            internalType: "address",
            name: "stfToInviter1Address",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "stfToInviter1Amount",
            type: "uint256",
          },
          {
            internalType: "address",
            name: "stfToInviter2Address",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "stfToInviter2Amount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "usdtWithdrawn",
            type: "uint256",
          },
        ],
        internalType: "struct ManagerType.Bill",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "who",
        type: "address",
      },
    ],
    name: "getLpPersonActiveBill",
    outputs: [
      {
        internalType: "uint256[]",
        name: "",
        type: "uint256[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "who",
        type: "address",
      },
    ],
    name: "getLpPersonFinishedBill",
    outputs: [
      {
        internalType: "uint256[]",
        name: "",
        type: "uint256[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "who",
        type: "address",
      },
    ],
    name: "getLpPersonWithdrawnBill",
    outputs: [
      {
        internalType: "uint256[]",
        name: "",
        type: "uint256[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "who",
        type: "address",
      },
    ],
    name: "getPerson",
    outputs: [
      {
        components: [
          {
            internalType: "address",
            name: "who",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "earlyAccessBought",
            type: "uint256",
          },
          {
            internalType: "address",
            name: "inviter",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "investUsdtCost",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "investZbcCost",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "accumulativeUsdsClaimed",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "gap3",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "gap4",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "stakedUsds",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "stakedSourceAmount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "rewardPerTotalSourcePaid",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "reward",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "accumulativeReward",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "firstStakeTimestamp",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "lastGetRewardTimestamp",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "cumulativeWithdrawnPromotionUsds",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "stakedLp",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "cumulativeStfRewardClaimed",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "cumulativeLpWithdrawnUsdtSelf",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "cumulativeLpWithdrawnStfSelf",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "cumulativeLpWithdrawnStfInviter1",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "cumulativeLpWithdrawnStfInviter2",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "stakedUsdsStfLp",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "UsdsStfLpCumulativeStfRewardClaimed",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "UsdsStfLpCumulativeLpWithdrawnUsdsBurn",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "UsdsStfLpCumulativeLpWithdrawnUsdsToStfSelf",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "UsdsStfLpCumulativeLpWithdrawnLpStfSelf",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "UsdsStfLpCumulativeLpWithdrawnLpStfInviter1",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "UsdsStfLpCumulativeLpWithdrawnLpStfInviter2",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "cumulativeUsdtPurchaseUsdsAmount",
            type: "uint256",
          },
        ],
        internalType: "struct ManagerType.Person",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "who",
        type: "address",
      },
    ],
    name: "getPersonBill",
    outputs: [
      {
        internalType: "uint256[]",
        name: "",
        type: "uint256[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getStfPriceParamTypeHash",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256[]",
        name: "ticketNumbers",
        type: "uint256[]",
      },
    ],
    name: "getTicketNumber",
    outputs: [
      {
        internalType: "address[]",
        name: "",
        type: "address[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "billNumber",
        type: "uint256",
      },
    ],
    name: "getUsdsStfLpBill",
    outputs: [
      {
        components: [
          {
            internalType: "address",
            name: "who",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "lpAmount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "lpEquivalentUsd",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "apy",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "startTimestamp",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "lastUpdateTimestamp",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "finishTimestamp",
            type: "uint256",
          },
          {
            internalType: "bool",
            name: "finished",
            type: "bool",
          },
          {
            internalType: "uint256",
            name: "cumulativeStfRewardClaimed",
            type: "uint256",
          },
          {
            internalType: "bool",
            name: "withdrawn",
            type: "bool",
          },
          {
            internalType: "uint256",
            name: "stfToSelf",
            type: "uint256",
          },
          {
            internalType: "address",
            name: "stfToInviter1Address",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "stfToInviter1Amount",
            type: "uint256",
          },
          {
            internalType: "address",
            name: "stfToInviter2Address",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "stfToInviter2Amount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "usdsBurn",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "usdsToStf",
            type: "uint256",
          },
        ],
        internalType: "struct ManagerType.UsdsStfLpBill",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "who",
        type: "address",
      },
    ],
    name: "getUsdsStfLpPersonActiveBill",
    outputs: [
      {
        internalType: "uint256[]",
        name: "",
        type: "uint256[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "who",
        type: "address",
      },
    ],
    name: "getUsdsStfLpPersonBill",
    outputs: [
      {
        internalType: "uint256[]",
        name: "",
        type: "uint256[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "who",
        type: "address",
      },
    ],
    name: "getUsdsStfLpPersonFinishedBill",
    outputs: [
      {
        internalType: "uint256[]",
        name: "",
        type: "uint256[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "who",
        type: "address",
      },
    ],
    name: "getUsdsStfLpPersonWithdrawnBill",
    outputs: [
      {
        internalType: "uint256[]",
        name: "",
        type: "uint256[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newAssociatedOperator",
        type: "address",
      },
      {
        internalType: "bool",
        name: "flag",
        type: "bool",
      },
    ],
    name: "setAssociatedOperator",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "oneDay",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "investUsdtOnlyMinimal",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "investmentCredentialUsdtToZbcNumerator",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "investmentCredentialUsdtToZbcDenominator",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "investmentDuration",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "earlyAccessUsdtStock",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "earlyAccessUsdtPrice",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "earlyAccessRedeemTimestamp",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "startTime",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "endTime",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "rewardPerDay",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "rewardPerDayMin",
            type: "uint256",
          },
        ],
        internalType: "struct ManagerType.GlobalConfigParam",
        name: "param",
        type: "tuple",
      },
    ],
    name: "setGlobalConfig",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "investUsdtOnlyMinimal",
        type: "uint256",
      },
    ],
    name: "setInvestUsdtOnlyMinimal",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "investmentCredentialUsdtToZbcNumerator",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "investmentCredentialUsdtToZbcDenominator",
        type: "uint256",
      },
    ],
    name: "setInvestmentCredentialPrice",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
] as const;

export class ManagerInterface3__factory {
  static readonly abi = _abi;
  static createInterface(): ManagerInterface3Interface {
    return new utils.Interface(_abi) as ManagerInterface3Interface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): ManagerInterface3 {
    return new Contract(address, _abi, signerOrProvider) as ManagerInterface3;
  }
}
