import { Box, Flex, useToast } from "@chakra-ui/react";
import { TokenInput } from "../UI/CommonInput/TokenInput";
import { useTranslation } from "react-i18next";
import lpIcon from "../../assets/image/stake/lp.png";
import styles from "./index.module.scss";
import { JoinButton } from "../JoinButton";
import { LpOracleResult } from "../../utils/zbcOracleUtil";
import { useTokens } from "../../hooks/useTokens";
import { callWithToastError, truncateNumber } from "../../utils/utils";
import React from "react";
import { BigNumber } from "ethers";
import { parseEther } from "ethers/lib/utils.js";
import { useContracts } from "../../hooks/useContracts";
import classNames from "classnames";

const apys: [string, string, number][] = [
  ["30 Days", "150% APY", 30],
  ["60 Days", "200% APY", 60],
  ["90 Days", "500% APY", 90],
];

export const AddLiquidity = ({
  lpOracleResult,
  onClose,
}: {
  lpOracleResult: LpOracleResult | undefined
  onClose: () => void
}) => {
  //@ts-ignore
  const { t } = useTranslation();
  t("Days", "Days");
  const toast = useToast();
  const tokensQuery = useTokens();
  // console.log(tokensQuery.data);

  const contracts = useContracts();
  const manager = contracts?.manager;

  // input lp
  const [inputLp, setInputLp] = React.useState("");
  let inputLpError = false;
  let lpNeedApprove = false;
  // balances
  const lpBalance = tokensQuery.isSuccess ? truncateNumber(tokensQuery.data.stfusdtlp.balance) : "-";
  // allowances
  const lpAllowance = tokensQuery.isSuccess ? tokensQuery.data.stfusdtlp.allowance : BigNumber.from(0);
  // supply
  const [supplyLoading, setSupplyLoading] = React.useState(false);

  // check input lp amount
  if (!!inputLp && parseEther(inputLp).gt(parseEther(lpBalance))) {
    inputLpError = true;
  }

  // need approve if balance > value > allowance
  if (!!inputLp && parseEther(inputLp).gt(lpAllowance) && parseEther(inputLp).lte(parseEther(lpBalance))) {
    lpNeedApprove = true;
  }

  // handle input change
  const handleInputLp = (e: any) => {
    const value = e.target.value;
    setInputLp(value);
  };

  // approve
  const [lpApproving, setLpApproving] = React.useState(false);
  const handleApproveLp = async () => {
    if (!tokensQuery.isSuccess) return;
    setLpApproving(true);
    await callWithToastError(
      async () => {
        await tokensQuery.data.stfusdtlp.approve();
        onClose();
      },
      toast,
      t("error occured", "An error occured. Please try again later."),
    );
    setLpApproving(false);
  };

  // apy
  const [chooseApy, setChooseApy] = React.useState(0);
  const millisecondsPerDay = 24 * 60 * 60;
  const handleChooseApy = (idx: number) => {
    setChooseApy(idx);
  };

  // supply
  const allowToSupply = !inputLpError && !lpNeedApprove;
  const handleSupply = async () => {
    if (!allowToSupply) return;
    setSupplyLoading(true);
    await callWithToastError(
      async () => {
        if (!lpOracleResult) return;
        const timeRange = millisecondsPerDay * apys[chooseApy][2];
        const tx = await manager!.lpStake(
          lpOracleResult.price,
          lpOracleResult.signature,
          parseEther(inputLp),
          timeRange,
        );
        const receipt = await tx.wait();
        onClose();
      },
      toast,
      t("error occured", "An error occured. Please try again later."),
    );
    setSupplyLoading(false);
  };

  return (
    <Flex className={styles.container}>
      <Box className={styles.modalTitle}>{t("Stake liquidity", "Stake liquidity")}</Box>
      <Box className={styles.modalSubTitle}>
        {t("Enter stake amount", "Enter stake amount")}
      </Box>
      <Flex className={styles.inputGroup}>
        <Flex className={styles.tokenInput}>
          <TokenInput
            isLoading={false}
            isDisabled={!tokensQuery.isSuccess}
            isError={inputLpError}
            tokenIconSrc={lpIcon}
            value={inputLp}
            onChange={handleInputLp}
          />
          <Box className={styles.balance}>
            {t("Balance", "Balance")}: {lpBalance} STF-USDT LP
          </Box>
          {lpNeedApprove && (
            <Box>
              <JoinButton handleClick={handleApproveLp} name={t("Approve LP", "Approve LP")} loading={lpApproving} />
            </Box>
          )}
        </Flex>

        <Flex className={styles.apys}>
          {apys.map((item, index) => {
            return (
              <Box
                className={classNames(styles.apy, index === chooseApy && styles.highlightApy)}
                key={`apy-${index}`}
                onClick={() => handleChooseApy(index)}
              >
                <Box>{item[0]}</Box>
                <Box fontWeight={600}>{item[1]}</Box>
              </Box>
            );
          })}
        </Flex>
        <Flex className={styles.buttons}>
          <JoinButton
            handleClick={handleSupply}
            name={t("Supply", "Supply")}
            disabled={!allowToSupply}
            loading={supplyLoading}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
