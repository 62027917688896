import React from "react";
import { Box, Grid, GridItem } from "@chakra-ui/react";
import "./index.scss";
import { useTranslation } from "react-i18next";

const partnerItems = [
  { name: "zebec" },
  { name: "nautilus" },
  { name: "bnbChain" },
  { name: "poseiswap" },
  { name: "race" },
  { name: "distributed-global" },
  { name: "parafi" },
  { name: "circle" },
  { name: "folius" },
  { name: "robot" },
  { name: "dweb3" },
  { name: "bufficorn" },
  { name: "santiago" },
  { name: "ken" },
  { name: "joseph" },
  { name: "lily" },
  { name: "ran" },
  { name: "others" },
];

export function Partnership() {
  // @ts-ignore
  const { t } = useTranslation();
  return (
    <Box className="partnershipContainer">
      <Box className="partnershipContent">
        <Box className="partnershipTextContainer">
          <Box className="partnershipSlogan">
            {t("Partnership", "Partnership")}
          </Box>
          <Box className="partnershipText">
            {t(
              "partnership desc",
              "We are proud to partner with the following companies to bring you the best experience."
            )}
          </Box>
        </Box>
        <Grid
          className="partnershipItemContainer"
          templateColumns="repeat(4, 1fr)"
          gap={{ base: 2, md: 8 }}
        >
          {partnerItems.map((item) => (
            <GridItem w="100%" h="50px" key={item.name} className="gridItem">
              <img src={`/image/partner/${item.name}.png`} alt={item.name} />
            </GridItem>
          ))}
        </Grid>
        {/* <Box className="partnershipItemContainer">
          {new Array(16).fill(0).map((_, index) => {
            return <BlankItem />;
          })}
        </Box> */}
      </Box>
    </Box>
  );
}
