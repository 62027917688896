import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import "./index.css";
import { Carousel, carouselItems } from "./carousel";
import { useTranslation } from "react-i18next";

export function Whyus() {
  const [currentSlide, setCurrentSlide] = React.useState(0);
  // @ts-ignore
  const { t } = useTranslation();
  return (
    <Box className="researchContainer">
      <Box className="researchContent">
        <Flex className="researchUpperFlex" justifyContent="center">
          <Box className="researchSlogan">{t("Why Us title", "Why Us")}</Box>
        </Flex>

        <Carousel setSlide={setCurrentSlide} />
        <Box position="relative">
          <Box className="researchSlideStartIndexer" />
          <Box
            className={"researchSlideCurrentIndexer"}
            style={{
              left: `${(currentSlide / (carouselItems.length - 1)) * 100}%`,
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}
