import React from "react";
import { Box } from "@chakra-ui/react";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import throttle from "lodash.throttle";
import "./index.css";
import "./carousel.css";
import { CarouselItem } from "./CarouselItem";
import { useTranslation } from "react-i18next";

export const carouselItems = [
  {
    name: "income-backed-title",
    url: "",
    urlName: "View white paper",
    // desc: "Unlock your full capital. Tokenize and trade your cashflow.",
    desc: "income-backed-desc",
    pic: "/image/whyus/wallet.png",
  },
  {
    // name: "Decentralized Signal Portfolio",
    name: "decentralized-title",
    url: "",
    urlName: "View DEV guide",
    // desc: "Plug-in any on or off-chain risk signal",
    desc: "decentralized-desc",
    pic: "/image/whyus/report.png",
  },
  {
    // name: "Lending Pools",
    name: "lending-pool-title",
    url: "",
    urlName: "Learn more",
    // desc: "Launch and configure your pools anyway you want",
    desc: "lending-pool-desc",
    pic: "/image/whyus/pool.png",
  },
  {
    // name: "Evaluation Agents",
    name: "evaluation-agents-title",
    url: "",
    urlName: "View DEV guide",
    // desc: "Automate underwriting with powerful risk models",
    desc: "evaluation-agents-desc",
    pic: "/image/whyus/calc.png",
  },
];

export function Carousel({ setSlide }: { setSlide: (slice: number) => void }) {
  // @ts-ignore
  const { t } = useTranslation();
  const throttledSetSlide = throttle(setSlide, 100, {
    leading: false,
    trailing: true,
  });
  const [sliderRef] = useKeenSlider({
    loop: false,
    mode: "free",
    slides: {
      perView: 3.1,
      spacing: 1,
    },
    detailsChanged: (event) => {
      throttledSetSlide(event.track.details.abs);
    },
  });

  const mediaQuery = window.matchMedia("(max-width: 768px)");
  return (
    <Box className="carouselContainer">
      <Box className="carouselContent">
        <Box ref={sliderRef} className="keen-slider">
          {carouselItems.map((item, idx) => (
            <CarouselItem
              name={t(item.name)}
              url={item.url}
              pic={item.pic}
              desc={t(item.desc) || "desc"}
              urlName={item.urlName || "urlName"}
              key={item.name}
            />
          ))}
          {mediaQuery.matches ? (
            <Box>
              <Box className="keen-slider__slide" />
              <Box className="keen-slider__slide" />
            </Box>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
}
