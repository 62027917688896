import { useContracts } from "./useContracts";
import { useWallet } from "../store/wallet-context";
import { useQuery } from "@tanstack/react-query";

export const useIsRegistered = () => {
  const contracts = useContracts();
  const { invitationCenter } = contracts;
  const { accountAddress, isConnected } = useWallet();
  const isRegisteredQuery = useQuery({
    queryKey: ["isRegisteredQuery"],
    queryFn: async () => {
      return await invitationCenter!.isRegistered(accountAddress!);
    },
    enabled: !!accountAddress && isConnected && !(invitationCenter == null),
    refetchInterval: 10 * 1000, // refetch every 10 seconds
  });

  return isRegisteredQuery;
};
