/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type {
  ManagerInterface2,
  ManagerInterface2Interface,
} from "../../../contracts/manager/ManagerInterface2";

const _abi = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "who",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "usdtCost",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256[]",
        name: "earlyAccessIds",
        type: "uint256[]",
      },
    ],
    name: "BuyEarlyAccess",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "who",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "usdsAmount",
        type: "uint256",
      },
    ],
    name: "GetInvestmentReward",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "who",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "reward",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "bonus",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "stfToSelf",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "stfToDead",
        type: "uint256",
      },
    ],
    name: "GetReward",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "who",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "usdtAll",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "zbcUsdtPrice",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "usdtCost",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "zbcCost",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "investmentCredentialId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "usdsAmount",
        type: "uint256",
      },
    ],
    name: "Invest",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "who",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "usdsAmount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "usdtAmount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "usdtToColdWallet",
        type: "uint256",
      },
    ],
    name: "RedeemUsds",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "who",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "inviter",
        type: "address",
      },
    ],
    name: "Register",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "who",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "usds",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "power",
        type: "uint256",
      },
    ],
    name: "Stake",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "who",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "usdtAmount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "usdsAmount",
        type: "uint256",
      },
    ],
    name: "UsdtPurchaseUsds",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "who",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "usds",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "usdsToBurn",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "power",
        type: "uint256",
      },
    ],
    name: "Withdraw",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "who",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "usdsAmount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "ticketNumber",
        type: "uint256",
      },
    ],
    name: "WithdrawPromotionUsds",
    type: "event",
  },
  {
    inputs: [],
    name: "accessControl",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "calcTotalReward",
    outputs: [
      {
        internalType: "uint256",
        name: "totalReward",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "newRewardRate",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "who",
        type: "address",
      },
    ],
    name: "earned",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "startTime_",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "endTime_",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "rewardRate_",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "rewardRateMin_",
        type: "uint256",
      },
      {
        internalType: "bool",
        name: "needClearRewardPerTotalSourceStored",
        type: "bool",
      },
    ],
    name: "forceSet",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "getReward",
    outputs: [
      {
        internalType: "uint256",
        name: "reward",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "bonus",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "lastTimeRewardApplicable",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "rewardPerToken",
    outputs: [
      {
        internalType: "uint256",
        name: "retE18",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "newRewardRate",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newAssociatedOperator",
        type: "address",
      },
      {
        internalType: "bool",
        name: "flag",
        type: "bool",
      },
    ],
    name: "setAssociatedOperator",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "usdsAmount",
        type: "uint256",
      },
    ],
    name: "stake",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "usdsAmount",
        type: "uint256",
      },
    ],
    name: "usdsToPower",
    outputs: [
      {
        internalType: "uint256",
        name: "power",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "when",
        type: "uint256",
      },
    ],
    name: "whichDay",
    outputs: [
      {
        internalType: "uint256",
        name: "day",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "dayStart",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "dayFinish",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "usdsAmount",
        type: "uint256",
      },
    ],
    name: "withdraw",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
] as const;

export class ManagerInterface2__factory {
  static readonly abi = _abi;
  static createInterface(): ManagerInterface2Interface {
    return new utils.Interface(_abi) as ManagerInterface2Interface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): ManagerInterface2 {
    return new Contract(address, _abi, signerOrProvider) as ManagerInterface2;
  }
}
