import React, { useEffect, useState } from "react";
import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import styles from "./LangButton.module.scss";

// @ts-ignore
import en from "../../assets/image/language/en.png";
// @ts-ignore
import zh from "../../assets/image/language/zh.png";
// @ts-ignore
import ja from "../../assets/image/language/ja.png";
// @ts-ignore
import ko from "../../assets/image/language/ko.png";
// @ts-ignore
import es from "../../assets/image/language/es.png";
// @ts-ignore
import ru from "../../assets/image/language/ru.png";
// @ts-ignore
import hi from "../../assets/image/language/hi.png";
// @ts-ignore
import ar from "../../assets/image/language/ar.png";
// @ts-ignore
import ms from "../../assets/image/language/ms.png";
// @ts-ignore
import vi from "../../assets/image/language/vi.png";
// @ts-ignore
import id from "../../assets/image/language/id.png";

const langs = [
  { key: "en", label: "English", icon: en },
  { key: "zh", label: "简体中文", icon: zh },
  { key: "ja", label: "日本語", icon: ja },
  { key: "ko", label: "한국의", icon: ko },
  { key: "es", label: "Español", icon: es },
  { key: "ru", label: "Русский", icon: ru },
  { key: "hi", label: "हिन्दी", icon: hi },
  { key: "ar", label: "عربي", icon: ar },
  { key: "ms", label: "Orang Melayu", icon: ms },
  { key: "vi", label: "tiếng Việt", icon: vi },
  { key: "id", label: "Bahasa Indonesia", icon: id },
];

const LangButton = () => {
  // @ts-ignore
  const { i18n } = useTranslation();

  const [currentLang, setCurrentLang] = useState<any>(langs[0]);

  useEffect(() => {
    const lang = i18n.resolvedLanguage || "en";

    setCurrentLang(langs.find((item) => item.key === lang));
  }, [i18n.resolvedLanguage]);

  return (
    <Menu placement="bottom-end">
      <MenuButton>
        <img
          className={styles.buttonImage}
          src={currentLang.icon}
          alt={currentLang.label}
        />
      </MenuButton>
      <MenuList className={styles.menuList}>
        {langs.map((item) => (
          <MenuItem
            className={styles.menuItem}
            key={item.key}
            onClick={() => i18n.changeLanguage(item.key)}
          >
            {item.label}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export default LangButton;
