import React from "react";
import { Outlet } from "react-router-dom";
import { Header } from "./components/Header";
import { Subscription } from "./components/Subscription";
import { useNameservice } from "./store/nameservice-context";

function App() {
  const ns = useNameservice();
  console.log(ns);

  // const nsAddress = process.env.REACT_APP_NS!;
  // // const usds = ERC20__factory.connect(usdsAddress, provider.getSigner());
  // const provider = new ethers.providers.Web3Provider(window.ethereum as any);
  // const nsContract = NameServiceInterface__factory.connect(nsAddress, provider.getSigner());
  // nsContract.listSingleEntries().then(res => {
  //   console.log(res);
  //   res.forEach(([name, value]) => {
  //     console.log([parseBytes32String(name), value]);
  //   });
  // });

  return (
    <div>
      <Header />
      <Outlet />
      <Subscription />
    </div>
  );
}

export default App;
