import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import "./index.css";
import { JoinButton } from "../JoinButton";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export function Banner() {
  // @ts-ignore
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Box className="bannerContainer">
      <Box className="bannerContent">
        <Box className="bannerBg1" />
        <Box className="bannerSlogan">
          {t("Income backed", "Income backed")}
        </Box>
        <Box className="bannerSlogan">
          {t("DeFi for the 99%", "DeFi for the 99%")}
        </Box>
        <Box className="bannerText">
          {t(
            "Lend and borrow against income",
            "Lend and borrow against income, the new collateral."
          )}
        </Box>
        {/* <Box className="bannerSocialContainer">
          <Box
            className={classNames("bannerSocialTwitter", "bannerSocialItem")}
          />
          <Box
            className={classNames("bannerSocialWhatsapp", "bannerSocialItem")}
          />
          <Box
            className={classNames("bannerSocialFacebook", "bannerSocialItem")}
          />
          <Box
            className={classNames("bannerSocialSettle", "bannerSocialItem")}
          />
        </Box> */}
        <Flex align="center" gap="40px" mt="50px" className="earlyBirdWrap">
          <Box>
            <JoinButton
              name={t("Launch App", "Launch App")}
              handleClick={() => {
                navigate("/invest");
              }}
            />
          </Box>
          {/*<Box className="goldenLabel">*/}
          {/*  Learn more <Box className="bannerDownArrow" />*/}
          {/*</Box>*/}
        </Flex>
      </Box>
    </Box>
  );
}
