import { Box, Flex, useToast } from "@chakra-ui/react";
import { TokenInput } from "../UI/CommonInput/TokenInput";
import { useTranslation } from "react-i18next";
import usdsIcon from "../../assets/image/invest/usds.png";
import stfIcon from "../../assets/image/STF.png";
import styles from "./index.module.scss";
import { JoinButton } from "../JoinButton";
import { UsdsLpOracleResult } from "../../utils/zbcOracleUtil";
import { useTokens } from "../../hooks/useTokens";
import { callWithToastError, truncateNumber } from "../../utils/utils";
import React from "react";
import { BigNumber } from "ethers";
import { parseEther } from "ethers/lib/utils.js";
import { useContracts } from "../../hooks/useContracts";
import classNames from "classnames";
import { useQuery } from "@tanstack/react-query";
import { calcOpponentTokenAmount } from "./utils";

const apys: [string, string, number][] = [
  ["30 Days", "150% APY", 30],
  ["60 Days", "200% APY", 60],
  ["90 Days", "500% APY", 90],
];

export const AddUsdsLiquidity = ({
  lpOracleResult,
  onClose,
}: {
  lpOracleResult: UsdsLpOracleResult | undefined;
  onClose: () => void;
}) => {
  //@ts-ignore
  const { t } = useTranslation();
  t("Days", "Days");
  const toast = useToast();
  const tokensQuery = useTokens();
  // console.log(tokensQuery.data);

  const contracts = useContracts();
  const manager = contracts?.manager;
  const stfusdslp = contracts?.stfusdslp;

  const pairQuery = useQuery({
    queryKey: ["pair", "stfusdslp"],
    queryFn: async () => {
      if (!stfusdslp) return;
      // console.log("token 0 is", await stfusdslp.token0());
      // token 0 is stf
      const [stfReserve, usdsReserve] = await stfusdslp.getReserves();
      return {
        stfReserve,
        usdsReserve,
      };
    },
    enabled: !!stfusdslp,
  });

  // usds token
  const [inputUsds, setInputUsds] = React.useState("");
  let inputUsdsError = false;
  let usdsNeedApprove = false;
  const usdsBalance = tokensQuery.isSuccess ? truncateNumber(tokensQuery.data.usds.balance) : "-";
  const usdsAllowance = tokensQuery.isSuccess ? tokensQuery.data.usds.allowance : BigNumber.from(0);
  if (!!inputUsds && parseEther(inputUsds).gt(parseEther(usdsBalance))) {
    inputUsdsError = true;
  }
  if (!!inputUsds && parseEther(inputUsds).gt(usdsAllowance) && parseEther(inputUsds).lte(parseEther(usdsBalance))) {
    usdsNeedApprove = true;
  }
  const handleInputUsds = (e: any) => {
    const value = e.target.value;
    if (!pairQuery.data || value.length > 18) return;
    setInputUsds(value);
    setInputStf(calcOpponentTokenAmount(value, pairQuery.data));
  };
  const [usdsApproving, setUsdsApproving] = React.useState(false);
  const handleApproveUsds = async () => {
    if (!tokensQuery.isSuccess) return;
    setUsdsApproving(true);
    await callWithToastError(
      async () => {
        await tokensQuery.data.usds.approve();
      },
      toast,
      t("error occured", "An error occured. Please try again later."),
    );
    setUsdsApproving(false);
  };
  // sft token
  const [inputStf, setInputStf] = React.useState("");
  let inputStfError = false;
  let stfNeedApprove = false;

  const stfBalance = tokensQuery.isSuccess ? truncateNumber(tokensQuery.data.stf.balance) : "-";
  const stfAllowance = tokensQuery.isSuccess ? tokensQuery.data.stf.allowance : BigNumber.from(0);
  if (!!inputStf && parseEther(inputStf).gt(parseEther(stfBalance))) {
    inputStfError = true;
  }
  if (!!inputStf && parseEther(inputStf).gt(stfAllowance) && parseEther(inputStf).lte(parseEther(stfBalance))) {
    stfNeedApprove = true;
  }
  const handleInputStf = (e: any) => {
    const value = e.target.value;
    if (!pairQuery.data || value.length > 18) return;
    setInputStf(value);
    setInputUsds(calcOpponentTokenAmount(value, pairQuery.data, true));
  };
  const [stfApproving, setStfApproving] = React.useState(false);
  const handleApproveStf = async () => {
    if (!tokensQuery.isSuccess) return;
    setStfApproving(true);
    await callWithToastError(
      async () => {
        await tokensQuery.data.stf.approve();
      },
      toast,
      t("error occured", "An error occured. Please try again later."),
    );
    setStfApproving(false);
  };

  // supply
  const [supplyLoading, setSupplyLoading] = React.useState(false);

  // apy
  const [chooseApy, setChooseApy] = React.useState(0);
  const millisecondsPerDay = 24 * 60 * 60;
  const handleChooseApy = (idx: number) => {
    setChooseApy(idx);
  };

  // supply
  const allowToSupply =
    !inputUsdsError && !usdsNeedApprove && !inputStfError && !stfNeedApprove && !!inputUsds && !!inputStf;

  const handleSupply = async () => {
    if (!allowToSupply) return;
    setSupplyLoading(true);
    await callWithToastError(
      async () => {
        if (!lpOracleResult) return;
        const timeRange = millisecondsPerDay * apys[chooseApy][2];
        const tx = await manager!.addUsdsStfLpAndStake(
          parseEther(inputUsds),
          parseEther(inputStf),
          parseEther(inputUsds).mul(9).div(10),
          parseEther(inputStf).mul(9).div(10),
          lpOracleResult.price,
          lpOracleResult.signature,
          timeRange,
        );
        const receipt = await tx.wait();
        onClose();
      },
      toast,
      t("error occured", "An error occured. Please try again later."),
    );
    setSupplyLoading(false);
  };

  return (
    <Flex className={styles.container}>
      <Box className={styles.modalTitle}>{t("Stake liquidity", "Stake liquidity")}</Box>
      <Box className={styles.modalSubTitle}>{t("Enter stake amount", "Enter stake amount")}</Box>
      <Flex className={styles.inputGroup}>
        <Flex className={styles.tokenInput}>
          <Flex className={styles.tokenInput}>
            <TokenInput
              isLoading={false}
              isDisabled={!tokensQuery.isSuccess || !pairQuery.isSuccess || !lpOracleResult}
              isError={inputUsdsError}
              tokenIconSrc={usdsIcon}
              value={inputUsds}
              onChange={handleInputUsds}
            />
            <Box className={styles.balance}>
              {t("Balance", "Balance")}: {usdsBalance} USDS
            </Box>
            {usdsNeedApprove && (
              <Box mb={4}>
                <JoinButton
                  handleClick={handleApproveUsds}
                  name={t("Approve USDS", "Approve USDS")}
                  loading={usdsApproving}
                />
              </Box>
            )}
          </Flex>
          <Flex className={styles.tokenInput}>
            <TokenInput
              isLoading={false}
              isDisabled={!tokensQuery.isSuccess || !pairQuery.isSuccess || !lpOracleResult}
              isError={inputStfError}
              tokenIconSrc={stfIcon}
              value={inputStf}
              onChange={handleInputStf}
            />
            <Box className={styles.balance}>
              {t("Balance", "Balance")}: {stfBalance} STF
            </Box>
            {stfNeedApprove && (
              <Box>
                <JoinButton
                  handleClick={handleApproveStf}
                  name={t("Approve STF", "Approve STF")}
                  loading={stfApproving}
                />
              </Box>
            )}
          </Flex>
        </Flex>

        <Flex className={styles.apys}>
          {apys.map((item, index) => {
            return (
              <Box
                className={classNames(styles.apy, index === chooseApy && styles.highlightApy)}
                key={`apy-${index}`}
                onClick={() => handleChooseApy(index)}
              >
                <Box>{item[0]}</Box>
                <Box fontWeight={600}>{item[1]}</Box>
              </Box>
            );
          })}
        </Flex>
        <Flex className={styles.buttons}>
          <JoinButton
            handleClick={handleSupply}
            name={t("Supply", "Supply")}
            disabled={!allowToSupply}
            loading={supplyLoading}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
