import { formatEther } from "ethers/lib/utils.js";
import { LpInfo } from "../../hooks/useLpInfos";
import { format, fromUnixTime } from "date-fns";
import { BigNumberish } from "ethers";

export type DisplayLpInfoItem = {
  id: BigNumberish
  apr: number
  staked: string
  claimable: string
  claimed: string
  endTimestamp: string
}

export type DisplayLpInfo = {
  lpBalance: string
  // banner area
  stakedUsdt: string
  stakedStf: string
  stakedLp: string
  earnedStf: string
  cumulativeLpWithdrawnStfInviter: string
  // table banner
  totalClaimabledStf: string
  totalClaimedStf: string

  lpInfoItems: DisplayLpInfoItem[]
}

const defaultDisplayLpInfo: DisplayLpInfo = {
  lpBalance: "-",
  // banner area
  stakedUsdt: "-",
  stakedStf: "-",
  stakedLp: "-",
  earnedStf: "-",
  cumulativeLpWithdrawnStfInviter: "-",
  // table banner
  totalClaimabledStf: "-",
  totalClaimedStf: "-",

  lpInfoItems: [],
};

export const formatLpInfos = (lpInfo: LpInfo | undefined): DisplayLpInfo => {
  if(!lpInfo) return defaultDisplayLpInfo;
  return {
    lpBalance: formatEther(lpInfo.lpBalance),
    // banner area
    stakedUsdt: formatEther(lpInfo.stakedUsdt),
    stakedStf: formatEther(lpInfo.stakedStf),
    stakedLp: formatEther(lpInfo.stakedLp),
    earnedStf: formatEther(lpInfo.earnedStf),
    cumulativeLpWithdrawnStfInviter: formatEther(lpInfo.cumulativeLpWithdrawnStfInviter),
    // table banner
    totalClaimabledStf: formatEther(lpInfo.totalClaimabledStf),
    totalClaimedStf: formatEther(lpInfo.totalClaimedStf),

    lpInfoItems: lpInfo.lpInfoItems.map(item => {
      return {
        id: item.id.toNumber(),
        apr: item.apr.toNumber(),
        staked: formatEther(item.staked),
        claimable: formatEther(item.claimable),
        claimed: formatEther(item.claimed),
        endTimestamp: format(fromUnixTime(item.endTimestamp.toNumber()), "MM/dd/yyyy"),
      };
    }),
  };
};
