import React, { useEffect, useState } from "react";
import classNames from "classnames";
import {
  useContractRead,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from "wagmi";
import { ethers } from "ethers";

import MainContainer from "../../components/UI/MainContainer/MainContainer";
import { JoinButton } from "../../components/JoinButton";
import managerAbi from "../../abi/ManagerInterface";
import erc20Abi from "../../abi/ERC20Interface";
import erc721Abi from "../../abi/ERC721Interface";
import img1 from "../../assets/image/early-bird/1.png";
import img2 from "../../assets/image/early-bird/2.png";
import img3 from "../../assets/image/early-bird/3.png";
import invested from "../../assets/image/invested.png";

import styles from "./EarlyBird.module.scss";
import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import { useWallet } from "../../store/wallet-context";
import { truncateNumber } from "../../utils/utils";
import { useBalance } from "wagmi";
import {
  NameserviceKeys,
  useNameservice,
} from "../../store/nameservice-context";
import { useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const EarlyAccessLimit = 10;

const EarlyBird = () => {
  const { nativeBalance, accountAddress: address } = useWallet();
  const ns = useNameservice();
  //@ts-ignore
  const { t } = useTranslation();
  const { data: usdtBalance } = useBalance({
    address: address,
    token: ns?.[NameserviceKeys.USDT] as `0x${string}`,
    watch: true,
  });
  const toast = useToast();
  const [count, setCount] = useState(1);
  const [totalCost, setTotalCost] = useState(ethers.BigNumber.from(0));
  const [price, setPrice] = useState(ethers.BigNumber.from(0));
  const [allowance, setAllowance] = useState(ethers.BigNumber.from(0));
  const [quotaLeft, setQuotaLeft] = useState(0);
  const [purchasedAmount, setPurchasedAmount] = useState(
    ethers.BigNumber.from(0)
  );

  const hasEnoughAllowance = allowance.gte(totalCost);

  const managerContract = {
    address: ns?.[NameserviceKeys.Manager] as `0x${string}`,
    abi: managerAbi,
  };

  // get global config
  const { data: globalConfig } = useContractRead({
    ...managerContract,
    functionName: "getGlobalConfig",
  });
  // get person record
  const { data: personRecord } = useContractRead({
    ...managerContract,
    functionName: "getPerson",
    args: [address || ethers.constants.AddressZero],
  });
  // get usdt allowance
  const { data: usdtAllowance } = useContractRead({
    address: ns?.[NameserviceKeys.USDT] as `0x${string}`,
    abi: erc20Abi,
    functionName: "allowance",
    args: [
      address || ethers.constants.AddressZero,
      ns?.[NameserviceKeys.Manager] as `0x${string}`,
    ],
    watch: true,
  });

  // get earlyBird purchased
  const { data: earlyAccessBalance } = useContractRead({
    address: ns?.[NameserviceKeys.EarlyAccess] as `0x${string}`,
    abi: erc721Abi,
    functionName: "balanceOf",
    args: [address || ethers.constants.AddressZero],
    watch: true,
  });

  const { config: approveConfig } = usePrepareContractWrite({
    address: ns?.[NameserviceKeys.USDT] as `0x${string}`,
    abi: erc20Abi,
    functionName: "approve",
    args: [
      ns?.[NameserviceKeys.Manager] as `0x${string}`,
      ethers.constants.MaxUint256,
    ],
    enabled: true,
  });

  const { data: approveData, write: approveWrite } =
    useContractWrite(approveConfig);

  const { isLoading: isAapproveLoading, isSuccess: approveSuccess } =
    useWaitForTransaction({
      hash: approveData?.hash,
    });

  const { data: purchaseData, write: purchaseWrite } = useContractWrite({
    mode: "recklesslyUnprepared",
    ...managerContract,
    functionName: "buyEarlyAccess",
    args: [ethers.BigNumber.from(count)],
  });

  const { isLoading: isPurchaseLoading, isSuccess: isPurchaseSuccess } =
    useWaitForTransaction({
      hash: purchaseData?.hash,
    });

  // calc quota left
  useEffect(() => {
    const newQuotaLeft =
      EarlyAccessLimit - (earlyAccessBalance?.toNumber() || 0);
    setQuotaLeft(newQuotaLeft);
    setPurchasedAmount(
      earlyAccessBalance?.mul(price) || ethers.BigNumber.from(0)
    );
  }, [earlyAccessBalance, price]);

  // calc total cost
  useEffect(() => {
    const newPrice =
      globalConfig?.earlyAccessUsdtPrice || ethers.BigNumber.from(0);
    setPrice(newPrice);
    const newTotalCost = newPrice.mul(count);
    setTotalCost(newTotalCost);
    return () => {};
  }, [globalConfig, count]);

  useEffect(() => {
    setAllowance(usdtAllowance || ethers.BigNumber.from(0));
  }, [usdtAllowance]);

  useEffect(() => {
    const newPrice =
      globalConfig?.earlyAccessUsdtPrice || ethers.BigNumber.from(0);
    setPrice(newPrice);
    const newTotalCost = newPrice.mul(count);
    setTotalCost(newTotalCost);
  }, [globalConfig, count]);

  const handleCountChange = (diff: number) => {
    const newCount = count + diff;
    const finalCount = Math.min(Math.max(1, newCount), quotaLeft);
    setCount(finalCount);
    const newTotalCost = price.mul(finalCount);
    setTotalCost(newTotalCost);
  };

  const handleApprove = () => {
    if (isAapproveLoading) {
      return;
    }
    if (Number(nativeBalance?.formatted) < 0.005) {
      toast({
        description: t("Need BNB for gas", "Need BNB for gas."),
        status: t("error", "error"),
        duration: 1500,
        isClosable: false,
      });
    }

    approveWrite?.();
  };

  const handleInvest = () => {
    if (isPurchaseLoading) {
      return;
    }
    if (Number(nativeBalance?.formatted) < 0.005) {
      toast({
        description: t("Need BNB for gas", "Need BNB for gas."),
        status: t("error", "error"),
        duration: 1500,
        isClosable: false,
      });
    }

    if (totalCost.gt(ethers.BigNumber.from(usdtBalance?.value))) {
      toast({
        description: t("Insufficient USDT", "Insufficient USDT."),
        status: t("error", "error"),
        duration: 1500,
        isClosable: false,
      });
    }
    purchaseWrite?.();
  };

  return (
    <div className={styles.EarlyBird}>
      <MainContainer className={styles.container}>
        <div className={classNames(styles.card, styles.firstCard)}>
          <img className={styles.img1} src={img1} alt="img1" />
          <img className={styles.img2} src={img2} alt="img2" />
          <h1>{t("Early Bird Event", "Early Bird Event")}</h1>
        </div>
        <div className={classNames(styles.card)}>
          <h3>{t("Description", "Description:")}</h3>
          <p>
            {t(
              "SettleFinanceEarlyBirdIntro",
              "Settle Finance is launching the Early Bird Asset Package, an innovative chance to experience DeFi products that link the real world to the blockchain. Be an early bird to share in our success, enjoy higher yields, and shape the future of Settle Finance. This limited offer invites you to explore the limitless potential of DeFi in the Web3 world."
            )}
          </p>
        </div>
        <div className={styles.purchaseWrap}>
          <div className={styles.actionWrap}>
            <h3 className={styles.unitPrice}>
              {t("PriceTotal", "Price: {{price}} USDT/EA", { price: 0 })}
            </h3>
            <div className={styles.inputWrap}>
              <div className={styles.inputGroup}>
                <div className={styles.inputButton}>
                  <MinusIcon
                    boxSize={5}
                    onClick={() => handleCountChange(-1)}
                  />
                </div>
                <h2>{count}</h2>
                <div
                  className={styles.inputButton}
                  onClick={() => handleCountChange(1)}
                >
                  <AddIcon boxSize={5} />
                </div>
              </div>
              <p className={styles.desc}>
                {t("QuotaLeft", "Your Quota Left: {{quotaLeft}}", {
                  quotaLeft: quotaLeft,
                })}
              </p>
            </div>
            <div className={styles.buttonGroup}>
              <p className={styles.desc}>
                {t("TotalCost", " Total Cost: {{cost}}", {
                  cost: truncateNumber(ethers.utils.formatEther(totalCost), 4),
                })}
              </p>
              {hasEnoughAllowance ? (
                <JoinButton
                  handleClick={handleInvest}
                  name={t("Purchase", "Purchase")}
                />
              ) : (
                <JoinButton
                  handleClick={handleApprove}
                  name={`${t("Approve", "Approve")} USDT`}
                />
              )}
              <p className={styles.desc}>
                {t("WalletBalance", " Wallet Balance: {{balance}}", {
                  balance: truncateNumber(usdtBalance?.formatted || "0", 4),
                })}
              </p>
            </div>

            <div className={styles.invested}>
              <div className={styles.leftTitle}>
                <img src={invested} alt={"investIcon"} />
                {t("Purchased", "Purchased:")}
              </div>
              <div>
                {truncateNumber(
                  ethers.utils.formatEther(purchasedAmount) || "0",
                  4
                )}{" "}
                USDT
              </div>
            </div>
          </div>
          <div className={classNames(styles.card, styles.benefitCard)}>
            <img src={img3} alt="img3" />
            <h3>{t("Exclusive Benefits", "Exclusive Benefits")}</h3>
            <p>
              {t(
                "Limited availability",
                "1. Limited availability - grab it fast!"
              )}
            </p>
            <p>
              {t(
                "Begin earning",
                "2. Begin earning instantly after product launch."
              )}
            </p>
            <p>
              {t(
                "Early birds get an extra",
                "3. Early birds get an extra worth of 10% ZBC airdrop on launch."
              )}
            </p>
            <p>
              {t(
                "Access tiered services",
                "4. Access tiered services and secure exclusive yield rights."
              )}
            </p>
          </div>
        </div>
      </MainContainer>
    </div>
  );
};

export default EarlyBird;
