import { useContracts } from "./useContracts";
import { useWallet } from "../store/wallet-context";
import { useQuery } from "@tanstack/react-query";
import { ethers } from "ethers";

export const usePerson = () => {
  const contracts = useContracts();
  const { manager } = contracts;
  const { accountAddress } = useWallet();
  const personQuery = useQuery({
    queryKey: ["personQuery"],
    queryFn: async () => {
      const result = await manager!.getPerson(accountAddress!);
      const person = {} as any;
      Object.entries(result).forEach(([key, value]) => {
        if (value instanceof ethers.BigNumber) {
          person[key] = value.toString();
        } else {
          person[key] = value;
        }
      });
      console.log("personQuery", person);

      return result;
    },
    enabled: !(manager == null) && !!accountAddress,
  });

  return personQuery;
};
