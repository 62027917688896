/* eslint-disable unused-imports/no-unused-imports */
import React, { useEffect, useState } from "react";
import { JoinButton } from "../../components/JoinButton";
import img1 from "../../assets/image/invest/sunflower.png";
import img2 from "../../assets/image/invest/rules.png";
import usdtIcon from "../../assets/image/invest/usdt.png";
import zbecIcon from "../../assets/image/invest/zbec.png";
import nftImg from "../../assets/image/invest/nft.png";

import styles from "./Invest.module.scss";
import {
  Box,
  Flex,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spinner,
  useToast
} from "@chakra-ui/react";
import { Modal } from "../../components/Modal";
import { useNavigate } from "react-router-dom";
import { useContracts } from "../../hooks/useContracts";
import { useWallet } from "../../store/wallet-context";
import { BigNumber, ethers } from "ethers";
import { CommonInput } from "../../components/UI/CommonInput";
import classNames from "classnames";
import { callWithToastError, truncateNumber } from "../../utils/utils";
import { OracleResult, requestZbcPrice } from "../../utils/zbcOracleUtil";
import { add, format } from "date-fns";
import { useIsRegistered } from "../../hooks/useIsRegistered";
import { useBalance, useBlockNumber } from "wagmi";
import { useTokens } from "../../hooks/useTokens";
import { useTranslation } from "react-i18next";
import { useGlobalConfig } from "../../hooks/useGlobalConfig";
import {
  formatEther,
  formatUnits,
  parseEther,
  parseUnits
} from "ethers/lib/utils.js";
import { Promotion } from "../../components/Promotion";
import { useTimer } from "../../hooks/useTimer";

type InputMode = "usdt" | "zbec";
type NftDetail = {
  startTime: string;
  endTime: string;
  investAmount: string;
};

export const Invest = () => {
  const isDev = process.env.NODE_ENV === "development";

  //@ts-ignore
  const { t } = useTranslation();
  const navigate = useNavigate();
  const promotionStartCountdown = useTimer({
    deadline: new Date("2023-08-25T12:00:00+08:00")
  });
  const promotionCountdown = useTimer({
    deadline: new Date("2023-09-09T23:59:59+08:00")
  });
  const isInPromotion = promotionCountdown.diffInSeconds > 0 && promotionStartCountdown.diffInSeconds <= 0;

  const toast = useToast();
  const [txLoading, setTxLoading] = useState(false); // approving usdt, zbc or investing
  const [nftDetail, setNftDetail] = useState<NftDetail | null>(null);
  const [inputMode, setInputMode] = useState<InputMode>("usdt"); // ['usdt', 'zbec']
  const [zbcOracleResult, setZbcOracleResult] = useState<OracleResult | null>(
    null
  );
  const [usdtAmount, setUsdtAmount] = useState("");
  const [usdtLoading, setUsdtLoading] = useState(false);
  // const [usdtError, setUsdtError] = useState(false);
  let usdtError = false;
  const [zAmount, setZAmount] = useState("");
  const [zLoading, setZLoading] = useState(false);
  const [costAll, setCostAll] = useState(""); // usdt + zbec
  // const [zError, setZError] = useState(false);
  let zError = false;
  const [showModal, setShowModal] = useState(false);
  const contracts = useContracts();
  const { accountAddress, isConnected } = useWallet();
  const bnbBalanceQuery = useBalance({ address: accountAddress });
  const manager = contracts.manager!;
  const investmentCredential = contracts.investmentCredential!;
  const blockNumberQuery = useBlockNumber({ watch: true });
  const blockNumberRef = React.useRef(blockNumberQuery);
  const configQuery = useGlobalConfig();

  const isRegisteredQuery = useIsRegistered();
  if (isRegisteredQuery.data === false) {
    navigate("/register");
  }
  useEffect(() => {
    requestZbcPrice().then((oracleResult) => {
      setZbcOracleResult(oracleResult);
    });
  }, [accountAddress]);

  const minUsdt = configQuery.isSuccess
    ? configQuery.data.investUsdtOnlyMinimal
    : ethers.utils.parseEther("10");
  // zbc decimals is 9
  const minZbc =
    !!zbcOracleResult && configQuery.isSuccess
      ? minUsdt
        .div(9)
        .mul(BigNumber.from(10).pow(9))
        .div(zbcOracleResult.price.zbcPriceFixedPoint18)
      : ethers.utils.parseUnits("90", 9);

  // Queries
  const tokensQuery = useTokens();

  const {
    usdt: usdtQuery,
    usds: usdsQuery,
    zBec: zBecQuery
  } = tokensQuery.data || {};

  // need approve condition: allowance < input < balance
  const inputUsdtAmount = ethers.utils.parseEther(usdtAmount || "0");
  const usdtInputMoreThanApproved =
    tokensQuery.isSuccess &&
    ethers.utils.parseEther(usdtQuery!.balance).gt(inputUsdtAmount) &&
    inputUsdtAmount.gt(usdtQuery!.allowance);

  const inputZAmount = ethers.utils.parseUnits(zAmount || "0", 9);
  const zbcInputMoreThanApproved =
    tokensQuery.isSuccess &&
    ethers.utils.parseEther(zBecQuery!.balance).gt(inputZAmount) &&
    inputZAmount.gt(zBecQuery!.allowance);

  const usdtNotApproved =
    usdtQuery?.approved === false || usdtInputMoreThanApproved;
  const zBecNotApproved =
    zBecQuery?.approved === false || zbcInputMoreThanApproved;

  const usdtBalance = usdtQuery?.balance || "-";
  const zBecBalance = zBecQuery?.balance || "-";

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleInvest = async () => {
    if (!isConnected || !zbcOracleResult || !bnbBalanceQuery.isSuccess) {
      return;
    }
    if (bnbBalanceQuery.data!.value.lt(ethers.utils.parseUnits("0.001", 18))) {
      toast({
        title: t(
          "Insufficient BNB balance for gas",
          "Insufficient BNB balance for gas."
        ),
        status: "error",
        duration: 1500,
        isClosable: true
      });
      return;
    }
    if (usdtNotApproved) {
      setTxLoading(true);
      await callWithToastError(
        async () => {
          await usdtQuery!.approve();
        },
        toast,
        t("error occured", "An error occured. Please try again later.")
      );
      setTxLoading(false);
      return;
    }
    if (zBecNotApproved) {
      setTxLoading(true);
      await callWithToastError(
        async () => {
          await zBecQuery!.approve();
        },
        toast,
        t("error occured", "An error occured. Please try again later.")
      );
      setTxLoading(false);
      return;
    }
    if (usdtError || zError) {
      toast({
        title: t(
          "Please input correct token amount",
          "Please input correct token amount"
        ),
        status: "error",
        duration: 1500,
        isClosable: true
      });
      return;
    }
    if (configQuery.isSuccess) {
      const price = zbcOracleResult.price;
      const signature = zbcOracleResult.signature;
      const doInvest = async () => {
        if (inputMode === "usdt") {
          const gas = await manager.estimateGas.invest(
            price,
            signature,
            ethers.utils.parseUnits(usdtAmount, 18),
            0
          );
          // add 20% gas limit
          const tx = await manager.invest(
            price,
            signature,
            ethers.utils.parseUnits(usdtAmount, 18),
            0,
            {
              gasLimit: gas.mul(150).div(100).toString()
            }
          );
          await tx.wait();
        } else {
          const gas = await manager.estimateGas.invest(
            price,
            signature,
            0,
            ethers.utils.parseUnits(zAmount, 9)
          );
          // add 20% gas limit
          const tx = await manager.invest(
            price,
            signature,
            0,
            ethers.utils.parseUnits(zAmount, 9),
            {
              gasLimit: gas.mul(150).div(100).toString()
            }
          );
          await tx.wait();
        }
        setNftDetail({
          startTime: format(new Date(), "MM/dd/yyyy HH:mm"),
          endTime: format(add(new Date(), { months: 18 }), "MM/dd/yyyy HH:mm"),
          investAmount: truncateNumber(costAll)
        });
        setShowModal(true);
      };
      setTxLoading(true);
      await callWithToastError(
        doInvest,
        toast,
        t("error occured", "An error occured. Please try again later.")
      );
      setTxLoading(false);
    }
  };

  const queryZbcCost = async (value: string) => {
    if (!Number(value) || !zbcOracleResult) {
      return;
    }
    const cost = await manager.calcInvestCost(
      zbcOracleResult.price,
      ethers.utils.parseUnits(value, 18),
      0
    );
    setZAmount(ethers.utils.formatUnits(cost.zbcCost, 9));
    // invester get two times of USDS value by default
    let bonusTimes = 2;
    if(isInPromotion && cost.usdtAll.gt(ethers.utils.parseEther("1000"))) {
      bonusTimes = 3;
    }
    setCostAll(ethers.utils.formatUnits(cost.usdtAll.mul(bonusTimes), 18));
    setZLoading(false);
  };
  const queryUsdtCost = async (value: string) => {
    if (!Number(value) || !zbcOracleResult) {
      return;
    }
    const cost = await manager.calcInvestCost(
      zbcOracleResult.price,
      0,
      ethers.utils.parseUnits(value, 9)
    );
    setUsdtAmount(ethers.utils.formatUnits(cost.usdtCost, 18));
    // invester get two times of USDS value by default
    let bonusTimes = 2;
    if(isInPromotion && cost.usdtAll.gt(ethers.utils.parseEther("1000"))) {
      bonusTimes = 3;
    }
    setCostAll(ethers.utils.formatUnits(cost.usdtAll.mul(bonusTimes), 18));
    setUsdtLoading(false);
  };

  if (
    usdtAmount &&
    (Number(usdtAmount) > Number(usdtBalance) ||
      parseEther(usdtAmount).lt(minUsdt))
  ) {
    usdtError = true;
  }

  if (
    zAmount &&
    // zbc decimals is 9
    (Number(zAmount) > Number(zBecBalance) || parseUnits(zAmount, 9).lt(minZbc))
  ) {
    zError = true;
  }

  const handleInputMode = async (mode: InputMode, value: string) => {
    if (!isConnected) {
      return;
    }
    if (mode === "usdt") {
      setUsdtAmount(value);
      if (!!Number(value)) {
        setInputMode("usdt");
        setZLoading(true);
        queryZbcCost(value);
      }
    } else {
      setZAmount(value);
      if (!!Number(value)) {
        setInputMode("zbec");
        setUsdtLoading(true);
        queryUsdtCost(value);
      }
    }
  };

  return (
    <div className={styles.invest}>
      <Box className={styles.container}>
        <Box className={styles.banner}>
          <Box>
            <img className={styles.img1} src={img1} alt="img1" />
          </Box>
          <Box className={styles.bannerTitle}>
            {t("Settle Finance", "Settle Finance")}
          </Box>
          <Box className={styles.bannerText}>
            {t("Income backed DeFi", "Income backed DeFi")}
          </Box>
        </Box>

        <Flex className={styles.investMain} justifyContent="center">
          <Flex className={styles.investSection}>
            <Box className={styles.investTitle}>
              {t(
                "Input both ZBC and USDT to purchase",
                "Input both ZBC and USDT to purchase"
              )}
            </Box>
            {isInPromotion && <Promotion />}

            <Flex wrap="wrap" marginBottom={10} justifyContent="center" gap={1}>
              <Box className={styles.investToken} maxW="300px">
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    marginTop={1}
                    marginLeft={1}
                    paddingRight={2}
                  >
                    <img src={usdtIcon} alt="" />
                  </InputLeftElement>
                  <CommonInput
                    value={usdtAmount}
                    onChange={(e) => handleInputMode("usdt", e.target.value)}
                    type="number"
                    className={classNames(
                      styles.investInput,
                      usdtError && styles.inputError
                    )}
                    disabled={usdtLoading}
                    placeholder={t("Enter USDT Amount", "Enter USDT Amount")}
                  />
                  {usdtLoading && (
                    <InputRightElement
                      pointerEvents="none"
                      marginTop={1}
                      marginLeft={1}
                      paddingRight={2}
                    >
                      <Spinner />
                    </InputRightElement>
                  )}
                </InputGroup>

                <Box className={styles.tokenDesc}>
                  {t(
                    "Minimal Amount USDT",
                    "Minimal Amount: {{minUsdt}} USDT",
                    {
                      minUsdt: isDev
                        ? formatEther(minUsdt)
                        : Math.floor(Number(formatEther(minUsdt)))
                    }
                  )}
                </Box>
                <Box className={styles.tokenDesc}>
                  {t("Balance", "Balance")}: {truncateNumber(usdtBalance)} USDT
                </Box>
              </Box>

              <Box className={styles.plus}>+</Box>

              <Box className={styles.investToken} maxW="300px">
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    marginTop={1}
                    marginLeft={1}
                    paddingRight={2}
                  >
                    <img src={zbecIcon} alt="" />
                  </InputLeftElement>
                  <CommonInput
                    value={zAmount}
                    onChange={(e) => handleInputMode("zbec", e.target.value)}
                    type="number"
                    className={classNames(
                      styles.investInput,
                      zError && styles.inputError
                    )}
                    placeholder={t("Enter ZBC Amount", "Enter ZBC Amount")}
                    disabled={zLoading}
                  />
                  {zLoading && (
                    <InputRightElement
                      pointerEvents="none"
                      marginTop={1}
                      marginLeft={1}
                      paddingRight={2}
                    >
                      <Spinner />
                    </InputRightElement>
                  )}
                </InputGroup>
                <Box className={styles.tokenDesc}>
                  {t("Minimal Amount ZBC", "Minimal Amount: {{minZbc}} ZBC", {
                    minZbc: isDev
                      ? truncateNumber(formatUnits(minZbc, 9), 2)
                      : Math.floor(Number(formatUnits(minZbc, 9)))
                  })}
                </Box>
                <Box className={styles.tokenDesc}>
                  {t("Balance", "Balance")}: {truncateNumber(zBecBalance)} ZBC
                </Box>
              </Box>
            </Flex>

            <JoinButton
              handleClick={handleInvest}
              name={
                usdtNotApproved
                  ? t("Approve USDT", "Approve USDT")
                  : zBecNotApproved
                    ? t("Approve ZBC", "Approve ZBC")
                    : t("Invest", "Invest")
              }
              disabled={!isConnected}
              loading={txLoading}
            />
          </Flex>
          <Box className={styles.rules}>
            <Box>
              <img className={styles.img2} src={img2} alt="img2" />
            </Box>
            <Box className={styles.rulesTitle}>{t("Rules", "Rules")}:</Box>
            <Box className={styles.rulesText}>
              {t(
                "rules detail",
                "To start earning, you need to have both ZBC and USDT at the rate of 1:9 in terms of USD value. And the minimum invest amount is 100 USDT."
              )}
            </Box>
          </Box>
        </Flex>
      </Box>
      {showModal && (
        <Modal
          setIsOpen={handleCloseModal}
          className={styles.nftModal}
          closable={false}
        >
          <Flex flexDir="column" alignItems="center" gap={8}>
            <Box className={styles.modalImg}>
              <img src={nftImg} alt="" />
            </Box>
            <Box className={styles.modalDivier} />
            <Flex className={styles.modalDetail} flexDir="column">
              <Flex>
                <Box>{t("Invest Date", "Invest Date")}:</Box>
                <Box>{nftDetail ? nftDetail.startTime : "-"}</Box>
              </Flex>
              <Flex>
                <Box>{t("Invest Amount", "Invest Amount")}:</Box>
                <Box>
                  {nftDetail ? truncateNumber(nftDetail.investAmount, 4) : "-"}{" "}
                  USDT
                </Box>
              </Flex>
              <Flex>
                <Box>{t("End Date", "End Date")}:</Box>
                <Box>{nftDetail ? nftDetail.endTime : "-"}</Box>
              </Flex>
            </Flex>
            <JoinButton
              handleClick={() => navigate("/profile")}
              name={t("Check Your History", "Check Your History")}
              className={styles.checkNftBtn}
            />
          </Flex>
        </Modal>
      )}
    </div>
  );
};
