import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import { HashLink as Link } from "react-router-hash-link";
import { useMatch, useNavigate } from "react-router-dom";

import WalletButton from "../WalletButton/WalletButton";
import { JoinButton } from "../JoinButton";
import { Hamburger } from "./Hamburger";
import { useTranslation } from "react-i18next";

import "./index.css";
import LangButton from "../LangButton/LangButton";

const burger = require("../../assets/image/burger.svg");

type LinkInfo = {
  name: string;
  link: string;
};

const HOMEPAGE_LINKS: LinkInfo[] = [
  {
    name: "Home",
    link: "/#home"
  },
  {
    name: "Why us",
    link: "/#whyus"
  },
  {
    name: "Our clients",
    link: "/#clients"
  },
  {
    name: "Settle Ecosystem",
    link: "/#ecosystem"
  },
  {
    name: "Partnership",
    link: "/#partner"
  }
  // {
  //   name: "About us",
  //   link: "/#about",
  // },
];

const APP_LINKS: LinkInfo[] = [
  // auto navigate to register page if not registered
  // {
  //   name: 'Register',
  //   link: '/register',
  // },
  // {
  //   name: 'EarlyBird',
  //   link: '/earlybird',
  // },
  {
    name: "Invest",
    link: "/invest"
  },
  {
    name: "Mining",
    link: "/mining"
  },
  {
    name: "Farm",
    link: "/farm"
  },
  {
    name: "Profile",
    link: "/profile"
  },
];

const linksMap: Record<string, LinkInfo[]> = {
  homePage: HOMEPAGE_LINKS,
  otherPage: []
};

export function Header() {
  const navigate = useNavigate();
  const [showBurgerMenu, setShowBurgerMenu] = React.useState(false);
  let links = linksMap.otherPage;
  const isHomepage = !!useMatch("/");
  const isEarlyBirdPage = !!useMatch("/earlybird");
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isRegisterPage = !!useMatch("/register");
  const isInvestPage = !!useMatch("/invest");
  const isProfilePage = !!useMatch("/profile");
  const isMiningPage = !!useMatch("/mining");
  const isStakePage = !!useMatch("/farm");
  const isAppPage =
    isRegisterPage ||
    isInvestPage ||
    isProfilePage ||
    isMiningPage ||
    isStakePage ||
    isEarlyBirdPage;
  const isOtherPage = !isHomepage && !isEarlyBirdPage && !isAppPage;
  if (isHomepage) {
    links = HOMEPAGE_LINKS;
  } else if (isAppPage) {
    links = APP_LINKS;
  }
  const appLink = "/invest";
  // const toast = useToast();
  // @ts-ignore
  const { t } = useTranslation();

  t("EarlyBird", "EarlyBird");
  t("Profile", "Profile");
  t("Farm", "Farm");
  const socialIcons = (
    <>
      <Box
        as="a"
        className="headerMediumIcon headerSocialIcon"
        href="https://medium.com/@settlefi"
      />
      <Box
        as="a"
        className="headerTwitterIcon headerSocialIcon"
        href="https://twitter.com/settlefi"
      />
      <Box
        as="a"
        className="headerTgIcon headerSocialIcon"
        href="https://t.me/settlefi"
      />
    </>
  );

  return (
    <Box className="headerContainer">
      {showBurgerMenu && (
        <Hamburger
          isHomepage={isHomepage}
          closeMenu={() => setShowBurgerMenu(false)}
          links={links}
          appLink={appLink}
        />
      )}
      <Box className="headerContent">
        <Flex className="headerFlex">
          <Link className="headerLogoWrap" to={"/"}>
            <Box className="headerLogo" />
            <Box className="headerLogoText">Settle finance</Box>
          </Link>
          <Box className="headerLinkContainer">
            {links.map((link) => (
              <Link to={link.link} key={link.name} className="headerLink">
                {t(link.name)}
              </Link>
            ))}
          </Box>
          {isHomepage && (
            <Box className="headerLaunchBtn">
              {socialIcons}
              <LangButton />
              <JoinButton
                handleClick={() => {
                  navigate(appLink);
                }}
                name={t("Launch App", "Launch App")}
              />
            </Box>
          )}
          {!isHomepage && (
            <Box className="headerLaunchBtn">
              <Box
                as="a"
                className="headerTwitterIcon headerSocialIcon"
                href={"https://twitter.com"}
              />
              <Box
                as="a"
                className="headerTgIcon headerSocialIcon"
                href={"https://twitter.com"}
              />
              <LangButton />
              <WalletButton />
            </Box>
          )}

          <Box className="headerHamburger" cursor="pointer">
            {socialIcons}
            <LangButton />
            <img
              className="headerHamburgerImg"
              src={burger.default}
              alt=""
              style={{ width: "40px", height: "40px" }}
              onClick={() => setShowBurgerMenu(true)}
            />
          </Box>
        </Flex>
      </Box>
    </Box>
  );
}
