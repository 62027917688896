/* eslint-disable unused-imports/no-unused-imports */
import { Box, Flex, Slider, SliderFilledTrack, SliderMark, SliderThumb, SliderTrack, useToast } from "@chakra-ui/react";
import { TokenInput } from "../UI/CommonInput/TokenInput";
import { useTranslation } from "react-i18next";
import usdtIcon from "../../assets/image/invest/usdt.png";
import stfIcon from "../../assets/image/STF.png";
import styles from "./index.module.scss";
import { JoinButton } from "../JoinButton";
import { useEffect, useState } from "react";
import { LpOracleResult, requestLpPrice } from "../../utils/zbcOracleUtil";
import { callWithToastError, truncAddress, truncateNumber } from "../../utils/utils";
import { formatEther, parseEther } from "ethers/lib/utils.js";
import { useContracts } from "../../hooks/useContracts";
import { BigNumber, BigNumberish, ethers } from "ethers";
import { LpInfoItem } from "../../hooks/useLpInfos";
import { useWallet } from "../../store/wallet-context";
import { set } from "lodash";
import { useQuery } from "@tanstack/react-query";
import { es } from "date-fns/locale";

const labelStyles = {
  mt: "2",
  ml: "-2.5",
  fontSize: "sm",
};

export const RemoveLiquidity = ({
  lpOracleResult,
  bill,
  onClose,
}: {
  lpOracleResult: LpOracleResult | undefined
  bill: LpInfoItem | null
  onClose: () => void
}) => {
  //@ts-ignore
  const { t } = useTranslation();
  const toast = useToast();
  const contracts = useContracts();
  const { accountAddress } = useWallet();
  const manager = contracts?.manager;

  let usdtToGet = BigNumber.from(0);
  let stfToGet = BigNumber.from(0);
  const isReady = !(!manager || !accountAddress || !bill || !lpOracleResult);
  if (isReady) {
    const price = lpOracleResult!.price.lpPriceFixedPoint18;
    usdtToGet = bill!.staked.div(2);
    stfToGet = bill!.staked.div(2).mul(parseEther("1")).div(price);
  }

  const expectedRewardQuery = useQuery({
    queryKey: ["expectedReward", bill?.id.toHexString()],
    queryFn: async () => {
      if (!manager || !bill) return;
      const reward = await manager.callStatic.lpWithdraw([bill.id]);
      return reward;
    },
    enabled: isReady,
  });

  console.log("expectedRewardQuery", expectedRewardQuery.data);
  const estimateUsdtToGet = expectedRewardQuery.isSuccess ? expectedRewardQuery.data!.allUsdtAmount : usdtToGet;
  const estimateStfToGet = expectedRewardQuery.isSuccess ? expectedRewardQuery.data!.allStfToSelf : stfToGet;

  // remove
  const [isRemoveLoading, setIsRemoveLoading] = useState(false);
  const handleRemove = async () => {
    if (!manager) return;
    setIsRemoveLoading(true);
    await callWithToastError(
      async () => {
        if (!bill || !manager) return;
        const tx = await manager.lpWithdraw([bill.id]);
        const receipt = await tx.wait();
        setIsRemoveLoading(false);
        onClose();
      },
      toast,
      t("Please wait for the bill to be finished or claim the reward first", "Please wait for the bill to be finished or claim the reward first."),
    );
    setIsRemoveLoading(false);
  };

  return (
    <Flex className={styles.container}>
      <Box className={styles.modalTitle}>{t("Withdraw liquidity", "Withdraw liquidity")}</Box>
      <Box className={styles.modalSubTitle}>
        {t("You will get", "You will get")}
      </Box>
      <Flex className={styles.receiveWrapper}>
        {/* <Box className={styles.receiveText}>{t("You will receive", "You will receive")}</Box> */}
        <Flex className={styles.inputGroup}>
          <Box className={styles.tokenInputWrapper}>
            <TokenInput
              isLoading={false}
              tokenIconSrc={usdtIcon}
              value={truncateNumber(formatEther(estimateUsdtToGet))}
              onChange={() => undefined}
              isDisabled
            />
          </Box>
          <Box className={styles.tokenInputWrapper}>
            <TokenInput
              isLoading={false}
              tokenIconSrc={stfIcon}
              value={truncateNumber(formatEther(estimateStfToGet))}
              onChange={() => undefined}
              isDisabled
            />
          </Box>
        </Flex>
      </Flex>

      <Box className={styles.desc}>
        {t(
          "Output is estimated. If the price changes by more than 10% your transaction will revert",
          "Output is estimated. If the price changes by more than 10% your transaction will revert.",
        )}
      </Box>

      <Flex className={styles.metadatas}>
        {expectedRewardQuery.isSuccess && <Flex className={styles.metadata}>
          <Box className={styles.metaLabel}>{t("STF Given", "STF Given")}:</Box>
          <Flex className={styles.priceValues}>
            <Box className={styles.metaValue}>
              {truncAddress(expectedRewardQuery.data!.inviterUp1)} 5%
            </Box>
            {expectedRewardQuery.data!.inviterUp2 !== ethers.constants.AddressZero && <Box className={styles.metaValue}>
              {truncAddress(expectedRewardQuery.data!.inviterUp2)} 5%
            </Box>}
            
          </Flex>
        </Flex>}
        {/* <Flex className={styles.metadata}>
          <Box className={styles.metaLabel}>{t("Burned", "Burned")}:</Box>
          <Box className={styles.metaValue}>10% USDT-STF LP</Box>
        </Flex> */}
        <Flex className={styles.metadata}>
          <Box className={styles.metaLabel}>{t("Price", "Price")}:</Box>
          <Flex className={styles.priceValues}>
            <Box className={styles.metaValue}>
              1 USDT = {lpOracleResult && truncateNumber(formatEther(lpOracleResult.price.lpPriceFixedPoint18))} STF
            </Box>
            <Box className={styles.metaValue}>
              1 STF ={" "}
              {lpOracleResult &&
                truncateNumber(
                  formatEther(parseEther("1").mul(parseEther("1")).div(lpOracleResult.price.lpPriceFixedPoint18)),
                )}{" "}
              USDT
            </Box>
          </Flex>
        </Flex>
      </Flex>

      <Flex className={styles.buttons}>
        <JoinButton handleClick={handleRemove} name={t("Withdraw", "Withdraw")} type="danger" loading={isRemoveLoading} />
      </Flex>
    </Flex>
  );
};
