import React from "react";
import { Box, Divider, Flex, useToast } from "@chakra-ui/react";
import { HashLink as Link } from "react-router-hash-link";
import emailjs from "@emailjs/browser";
import "./index.css";
import { useNavigate } from "react-router-dom";
const emailIcon = require("../../assets/image/subscription/email.png");

export function Subscription() {
  const navigate = useNavigate();
  const [email, setEmail] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);
  const toast = useToast();
  const toastIdRef = React.useRef<any>();
  function closeLoadingToast() {
    setLoading(false);
    if (toastIdRef.current) {
      toast.close(toastIdRef.current);
    }
  }
  const handleSubmit = async () => {
    if (loading) return;
    const emailError =
      !email || !email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/);
    if (emailError) {
      toast({
        description: "Please check your inputs.",
        status: "error",
        duration: 2000,
        isClosable: false,
      });
    } else {
      const serviceId = process.env.REACT_APP_SERVICE_ID;
      const messageTemplateId =
        process.env.REACT_APP_MESSAGE_CONTACT_TEMPLATE_ID;
      const thanksTemplateId = process.env.REACT_APP_THANKS_CONTACT_TEMPLATE_ID;
      const pubkey = process.env.REACT_APP_PUBKEY;
      if (!serviceId || !messageTemplateId || !thanksTemplateId || !pubkey) {
        console.error("Missing env variables");
        return;
      }
      setLoading(true);
      toastIdRef.current = toast({
        description: "loading...",
        status: "info",
        duration: 60_000,
      });

      await emailjs.send(
        serviceId,
        messageTemplateId,
        {
          email,
        },
        pubkey
      );

      emailjs
        .send(
          serviceId,
          thanksTemplateId,
          {
            email,
          },
          pubkey
        )
        .then(
          (result) => {
            console.log(result);
            closeLoadingToast();
            toast({
              description: "Thanks for subscribing.",
              status: "success",
              duration: 2000,
            });
          },
          (error) => {
            console.log(error);
            closeLoadingToast();
            toast({
              description: "Email service error, try again later.",
              status: "error",
            });
          }
        );
    }
  };
  return (
    <Box className="subscriptionContainer">
      <Box className="subscriptionContent">
        <Flex className="subscriptionFlex">
          {/* <Box className="subscriptionLinks">
            <Box className="subscriptionLinksGroup">
              <Box className="subscriptionText">Protocol</Box>
              <Box className="subscriptionText">White paper</Box>
              <Box className="subscriptionText">Dev Docs</Box>
            </Box>
            <Box className="subscriptionLinksGroup">
              <Box className="subscriptionText">Legal</Box>
              <Box className="subscriptionText">Terms of Use</Box>
              <Box className="subscriptionText">Privacy Policy</Box>
              <Box className="subscriptionText">Support</Box>
            </Box>
          </Box> */}
          <Flex flexWrap="wrap" alignItems="end" gap="30px">
            {/*  <Box className="subscriptionInputContainer">*/}
            {/*    <Box className="subscriptionInputLabel" mb="30px">*/}
            {/*      Subscribe our Newsletter*/}
            {/*    </Box>*/}
            {/*    <Flex align='center' gap="30px">*/}
            {/*      <InputGroup >*/}
            {/*        <InputLeftElement className="subscriptionInputRightElement">*/}
            {/*          <img*/}
            {/*            alt=""*/}
            {/*            src={emailIcon}*/}
            {/*            style={{*/}
            {/*              width: "60px",*/}
            {/*              height: "30px",*/}
            {/*              display: "inline-block",*/}
            {/*              paddingLeft: "20px",*/}
            {/*            }}*/}
            {/*          />*/}
            {/*        </InputLeftElement>*/}
            {/*        <Input*/}
            {/*          type="email"*/}
            {/*          value={email}*/}
            {/*          onChange={(e) => setEmail(e.target.value)}*/}
            {/*          placeholder="Your email"*/}
            {/*          height="56px"*/}
            {/*          bg="white"*/}
            {/*          borderRadius={50}*/}
            {/*          pl="60px"*/}
            {/*        />*/}
            {/*      </InputGroup>*/}
            {/*      <JoinButton handleClick={handleSubmit} />*/}
            {/*    </Flex>*/}
            {/*  </Box>*/}

            <Box className="subscriptionSocialFlex">
              <Box>
                <a href="mailto:info@settlefinance.xyz" className="contactus">
                  <img
                    className="subscriptionSocialFlexImg"
                    src={emailIcon}
                    alt={email}
                  />
                  Contact Us at info@settlefinance.xyz
                </a>
              </Box>
              {/*<Box className="subscriptionSocialItem subscriptionSocialLinkedin" />*/}
              {/*<Box className="subscriptionSocialItem subscriptionSocialTwitter" />*/}
              {/*<Box className="subscriptionSocialItem subscriptionSocialFacebook" />*/}
              {/*<Box className="subscriptionSocialItem subscriptionSocialDribble" />*/}
              {/*<Box className="subscriptionSocialInvite">- follow us</Box>*/}
            </Box>
          </Flex>
        </Flex>
        <Divider opacity="1" mt="30px" />
        <Flex
          className="copyrightWrap"
          align={"center"}
          justify="space-between"
        >
          <Link to={"/"}>
            <Flex width="200px">
              <Box className="subscriptionLogo" />
              <Box className="subscriptionLogoText">Settle finance</Box>
            </Flex>
          </Link>
          <Box className="footerText">
            © 2023 Settle Finance. All rights reserved.
          </Box>
        </Flex>
      </Box>
    </Box>
  );
}
