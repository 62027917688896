/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Signer, utils, Contract, ContractFactory, Overrides } from "ethers";
import type { Provider, TransactionRequest } from "@ethersproject/providers";
import type { PromiseOrValue } from "../../../../common";
import type {
  DeputyRefLogic,
  DeputyRefLogicInterface,
} from "../../../../contracts/dependant/deputyRef/DeputyRefLogic";

const _abi = [
  {
    inputs: [],
    name: "accessControl",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
] as const;

const _bytecode =
  "0x6080604052348015600f57600080fd5b50608280601d6000396000f3fe6080604052348015600f57600080fd5b506004361060285760003560e01c806313007d5514602d575b600080fd5b600054604080516001600160a01b039092168252519081900360200190f3fea264697066735822122099ad66ecdb43a3a78c9b8a3417226f58a8cf5d18bc127c5f5d16e45ec22a692664736f6c63430008110033";

type DeputyRefLogicConstructorParams =
  | [signer?: Signer]
  | ConstructorParameters<typeof ContractFactory>;

const isSuperArgs = (
  xs: DeputyRefLogicConstructorParams
): xs is ConstructorParameters<typeof ContractFactory> => xs.length > 1;

export class DeputyRefLogic__factory extends ContractFactory {
  constructor(...args: DeputyRefLogicConstructorParams) {
    if (isSuperArgs(args)) {
      super(...args);
    } else {
      super(_abi, _bytecode, args[0]);
    }
  }

  override deploy(
    overrides?: Overrides & { from?: PromiseOrValue<string> }
  ): Promise<DeputyRefLogic> {
    return super.deploy(overrides || {}) as Promise<DeputyRefLogic>;
  }
  override getDeployTransaction(
    overrides?: Overrides & { from?: PromiseOrValue<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(overrides || {});
  }
  override attach(address: string): DeputyRefLogic {
    return super.attach(address) as DeputyRefLogic;
  }
  override connect(signer: Signer): DeputyRefLogic__factory {
    return super.connect(signer) as DeputyRefLogic__factory;
  }

  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): DeputyRefLogicInterface {
    return new utils.Interface(_abi) as DeputyRefLogicInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): DeputyRefLogic {
    return new Contract(address, _abi, signerOrProvider) as DeputyRefLogic;
  }
}
