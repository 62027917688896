import React, { useEffect, useState } from "react";
import classNames from "classnames";
import axios from "axios";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { JoinButton } from "../../components/JoinButton";
import avatar from "../../assets/image/profile/avatar.png";
import usdc from "../../assets/image/profile/usdc.png";
import referal from "../../assets/image/profile/referal.png";
import historyTab from "../../assets/image/profile/history-tab.png";
import teamTab from "../../assets/image/profile/team-tab.png";
import historyItemIcon from "../../assets/image/profile/history-icon.png";

import styles from "./Profile.module.scss";
import { Box, Flex, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";

import { Modal } from "../../components/Modal";
import { CommonInput } from "../../components/UI/CommonInput";
import { useNavigate } from "react-router-dom";
import { BigNumber, ethers } from "ethers";
import { useWallet } from "../../store/wallet-context";
import { useQuery } from "@tanstack/react-query";
import { hexify, callWithToastError, truncateNumber, truncAddress } from "../../utils/utils";
import { useToast } from "@chakra-ui/react";
import { useContracts } from "../../hooks/useContracts";
import { useTokens } from "../../hooks/useTokens";
import { add, format, fromUnixTime } from "date-fns";
import { useIsRegistered } from "../../hooks/useIsRegistered";
import { useBalance, useBlockNumber } from "wagmi";
import { useTranslation } from "react-i18next";
import { ChevronUpIcon, ChevronDownIcon } from "@chakra-ui/icons";
import { SwapModal } from "./SwapModal";

type TabName = "history" | "team";

const BACKEND = axios.create({ baseURL: process.env.REACT_APP_BACKEND_URL });

type AccountInfoInterface = {
  level: number;
  totalReferrals: number;
  directReferrals: number;
  groupPerformance: ethers.BigNumber;
  directReferralList: {
    address: string;
    level: number;
    invested: ethers.BigNumber;
    groupPerformance: ethers.BigNumber;
  }[];
} | null;

export const Profile = () => {
  //@ts-ignore
  const { t } = useTranslation();
  const toast = useToast();
  const contracts = useContracts();
  const tokensQuery = useTokens();
  const { accountAddress, isConnected } = useWallet();
  const bnbBalanceQuery = useBalance({ address: accountAddress });

  const [tabName, setTabName] = useState<TabName>("history");
  const [expandIndex, setExpandIndex] = useState<number>(-1);
  const [showTransferNftModal, setShowModal] = useState<boolean>(false);
  const [showSwapModal, setShowSwapModal] = useState<boolean>(false);
  const [currentTransferNftId, setCurrentTransferNftId] = useState<BigNumber | null>(null);
  const [transferToAddress, setTransferToAddress] = useState<string>("");
  const [showWithdrawModal, setShowWithdrawModal] = useState<boolean>(false);
  const [accountInfo, setAccountInfo] = useState<AccountInfoInterface>(null);
  const [claimedGroupRewards, setClaimedGroupRewards] = useState<ethers.BigNumber>(ethers.BigNumber.from(0));
  const [claimableGroupRewards, setClaimableGroupRewards] = useState<ethers.BigNumber>(ethers.BigNumber.from(0));
  const [pendingGroupRewards, setPendingGroupRewards] = useState<ethers.BigNumber>(ethers.BigNumber.from(0));
  const [isGroupRewardsClaimable, setIsGroupRewardsClaimable] = useState<boolean>(false);
  const [pendingGroupRewardsWithdraw, setPendingGroupRewardsWithdraw] = useState<any>(null);
  const isHistory = tabName === "history";
  const isTeam = tabName === "team";

  const investmentCredential = contracts.investmentCredential;
  const manager = contracts.manager;
  const invitationCenter = contracts.invitationCenter;

  const navigate = useNavigate();
  const isRegisteredQuery = useIsRegistered();
  if (isRegisteredQuery.data === false) {
    navigate("/register");
  }

  const fetchUserInfo = async () => {
    const getInfo = async () => {
      if (!accountAddress) {
        return true;
      }

      const info = (await BACKEND.post("/api2/info/", { address: accountAddress })).data;

      setAccountInfo({
        level:
          Number(info.tierForce.substring(5, info.tierForce.length)) > 0
            ? Number(info.tierForce.substring(5, info.tierForce.length))
            : Number(info.tier.substring(5, info.tier.length)),
        totalReferrals: info.allInvitees.length,
        directReferrals: info.inviteeNumber,
        groupPerformance: ethers.BigNumber.from(info.totalInviteeInvestUsdtAmount),
        directReferralList: info.allInvitees
          .filter((item: any) => item.inviter.toLocaleLowerCase() === accountAddress.toLocaleLowerCase())
          .map((item: any) => ({
            address: item.address,
            level:
              Number(item.tierForce.substring(5, item.tierForce.length)) > 0
                ? Number(item.tierForce.substring(5, item.tierForce.length))
                : Number(item.tier.substring(5, item.tier.length)),
            invested: ethers.BigNumber.from(item.investUsdtAmount),
            groupPerformance: ethers.BigNumber.from(item.investUsdtAmount).add(
              ethers.BigNumber.from(item.totalInviteeInvestUsdtAmount),
            ),
          })),
      });

      const claimedRewards = (await BACKEND.post("/api2/claimedreward/", { address: accountAddress })).data;
      setClaimedGroupRewards(ethers.BigNumber.from(claimedRewards.amount));

      const claimableRewards = (
        await BACKEND.post("/api2/claimablereward/", {
          address: accountAddress,
        })
      ).data;
      setClaimableGroupRewards(ethers.BigNumber.from(claimableRewards.amount));

      const pendingRewards = (await BACKEND.post("/api2/pendingreward/", { address: accountAddress })).data;
      setPendingGroupRewards(ethers.BigNumber.from(pendingRewards.amount));

      const pendingWithdraw = (
        await BACKEND.post("/api2/getPendingWithdraw/", {
          address: accountAddress,
        })
      ).data.result;

      setPendingGroupRewardsWithdraw(pendingWithdraw);

      setIsGroupRewardsClaimable(pendingWithdraw.length > 0);
    };
    getInfo().catch(e => {
      console.log(e);
    });
  };

  // Queries
  const referalCodeQuery = useQuery({
    queryKey: ["referalCode"],
    queryFn: async () =>
      ethers.utils.parseBytes32String((await invitationCenter!.inviterRecords(accountAddress!)).invitationCode),
    enabled: !!accountAddress && !!invitationCenter,
  });
  const investmentCredentialBalanceQuery = useQuery({
    queryKey: ["investmentCredentialBalance"],
    queryFn: async () => await investmentCredential!.balanceOf(accountAddress!),
    enabled: !!accountAddress && !!investmentCredential,
  });

  const investmentCredentialListQuery = useQuery({
    queryKey: ["investmentCredentialListQuery"],
    queryFn: async () => {
      const nftNumberlist: ethers.BigNumber[] = await investmentCredential!.tokensOfOwner(accountAddress!);
      const rewards = await manager!.callStatic.getInvestmentReward(nftNumberlist);
      // const rewards = [0];
      const nftList = await Promise.all(
        nftNumberlist.map(async (nftNumber, index) => {
          const nftAtributes = await investmentCredential!.getUint256Attributes(
            [
              hexify("startTimestamp"),
              hexify("investUsdtAmount"),
              hexify("finishTimestamp"),
              hexify("cumulativeClaimedUsds"),
            ],
            nftNumber,
          );
          return {
            id: nftNumber,
            startTimestamp: nftAtributes[0].toString(),
            investUsdtAmount: nftAtributes[1].toString(),
            finishTimestamp: nftAtributes[2].toString(),
            cumulativeClaimedUsds: nftAtributes[3].toString(),
            claimableUsds: rewards[index].toString(),
          };
        }),
      );
      return await nftList;
    },
    enabled: !!accountAddress && !!investmentCredential && !!manager,
  });

  // watch block number to update the states of NFTs
  const blockNumber = useBlockNumber({ watch: true });
  const blockNumberRef = React.useRef(blockNumber);

  useEffect(() => {
    if (blockNumber.isSuccess && blockNumberRef.current.data !== blockNumber.data) {
      console.log(
        "blockNumber updated, will refech nft list:",
        blockNumber.data,
        "previous:",
        blockNumberRef.current.data?.toString(),
      );
      blockNumberRef.current = blockNumber;
      investmentCredentialListQuery.refetch();
      fetchUserInfo();
    }
  }, [blockNumber, investmentCredentialListQuery]);

  const perosonQuery = useQuery({
    queryKey: ["perosonQuery"],
    queryFn: async () => await manager!.getPerson(accountAddress!),
    enabled: !!accountAddress && !!manager,
  });

  const perosonQueryComplete = perosonQuery.status === "success" && perosonQuery.data;
  const investmentCredentialListQueryComplete =
    investmentCredentialListQuery.status === "success" && investmentCredentialListQuery.data;
  const referalCodeQueryComplete = referalCodeQuery.status === "success" && referalCodeQuery.data;

  const referralLink = referalCodeQueryComplete
    ? `${window.origin}/register?ref=${referalCodeQuery.data}`
    : "loading...";

  const usdsBalance = tokensQuery.isSuccess ? tokensQuery.data.usds.balance : "0";

  let historyInfos: any[] = [];
  try {
    historyInfos = [
      [
        t("NFT Amount", "NFT Amount"),
        investmentCredentialBalanceQuery.data ? investmentCredentialBalanceQuery.data.toString() : "0",
      ],
      [
        t("Total USDT cost", "Total USDT cost"),
        perosonQueryComplete
          ? truncateNumber(ethers.utils.formatUnits(perosonQuery.data.investUsdtCost, 18).toString())
          : "0",
      ],
      [
        t("Total ZBC cost", "Total ZBC cost"),
        perosonQueryComplete
          ? truncateNumber(ethers.utils.formatUnits(perosonQuery.data.investZbcCost, 9).toString())
          : "0",
      ],
      [
        t("Total USDS locked", "Total USDS locked"),
        investmentCredentialListQueryComplete
          ? truncateNumber(
            ethers.utils.formatUnits(
              ethers.BigNumber.from(
                investmentCredentialListQuery.data.reduce(
                  (acc: any, nft) => ethers.BigNumber.from(nft.investUsdtAmount).add(acc),
                  0,
                ),
              ).sub(
                ethers.BigNumber.from(
                  investmentCredentialListQuery.data.reduce(
                    (acc: any, nft) => ethers.BigNumber.from(nft.cumulativeClaimedUsds).add(acc),
                    0,
                  ),
                ),
              ),

              18,
            ),
          )
          : "0",
      ],
      [
        t("Total USDS claimed", "Total USDS claimed"),
        investmentCredentialListQueryComplete
          ? truncateNumber(
            ethers.utils
              .formatUnits(
                investmentCredentialListQuery.data
                  .reduce((acc, nft) => acc + Number(nft.cumulativeClaimedUsds), 0)
                  .toString(),
                18,
              )
              .toString(),
          )
          : "0",
      ],
    ];
  } catch (e) {
    console.log(e);
  }

  const handleWithdrawUsds = async () => {
    if (!bnbBalanceQuery.isSuccess) {
      return;
    }
    if (bnbBalanceQuery.data!.value.lt(ethers.utils.parseUnits("0.001", 18))) {
      toast({
        title: t("Insufficient BNB balance for gas", "Insufficient BNB balance for gas."),
        status: "error",
        duration: 1500,
        isClosable: true,
      });
      return;
    }
    callWithToastError(
      () => manager!.redeemUsds(ethers.utils.parseEther(usdsBalance)),
      toast,
      t("error occured", "An error occured. Please try again later."),
    );
  };
  const handleCloseWithdraw = () => {
    setShowWithdrawModal(false);
  };
  const handleWithdraw = () => {
    setShowWithdrawModal(true);
  };

  const handleClaimReward = async (id: BigNumber) => {
    if (!bnbBalanceQuery.isSuccess) {
      return;
    }
    if (bnbBalanceQuery.data!.value.lt(ethers.utils.parseUnits("0.001", 18))) {
      toast({
        title: t("Insufficient BNB balance for gas", "Insufficient BNB balance for gas."),
        status: "error",
        duration: 1500,
        isClosable: true,
      });
      return;
    }

    callWithToastError(
      () => manager!.getInvestmentReward([id]),
      toast,
      t("error occured", "An error occured. Please try again later."),
    );
  };

  const handleTabClick = (tabName: TabName) => {
    setTabName(tabName);
  };

  const handleExpandIndex = (index: number) => {
    if (index === expandIndex) {
      setExpandIndex(-1);
    } else {
      setExpandIndex(index);
    }
  };

  const openTransferNft = (id: BigNumber) => {
    setCurrentTransferNftId(id);
    setShowModal(true);
  };

  const handleTransferNft = async () => {
    if (!currentTransferNftId) {
      return;
    }
    if (!transferToAddress) {
      toast({
        description: t("Please input address", "Please input address"),
        status: "error",
        duration: 1000,
        isClosable: false,
      });
      return;
    }
    callWithToastError(
      async () => {
        await investmentCredential!.transferFrom(accountAddress!, transferToAddress, currentTransferNftId);
        investmentCredentialListQuery.refetch();
      },
      toast,
      t("error occured", "An error occured. Please try again later."),
    );
    handleClolseTransferNft();
  };

  const handleClolseTransferNft = () => {
    setCurrentTransferNftId(null);
    setShowModal(false);
    setTransferToAddress("");
  };

  const handleCopyRefLink = () => {
    // navigator.clipboard.writeText(referralLink);
    console.log(referralLink);
    toast({
      description: t("Copied to clipboard", "Copied to clipboard!"),
      status: "success",
      duration: 1000,
      isClosable: false,
    });
  };

  const handleClaimGroupRewards = () => {
    const params = pendingGroupRewardsWithdraw[0];
    callWithToastError(
      () =>
        manager!.withdrawPromotion(
          {
            who: params.address,
            usdsAmount: params.usdsAmount,
            ticketNumber: params.ticketNumber,
          },
          ethers.utils.splitSignature(params.calldata),
        ),
      toast,
      t("error occured", "An error occured. Please try again later."),
    );
  };

  const handleRequestGroupRewards = async () => {
    if (!bnbBalanceQuery.isSuccess) {
      return;
    }
    if (bnbBalanceQuery.data!.value.lt(ethers.utils.parseUnits("0.001", 18))) {
      toast({
        title: t("Insufficient BNB balance for gas", "Insufficient BNB balance for gas."),
        status: "error",
        duration: 1500,
        isClosable: true,
      });
      return;
    }
    if (claimableGroupRewards.eq(0)) {
      toast({
        description: t("Nothing to Claim", "Nothing to Claim!"),
        status: "error",
        duration: 1000,
        isClosable: false,
      });
      return;
    }
    await BACKEND.post("/api2/withdrawRequest/", {
      address: accountAddress,
      usdsAmount: claimableGroupRewards.toString(),
    }).then(res => {
      if (res.data.success) {
        toast({
          description: t("Request Sent", "Request Sent, pending..."),
          status: "success",
          duration: 1000,
          isClosable: false,
        });
      }
    });
  };

  let canWithdraw = false;
  const forbitList: string[] = require("./forbidWithdrawList.json");
  if (
    isConnected &&
    !!accountAddress &&
    forbitList.findIndex(item => item.toLowerCase() === accountAddress?.toLowerCase()) === -1
  ) {
    canWithdraw = true;
  }

  return (
    <div className={styles.profile}>
      <Box className={styles.container}>
        <Box className={styles.card}>
          <Box>
            <img className={styles.avatar} src={avatar} alt="avatar" />
          </Box>
          <Flex flexDir="column" w="100%" gap={4}>
            <Box className={styles.name}>Level. {accountInfo?.level || 0}</Box>
            <Box className={styles.address}>{accountAddress || "-"}</Box>
            <Flex wrap="wrap" justifyContent="flex-start" className={styles.groupRewards}>
              <Flex flexDir="column">
                <Box className={styles.total}>{t("Claimed Group Rewards", "Claimed Group Rewards")}:</Box>
                <Box className={styles.tokenAmount}>
                  <strong>{truncateNumber(ethers.utils.formatEther(claimedGroupRewards || 0), 4)}</strong> USDS
                </Box>
              </Flex>
              <Flex flexDir="column">
                <Box className={styles.total}>{t("Available Group Rewards", "Available Group Rewards")}:</Box>
                <Box className={styles.tokenAmount}>
                  {<strong>{truncateNumber(ethers.utils.formatEther(claimableGroupRewards || 0), 4)}</strong>} USDS
                </Box>
              </Flex>
              <Flex flexDir="column">
                <Box className={styles.total}>{t("Pending Group Rewards", "Pending Group Rewards")}:</Box>
                <Box className={styles.tokenAmount}>
                  {<strong>{truncateNumber(ethers.utils.formatEther(pendingGroupRewards || 0), 4)}</strong>} USDS
                </Box>
              </Flex>
              {isGroupRewardsClaimable ? (
                <JoinButton
                  handleClick={handleClaimGroupRewards}
                  name={t("Claim Rewards", "Claim Rewards")}
                  disabled={!isConnected}
                />
              ) : (
                <JoinButton
                  handleClick={handleRequestGroupRewards}
                  name={t("Request Claim", "Request Claim")}
                  disabled={!isConnected}
                />
              )}
            </Flex>
          </Flex>
        </Box>
        <Box className={styles.card}>
          <Box>
            <img className={styles.usdc} src={usdc} alt="usdc" />
          </Box>
          <Flex wrap="wrap" justifyContent="space-between" w="100%" alignItems="center">
            <Box className={styles.usdcBalance}>
              {t("USDS Balance", "USDS Balance")}: {truncateNumber(usdsBalance)} USDS
            </Box>
            <Box className={styles.usdcButtonGroup}>
              {/*{canWithdraw && (*/}
              {/*  <JoinButton*/}
              {/*    handleClick={handleWithdraw}*/}
              {/*    name={t("Redeem USDT", "Redeem USDT")}*/}
              {/*    disabled={!isConnected}*/}
              {/*  />*/}
              {/*)}*/}
              <JoinButton
                handleClick={() => setShowSwapModal(true)}
                name={t("Swap", "Swap")}
                disabled={!isConnected}
              />
              <JoinButton
                handleClick={() => navigate("/mining")}
                name={t("STF Mining", "STF Mining") + " →"}
                disabled={!isConnected}
              />
            </Box>
          </Flex>
        </Box>
        <Box className={styles.card}>
          <Box>
            <img className={styles.referal} src={referal} alt="referal" />
          </Box>
          <Flex wrap="wrap" justifyContent="space-between" w="100%" alignItems="center">
            <Box className={styles.referalText}>
              {t("Your Referral Link", "Your Referral Link")}: {referralLink}
            </Box>
            <CopyToClipboard text={referralLink} onCopy={handleCopyRefLink}>
              <div className={styles.copyButton}>{t("Copy", "Copy")}</div>
            </CopyToClipboard>
          </Flex>
        </Box>

        <Flex className={styles.tabTitleContainer}>
          <Box>
            <img className={styles.tabIcon} src={isHistory ? historyTab : teamTab} alt="tab" />
          </Box>
          <Box
            className={classNames(styles.tabTitle, isHistory && styles.choosenTabTitle)}
            onClick={() => handleTabClick("history")}
          >
            {t("Invest History", "Invest History")}
          </Box>
          <Box className={classNames(styles.tabTitle, styles.choosenTabTitle)}>|</Box>
          <Box
            className={classNames(styles.tabTitle, !isHistory && styles.choosenTabTitle)}
            onClick={() => handleTabClick("team")}
          >
            {t("Team", "Team")}
          </Box>
        </Flex>
        {isHistory && (
          <>
            <Flex className={styles.historyInfoFlex}>
              {historyInfos.map((info, index) => (
                <Flex key={index} flexDir="column" className={styles.historyInfo}>
                  <Box className={styles.historyInfoTitle}>{info[0]}</Box>
                  <Box className={styles.historyInfoValue}>
                    <strong>{info[1]}</strong>
                  </Box>
                </Flex>
              ))}
            </Flex>
          </>
        )}
        {isHistory && (
          <>
            <TableContainer className={styles.historyTable}>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>{t("Start Date", "Start Date")}</Th>
                    <Th>{t("Invest Amount", "Invest Amount")}</Th>
                    <Th>{t("End Date", "End Date")}</Th>
                    <Th>{t("Total Claimed", "Total Claimed")}</Th>
                    <Th>{t("Available Rewards", "Available Rewards")}</Th>
                    <Th>{t("Claim", "Claim")}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {investmentCredentialListQueryComplete &&
                    investmentCredentialListQuery.data
                      .sort((a, b) => Number(b.startTimestamp) - Number(a.startTimestamp))
                      .map((history, index) => (
                        <>
                          <Tr key={"history" + index}>
                            <Td>
                              <Box className={styles.firstTd}>
                                <img src={historyItemIcon} alt="" />
                                {format(fromUnixTime(Number(history.startTimestamp)), "MM/dd/yyyy")}
                              </Box>
                            </Td>
                            <Td>{truncateNumber(ethers.utils.formatUnits(history.investUsdtAmount, 18))}</Td>
                            <Td>
                              {format(add(fromUnixTime(Number(history.startTimestamp)), { months: 18 }), "MM/dd/yyyy")}
                            </Td>
                            <Td>
                              {truncateNumber(
                                ethers.utils.formatUnits(ethers.BigNumber.from(history.cumulativeClaimedUsds), 18),
                              )}{" "}
                              USDS
                            </Td>
                            <Td>{truncateNumber(ethers.utils.formatUnits(history.claimableUsds, 18))} USDS</Td>
                            <Td>
                              <JoinButton
                                handleClick={() => handleClaimReward(history.id)}
                                name={history.claimableUsds === "0" ? t("Ended", "Ended") : t("Claim", "Claim")}
                                disabled={!history.claimableUsds || Number(history.claimableUsds) === 0 || !isConnected}
                                className={styles.claimButton}
                              />
                            </Td>
                            <Td>
                              {expandIndex === index ? (
                                <ChevronUpIcon onClick={() => handleExpandIndex(index)} style={{ cursor: "pointer" }} />
                              ) : (
                                <ChevronDownIcon
                                  onClick={() => handleExpandIndex(index)}
                                  style={{ cursor: "pointer" }}
                                />
                              )}
                            </Td>
                          </Tr>
                          {expandIndex === index && (
                            <>
                              <Tr key={index + 100}>
                                <Td colSpan={6}>
                                  <JoinButton
                                    handleClick={() => openTransferNft(history.id)}
                                    className={styles.tranferNftButton}
                                    disabled={!isConnected}
                                    name={t("Transfer NFT", "Transfer NFT")}
                                  />
                                </Td>
                                {/* <Td>
                                  <Box className={styles.historyItemEarned}>
                                    {t("Totally Earned", "Totally Earned")}:{" "}
                                    {truncateNumber(
                                      ethers.utils.formatUnits(
                                        ethers.BigNumber.from(history.cumulativeClaimedUsds).add(history.claimableUsds),
                                        18,
                                      ),
                                    )}
                                    USDS
                                  </Box>
                                </Td> */}
                              </Tr>
                            </>
                          )}
                        </>
                      ))}
                </Tbody>
              </Table>
            </TableContainer>
          </>
        )}

        {isTeam && (
          <>
            <Flex className={styles.historyInfoFlex}>
              <Flex flexDir="column" className={styles.historyInfo} backgroundColor="#364C98">
                <Box className={styles.historyInfoTitle}>{t("Total Referrals", "Total Referrals")}</Box>
                <Box className={styles.historyInfoValue}>
                  <strong>{accountInfo?.totalReferrals || "--"}</strong>
                </Box>
              </Flex>
              <Flex flexDir="column" className={styles.historyInfo} backgroundColor="#364C98">
                <Box className={styles.historyInfoTitle}>{t("Direct Referrals", "Direct Referrals")}</Box>
                <Box className={styles.historyInfoValue}>
                  <strong>{accountInfo?.directReferrals || "--"}</strong>
                </Box>
              </Flex>
              <Flex flexDir="column" className={styles.historyInfo} backgroundColor="#364C98">
                <Box className={styles.historyInfoTitle}>{t("Group Performance", "Group Performance")}</Box>
                <Box className={styles.historyInfoValue}>
                  <strong>
                    {truncateNumber(ethers.utils.formatEther(accountInfo?.groupPerformance || 0), 2)} USDT
                  </strong>
                </Box>
              </Flex>
              <Flex flexDir="column" className={styles.historyInfo} backgroundColor="#364C98">
                <Box className={styles.historyInfoTitle}>{t("Available Rewards", "Available Rewards")}</Box>
                <Box className={styles.historyInfoValue}>
                  <strong>{truncateNumber(ethers.utils.formatEther(claimableGroupRewards || 0), 2)} USDS</strong>
                </Box>
              </Flex>

              {/*<Flex h="100px" alignItems="center">*/}
              {/*  <JoinButton handleClick={undefined} name={t('Claim All', 'Claim All')} disabled={!isConnected} />*/}
              {/*</Flex>*/}
            </Flex>
          </>
        )}
        {isTeam && (
          <>
            <TableContainer className={styles.historyTable}>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>{t("Address", "Address")}</Th>
                    <Th>{t("Level", "Level")}</Th>
                    <Th>{t("Self-performance", "Invested")}</Th>
                    <Th>{t("Performance", "Performance")}</Th>
                    <Th>{t("", "")}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {accountInfo?.directReferralList.map((item, index) => (
                    <Tr key={"directReferralList" + index}>
                      {/*<Td>*/}
                      {/*  {' '}*/}
                      {/*  <Box className={styles.firstTd}>*/}
                      {/*    <img src={teamHistoryItemIcon} alt="" />*/}
                      {/*    {history.referralDate}*/}
                      {/*  </Box>*/}
                      {/*</Td>*/}
                      <Td>{truncAddress(item.address)}</Td>
                      <Td>Lvl. {item.level}</Td>
                      <Td>{truncateNumber(ethers.utils.formatEther(item.invested || 0), 2)} USDT</Td>
                      <Td>{truncateNumber(ethers.utils.formatEther(item.groupPerformance || 0), 2)} USDT</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </>
        )}
      </Box>

      <SwapModal isOpen={showSwapModal} setIsOpen={setShowSwapModal} />
      {showTransferNftModal && (
        <Modal setIsOpen={handleClolseTransferNft}>
          <Flex flexDir="column" alignItems="center" gap={8}>
            <Box className={styles.modalTitle}>Transfer NFT</Box>
            <Box w="80%">
              <CommonInput
                value={transferToAddress}
                onChange={e => setTransferToAddress(e.target.value)}
                placeholder="Input address here"
              />
            </Box>
            <JoinButton handleClick={handleTransferNft} name={t("Confirm", "Confirm")} disabled={!isConnected} />
          </Flex>
        </Modal>
      )}
      {showWithdrawModal && (
        <Modal setIsOpen={handleCloseWithdraw}>
          <Flex flexDir="column" alignItems="center" gap={8}>
            <Box className={styles.modalTitle}>{t("Confirmation", "Confirmation")}</Box>
            <Box className={styles.modalText}>
              {t(
                "redeem usds description",
                "You’re going to withdraw {{usdsBalance}} USDS to USDT, Commission fee 7%: {{commission}} USDT",
                {
                  usdsBalance: truncateNumber(usdsBalance, 4),
                  commission: truncateNumber((Number(usdsBalance) * 0.07).toString(), 4),
                },
              )}
            </Box>
            <JoinButton
              handleClick={handleWithdrawUsds}
              name={t("Confirm", "Confirm")}
              disabled={!isConnected || Number(usdsBalance) === 0}
            />
          </Flex>
        </Modal>
      )}
    </div>
  );
};
