/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type {
  ManagerEvent,
  ManagerEventInterface,
} from "../../../contracts/manager/ManagerEvent";

const _abi = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "who",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "usdtCost",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256[]",
        name: "earlyAccessIds",
        type: "uint256[]",
      },
    ],
    name: "BuyEarlyAccess",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "who",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "usdsAmount",
        type: "uint256",
      },
    ],
    name: "GetInvestmentReward",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "who",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "reward",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "bonus",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "stfToSelf",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "stfToDead",
        type: "uint256",
      },
    ],
    name: "GetReward",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "who",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "usdtAll",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "zbcUsdtPrice",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "usdtCost",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "zbcCost",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "investmentCredentialId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "usdsAmount",
        type: "uint256",
      },
    ],
    name: "Invest",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "who",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "usdsAmount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "usdtAmount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "usdtToColdWallet",
        type: "uint256",
      },
    ],
    name: "RedeemUsds",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "who",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "inviter",
        type: "address",
      },
    ],
    name: "Register",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "who",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "usds",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "power",
        type: "uint256",
      },
    ],
    name: "Stake",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "who",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "usdtAmount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "usdsAmount",
        type: "uint256",
      },
    ],
    name: "UsdtPurchaseUsds",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "who",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "usds",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "usdsToBurn",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "power",
        type: "uint256",
      },
    ],
    name: "Withdraw",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "who",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "usdsAmount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "ticketNumber",
        type: "uint256",
      },
    ],
    name: "WithdrawPromotionUsds",
    type: "event",
  },
] as const;

export class ManagerEvent__factory {
  static readonly abi = _abi;
  static createInterface(): ManagerEventInterface {
    return new utils.Interface(_abi) as ManagerEventInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): ManagerEvent {
    return new Contract(address, _abi, signerOrProvider) as ManagerEvent;
  }
}
