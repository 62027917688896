import { ethers } from "ethers";
import { useContracts } from "./useContracts";
import { useQuery } from "@tanstack/react-query";

export const useGlobalConfig = () => {
  const contracts = useContracts();
  const { manager } = contracts;
  const globalConfigQuery = useQuery({
    queryKey: ["globalConfigQuery"],
    queryFn: async () => {
      const result = await manager!.getGlobalConfig();
      const configs = {} as any;
      Object.entries(result).forEach(([key, value]) => {
        if (value instanceof ethers.BigNumber) {
          configs[key] = value.toString();
        } else {
          configs[key] = value;
        }
      });
      console.log("globalConfigQuery", configs);
      return result;
    },
    enabled: !(manager == null),
  });

  return globalConfigQuery;
};
