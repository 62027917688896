import { useToast, Flex, Box } from "@chakra-ui/react";
import { JoinButton } from "../../components/JoinButton";
import { Modal } from "../../components/Modal";
import { CommonInput } from "../../components/UI/CommonInput";
import { useContracts } from "../../hooks/useContracts";
import { useTokens } from "../../hooks/useTokens";
import { useWallet } from "../../store/wallet-context";
import { useTranslation } from "react-i18next";
import styles from "./SwapModal.module.scss";
import { useState } from "react";
import { truncateNumber } from "../../utils/utils";
import { parseEther } from "ethers/lib/utils.js";
import classNames from "classnames";

type Prop = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

export const SwapModal = (props: Prop) => {
  const { isOpen, setIsOpen } = props;
  //@ts-ignore
  const { t } = useTranslation();
  const toast = useToast();
  const contracts = useContracts();
  const manager = contracts.manager;
  const tokensQuery = useTokens();
  const { accountAddress, isConnected } = useWallet();
  const [isLoading, setIsLoading] = useState(false);
  const [isApproveLoading, setIsApproveLoading] = useState(false);

  const usdt = tokensQuery.data?.usdt;
  const usdtBalance = tokensQuery.data?.usdt.balance;
  const allowance = tokensQuery.data?.usdt.allowance;
  const usdtBalanceBi = parseEther(usdtBalance || "0");

  const [inputAmount, setInputAmount] = useState("");
  const inputAmountBi = parseEther(inputAmount || "0");
  const handleInputAmountChange = (e: any) => {
    const value = e.target.value;
    if (value === "") {
      setInputAmount("");
      setInputAmountError(false);
      return;
    }
    if (isNaN(value) || (usdtBalance && parseEther(value).gt(parseEther(usdtBalance)))) {
      setInputAmountError(true);
      return;
    }
    setInputAmount(value);
    setInputAmountError(false);
  };
  const [inputAmountError, setInputAmountError] = useState(false);

  const usdtInputMoreThanApproved =
    tokensQuery.isSuccess && usdtBalanceBi.gt(inputAmountBi) && inputAmountBi.gt(allowance!);
  const usdtNotApproved = tokensQuery.data?.usdt.approved === false || usdtInputMoreThanApproved;

  const handleApproveUSDT = async () => {
    if (!isConnected || !manager || !usdt) return;
    setIsApproveLoading(true);
    try {
      const tx = await usdt.approve();
      setIsApproveLoading(false);
      toast({
        title: t("Approve success", "Approve success"),
        status: "success",
      });
    } catch (error) {
      setIsApproveLoading(false);
      toast({
        title: t("An error occurred", "An error occurred"),
        status: "error",
        duration: 1500,
        isClosable: true,
      });
    }
  };
  const handleSwap = async () => {
    if (!isConnected || !manager || !inputAmount || Number(inputAmount) === 0 || inputAmountError) return;
    setIsLoading(true);
    try {
      const amount = parseEther(inputAmount);
      const tx = await manager.usdtPurchaseUsds(amount);
      const receipt = await tx.wait();
      setIsLoading(false);
      setIsOpen(false);
      toast({
        title: t("Swap success", "Swap success"),
        status: "success",
      });
    } catch (error) {
      setIsLoading(false);
      toast({
        title: t("An error occurred", "An error occurred"),
        status: "error",
        duration: 1500,
        isClosable: true,
      });
    }
  };

  if (!isOpen) return null;
  return (
    <Modal setIsOpen={() => setIsOpen(false)}>
      <Flex flexDir="column" alignItems="center" gap={8}>
        <Box className={styles.modalTitle}>{t("Swap USDT to USDS", "Swap USDT to USDS")}</Box>
        <Flex w="80%" flexDirection="column">
          <Box className={styles.detailText}>
            {t("USDT balance", "USDT balance")}: {usdtBalance ? truncateNumber(usdtBalance) : "-"}
          </Box>
          <CommonInput
            type="number"
            value={inputAmount}
            className={classNames(inputAmountError && styles.inputError)}
            onChange={handleInputAmountChange}
            placeholder="Input amount here"
          />
          <Box className={styles.detailText}>≈ {inputAmount} USDS</Box>
        </Flex>
        {usdtNotApproved && (
          <JoinButton
            handleClick={handleApproveUSDT}
            name={t("Approve USDT", "Approve USDT")}
            loading={isApproveLoading}
          />
        )}
        <JoinButton
          handleClick={handleSwap}
          name={t("Confirm", "Confirm")}
          disabled={!isConnected || inputAmountError || !inputAmount}
          loading={isLoading}
        />
      </Flex>
    </Modal>
  );
};
