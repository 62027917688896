import { ethers } from "ethers";

import { useContracts } from "./useContracts";
import { useWallet } from "../store/wallet-context";
import { useQuery } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import { useBlockNumber } from "wagmi";
import { useEffect, useRef } from "react";

interface Token {
  symbol: string;
  decimals: number;
  allowance: ethers.BigNumber;
  balance: string;
  approved: boolean;
  approve: () => Promise<ethers.ContractReceipt | undefined>;
}

export const useTokens = () => {
  const toast = useToast();
  const contracts = useContracts();
  const blockNumber = useBlockNumber({ watch: true });
  const blockNumberRef = useRef(blockNumber);
  const { usdt, usds, zBec, stf, stfusdtlp, stfusdslp, manager } = contracts;
  const { accountAddress } = useWallet();
  const tokenContracts = [usdt!, usds!, zBec!, stf!, stfusdtlp!, stfusdslp!];

  const tokenQueries = useQuery({
    queryKey: ["tokenQueries"],
    queryFn: async () => {
      const queryPromises = tokenContracts.map(async (tokenContract) => {
        const balanceBigNumber = await tokenContract.balanceOf(accountAddress!);
        const decimals = await tokenContract.decimals();
        const balance = ethers.utils.formatUnits(balanceBigNumber, decimals);
        const symbol = await tokenContract.symbol();
        const allowance = await tokenContract.allowance(accountAddress!, manager!.address);
        const approved = allowance.gt(0);
        const approveToken = async () => {
          // if (approved) {
          //   console.log(symbol, 'already approved');
          //   return;
          // }
          if (balanceBigNumber.eq(0)) {
            toast({
              title: `${symbol} balance is 0.`,
              status: "error",
              duration: 1500,
              isClosable: false,
            });
            return;
          }
          const tx = await tokenContract.approve(
            manager!.address,
            ethers.constants.MaxUint256
          );
          const receipt = await tx.wait();
          return receipt;
        };
        return {
          symbol,
          balance,
          allowance,
          approved,
          decimals,
          approve: approveToken,
        } satisfies Token;
      });
      const results = await Promise.all(queryPromises);
      return {
        usdt: results[0],
        usds: results[1],
        zBec: results[2],
        stf: results[3],
        stfusdtlp: results[4],
        stfusdslp: results[5],
      };
    },
    enabled:
      !!accountAddress &&
      !(manager == null) &&
      tokenContracts.every((tokenContract) => !!tokenContract),
  });

  useEffect(() => {
    if (
      blockNumber.isSuccess &&
      blockNumberRef.current.data !== blockNumber.data
    ) {
      blockNumberRef.current = blockNumber;
      tokenQueries.refetch();
    }
  }, [blockNumber]);

  return tokenQueries;
};
