import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useState, useEffect } from "react";

export const useTimeToWait = () => {
  const timeQuery = useQuery({
    queryKey: ["timeQuery"],
    queryFn: async () => {
      const result = await axios.get(
        "https://worldtimeapi.org/api/timezone/America/Los_Angeles"
      );
      const timstamp = result.data.unixtime as any;
      console.log("timstamp", timstamp);
      return timstamp;
    }
  });

  const defaultStartTime = 1687867200; // 06/27/2021 12:00:00 GMT+0000
  const currentTime = timeQuery.data || defaultStartTime;

  const targetTimestamp = 1687953600; // 06/28/2023 12:00:00 GMT+0000

  const [hoursToWait, setHoursToWait] = useState(0);
  const [minutesToWait, setMinutesToWait] = useState(0);
  const [secondsToWait, setSecondsToWait] = useState(0);
  const [timeIsDue, setTimeIsDue] = useState(false);

  const secondsPerHour = 60 * 60; // 3600
  const secondsPerMinute = 60; // 60

  useEffect(() => {
    const timeDifferenceInSeconds = targetTimestamp - currentTime;
    let counter = 0;
    const intervalId = setInterval(() => {
      counter++;
      const nextTime = timeDifferenceInSeconds - counter;
      if(nextTime <= 0) {
        setTimeIsDue(true);
      }
      const hoursToWait = Math.floor(nextTime / secondsPerHour);
      const minutesToWait = Math.floor(
        (nextTime % secondsPerHour) / secondsPerMinute
      );
      const secondsToWait = Math.floor(nextTime % 60);
      setHoursToWait(hoursToWait);
      setMinutesToWait(minutesToWait);
      setSecondsToWait(secondsToWait);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [currentTime]);

  return {
    timeIsDue,
    hoursToWait,
    minutesToWait,
    secondsToWait
  };
};
