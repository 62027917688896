import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import styles from "./index.module.scss";
import { useTimer } from "../../hooks/useTimer";

export function Promotion() {
  //@ts-ignore
  const { t } = useTranslation();
  const countDown = useTimer({
    deadline: new Date("2023-09-09T23:59:59+08:00")
  });
  if (countDown.diffInSeconds < 0) return null;

  return (
    <Flex className={styles.container}>
      <Box className={styles.x3Logo} />
      <Flex className={styles.detailContainer}>
        <Box className={styles.title}>{t("Limited Deal", "Limited Deal")}</Box>
        <Box className={styles.detail}>
          {t("Ends in", "Ends in")}:{" "}
          <span className={styles.strong}>
            {countDown.days}D:{countDown.hours}H:{countDown.minutes}M:
            {countDown.seconds}S
          </span>
        </Box>
        <Box className={styles.detail}>
          {t("Rules: invest more than", "Rules: invest more than")}:
          <span className={styles.strong}>1000 USDT</span>,{" "}
          {t(
            "you’ll earn 3 times return of USDS, which will be released within 18 months",
            "you’ll earn 3 times return of USDS, which will be released within 18 months."
          )}
        </Box>
      </Flex>
    </Flex>
  );
}
